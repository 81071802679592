import LoadingBox from 'components/LoadingBox/LoadingBox';
import React from 'react';

export default function LoadingViewer() {
	return (
		<div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
			<LoadingBox />
		</div>
	);
}
