import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/firmaClienteStyle";
// import logo from '../../assets/img/logo.svg';

import { useDispatch, useSelector } from "react-redux";

import FirmaRevision from "components/FirmaRevision/FirmaRevision";
import FirmaUpload from "components/FirmaUpload/FirmaUpload";
import { getDossier } from '../../actions/dossierActions';

import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const fakeUser = {
  firstName: "Rafael",
  secondName: "Alfonso",
  thirdName: "",
  lastName: "Sánchez",
  secondLastName: "Mólina",
  familyLastName: "",
  phone: "123 - 123124 - 12",
  email: "rf.sanchez@example.com",
  rfc: "OORL910509PWA",
};

const fakeDocument = {
  id: 9,
  documento: "Contrato de compra venta",
  estado: "Firma de documento",
  version: 2,
};

const fakeCreator = {
  id: 2658,
  displayName: "Jorge Estrada",
};

export default function FirmaCliente(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id } = useParams();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const dossierState = useSelector((state) => state.dossier);
  const [dossier, setDossier] = useState(null);

  const [step, setStep] = useState(1);

  const goBack = () => {
    props.history.push("/documento");
  };

  useEffect(() => {
    dispatch(getDossier(id));
  }, []);

  useEffect(() => {
    if(Object.keys(dossierState.data).length !== 0) {
        setDossier(dossierState.data[0].model)
    } // if
  }, [dossierState]);

  return (
    <div>
        {dossier && (
            <div>
            <Grid container className={classes.container}>
              <Grid item xs={6} className={classes.column}>
                <Grid container className={classes.container}>
                  <Grid item xs={1} className={classes.row}>
                    <div className={classes.clickable} onClick={goBack}>
                      <ArrowBackIcon />
                    </div>
                  </Grid>
                  <Grid item xs={10} className={classes.leftRow}>
                    <Typography variant="h3" className={classes.id}>
                      #{dossier.data[0].model.id}
                    </Typography>
                    <Typography variant="h3">{dossier.data[0].model.name}</Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.container}>
                  <Grid item xs={1} className={classes.row}></Grid>
                  <Grid item xs={10} className={classes.leftRow}>
                    <Typography variant="h3" className={classes.subHeadline}>
                      Firma de documento
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.column}>
                <Grid container className={classes.container}>
                  <Grid item xs={12} className={classes.rightRow}>
                    <Typography variant="h3" className={classes.subHeadline}>
                      Creador del documento
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.rightRow}>
                    <Typography variant="h3" className={classes.id}>
                      #{fakeCreator.id}
                    </Typography>
                    <Typography variant="h3">{fakeCreator.displayName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.contentCard}>d</div>
          </div>
        )}
    </div>
  );
}
