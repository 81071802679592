import React, { useEffect, useState } from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ContentTable from 'components/ContentTable/ContentTable';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { getPendingDossier } from 'actions/dossierActions';
import { useDispatch, useSelector } from 'react-redux';
import { DOSSIER_STATES } from 'utils/constants';
const useStyles = makeStyles(styles);

var fakeColumns = [
	{ name: 'Correlativo', flex: 1, align: 'center', minWidth: 100 },
	{ name: 'Documento', flex: 3, align: 'left', minWidth: 200 },
	{ name: 'Estado', align: 'center', flex: 2, minWidth: 100 },
];

function Dashboard(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [rows, setRows] = useState([]);

	const dossiers = useSelector((state) => state.pendingDossier.dossiers);
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo, loading, error } = userSignin;

	const visibilityActionClick = (id) => {
		if (id?.id) id = id.id;
		props.history.push('/revision_documento/' + id);
	};

	const nuevoExpedienteClick = () => {
		props.history.push('/registro_documento');
	};

	useEffect(() => {
		if (!userInfo) return props.history.push('/login');
		if (userInfo.roles.includes('cliente-firmante')) {
			props.history.push('/documento');
		} // if
		dispatch(getPendingDossier());
	}, []);

	useEffect(() => {
		if (dossiers) {
			setRows(
				dossiers.map((item) => {
					return {
						id: item.model.id,
						documento: item.model.name,
						estado: getStatus(item.model.status),
						actions: ['edit'],
					};
				})
			);
		}
	}, [dossiers]);

	const getStatus = (status) =>
		DOSSIER_STATES.find(
			(state) => state.minRange <= status && state.maxRange >= status
		)?.name || '';

	return (
		<div style={{ height: '100%' }}>
			<Grid container className={classes.accesoRapidoContainer}>
				<Grid item xs={12} sm={4}>
					<div className={classes.accesoRapidoContentContainer}>
						<div
							className={classes.accesoRapidoButton}
							onClick={nuevoExpedienteClick}
						>
							<Typography variant='caption'>
								Nuevo Documento
							</Typography>
						</div>
					</div>
				</Grid>
			</Grid>
			<ContentTable
				title='Documentos'
				pseudoRows={rows}
				columnDefinitions={fakeColumns}
				renderActionsHeader
				visibilityHandler={visibilityActionClick}
				loading={loading}
				onRowClick={visibilityActionClick}
				onCellClick={visibilityActionClick}
			/>
		</div>
	);
}

export default withRouter(Dashboard);
