/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Description from '@material-ui/icons/Description';
import People from '@material-ui/icons/People';
import Archive from '@material-ui/icons/Archive';
import BubbleChart from '@material-ui/icons/BubbleChart';
import LocationOn from '@material-ui/icons/LocationOn';
import Notifications from '@material-ui/icons/Notifications';
import AddBox from '@material-ui/icons/AddBox';
import Language from '@material-ui/icons/Language';
import PersonAdd from '@material-ui/icons/PersonAdd';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Visibility from '@material-ui/icons/Visibility';
import Send from '@material-ui/icons/Send';
import Drafts from '@material-ui/icons/Drafts';
import History from '@material-ui/icons/History';
// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard.js';
import Clientes from 'views/Clientes/Clientes';
import Protocolo from 'views/Protocolo/Protocolo';
import RegistroDocumento from 'views/RegistroDocumento/RegistroDocumento';
import RevisionDocumento from 'views/RevisionDocumento/RevisionDocumento';
import DashboardCliente from 'views/DashboardCliente/DashboardCliente.js';
import DocumentoCliente from 'views/DocumentoCliente/DocumentoCliente.js';
import FirmaCliente from 'views/FirmaCliente/FirmaCliente';
import RegistroCliente from 'views/RegistroCliente/RegistroCliente';
import UserProfile from 'views/UserProfile/UserProfile.js';
import TableList from 'views/TableList/TableList.js';
import Typography from 'views/Typography/Typography.js';
import Icons from 'views/Icons/Icons.js';
import Maps from 'views/Maps/Maps.js';
import NotificationsPage from 'views/Notifications/Notifications.js';
import UpgradeToPro from 'views/UpgradeToPro/UpgradeToPro.js';
// core components/views for RTL layout
import RTLPage from 'views/RTLPage/RTLPage.js';
import ExpedienteDetailPage from 'views/Expediente/ExpedienteDetail.js';
import RegistryPage from 'views/Registry/Registry';
import AsignacionPage from 'views/Asignacion/Asignacion';
import ExpedientesPage from 'views/Expediente/Expedientes';
import RevisionesPage from 'views/Revisiones/Revisiones';
import DespachoPage from 'views/Despacho/Despacho';
import AvisosPage from 'views/Avisos/Avisos';
import ArchivoPage from 'views/Archivo/Archivo';
import LoginPage from 'views/LogIn/LogIn';
import DetalleCliente from 'views/DetalleCliente/DetalleCliente';
import DocumentosCompletados from 'views/DocumentosCompletados/DocumentosCompletados';
import NuevoDocumento from 'views/NuevoDocumento/NuevoDocumento';
import { VpnKey } from '@material-ui/icons';
import SignUploadView from 'views/SignUploadView/SignUploadView';
import SignView from 'views/SignUploadView/SignView/SignView';

const dashboardRoutes = [
	{
		path: '/dashboard',
		name: 'En proceso',
		icon: Description,
		component: DashboardPage,
		layout: '/admin',
		roles: { 'super-admin': true, 'notario-adscrito': true },
	},
	{
		path: '/dossier-completed',
		name: 'Finalizados',
		icon: Description,
		component: DocumentosCompletados,
		layout: '/admin',
		roles: { 'super-admin': true, 'notario-adscrito': true },
	},
	{
		path: '/clientes/cliente/:id',
		name: 'Clientes',
		icon: People,
		component: DetalleCliente,
		layout: '/admin',
		hideOnSidebar: true,
		exact: true,
		roles: ['super-admin', 'notario-adscrito'],
	},
	{
		path: '/clientes',
		name: 'Clientes',
		icon: People,
		component: Clientes,
		layout: '/admin',
		exact: true,
		roles: ['super-admin', 'notario-adscrito'],
	},

	{
		path: '/firmas/nueva-firma',
		name: 'Firmas',
		icon: VpnKey,
		layout: '/admin',
		component: SignUploadView,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/firmas',
		name: 'Firmas',
		icon: VpnKey,
		layout: '/admin',
		component: SignView,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/protocolo',
		name: 'Protocolo electrónico',
		icon: Archive,
		component: Protocolo,
		layout: '/admin',
		hideOnSidebar: true,
		roles: ['super-admin', 'notario-adscrito'],
	},
	{
		path: '/nuevo_documento',
		name: 'Ingreso y Registro',
		icon: AddBox,
		layout: '/admin',
		component: RegistroDocumento,
		hideOnSidebar: true,
		roles: ['super-admin', 'notario-adscrito'],
	},
	{
		path: '/registro_documento',
		name: 'Ingreso y Registro',
		icon: AddBox,
		layout: '/admin',
		component: NuevoDocumento,
		hideOnSidebar: true,
		roles: ['super-admin', 'notario-adscrito'],
	},
	{
		path: '/registro_cliente',
		name: 'Ingreso y Registro',
		icon: AddBox,
		layout: '/admin',
		component: RegistroCliente,
		hideOnSidebar: true,
		roles: ['super-admin', 'notario-adscrito'],
	},
	{
		path: '/revision_documento/:id',
		name: 'Revision Documento',
		icon: AddBox,
		layout: '/admin',
		component: RevisionDocumento,
		hideOnSidebar: true,
		roles: ['super-admin', 'notario-adscrito'],
	},
	{
		path: '/documento/:id/firma',
		name: 'Firma cliente',
		icon: AddBox,
		layout: '/admin',
		component: FirmaCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/documento/:id/firma',
		name: 'Firma cliente',
		icon: AddBox,
		layout: '/admin',
		component: FirmaCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/documento/:id',
		name: 'Documento cliente',
		icon: AddBox,
		layout: '/admin',
		component: DocumentoCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},
	{
		path: '/documento/',
		name: 'Mis documentos',
		icon: Description,
		layout: '/admin',
		component: DashboardCliente,
		hideOnSidebar: true,
		roles: { 'super-admin': true, 'cliente-firmante': true },
	},

	/* {
	path: "/asignacion",
	name: "Asignación",
	icon: PersonAdd,
	layout: "/admin",
	component: AsignacionPage
  }, */
	{
		path: '/expediente/:id',
		name: 'Expediente',
		icon: Dashboard,
		component: ExpedienteDetailPage,
		layout: '/admin',
		icon: Dashboard,
		hideOnSidebar: true,
	},
	/* {
	path: "/expedientes",
	name: "Expedientes en Estudio",
	icon: BusinessCenter,
	layout: "/admin",
	component: ExpedientesPage
  },
  {
	path: "/revisiones",
	name: "Revisiones",
	icon: Visibility,
	layout: "/admin",
	component: RevisionesPage,
  },
  {
	path: "/despacho",
	name: "Despacho",
	icon: Send,
	layout: "/admin",
	component: DespachoPage,
  },
  {
	path: "/avisos",
	name: "Avisos",
	icon: Drafts,
	layout: "/admin",
	component: AvisosPage
  },
  {
	path: "/archivo",
	name: "Archivo Histórico",
	icon: History,
	layout: "/admin",
	component: ArchivoPage
  }, */
];

export default dashboardRoutes;
