import {
	PREPAPRE_REQUEST,
	PREPARE_REQUEST_SUCCESS,
	PREPARE_REQUEST_ERROR,
	CERTIFICATE_UPLOADED,
	CERTIFICATE_DELETED,
	KEY_UPLOADED,
	KEY_DELETED,
	FINISH_REQUEST,
	FINISH_REQUEST_SUCCESS,
	FINISH_REQUEST_ERROR,
} from '../constants/signConstants';
import Swal from 'sweetalert2';

import Axios from '../services/Api.js';

export const prepareUpload = (callback) => async (dispatch) => {
	dispatch({ type: PREPAPRE_REQUEST });

	try {
		const { data } = await Axios.UploadSignPrepare();
		dispatch({ type: PREPARE_REQUEST_SUCCESS, payload: data });

		callback();
	} catch (error) {
		console.log(error);
		dispatch({ type: PREPARE_REQUEST_ERROR });
		Swal.fire({
			icon: 'error',
			title: 'Hubo un error',
			text: 'Error inesperado al preparar firma',
		});
	}
};

export const finishUpload = (uuid, name) => async (dispatch) => {
	dispatch({ type: FINISH_REQUEST });

	try {
		const { data } = await Axios.UploadSignFinish({ uuid, name });
		dispatch({ type: FINISH_REQUEST_SUCCESS, payload: data });
		Swal.fire('Firma', 'Firma guardada!', 'success').then(
			() => (window.location = '/firmas')
		);
	} catch (error) {
		console.log(error);
		dispatch({ type: FINISH_REQUEST_ERROR });
		Swal.fire({
			icon: 'error',
			title: 'Hubo un error',
			text: 'Error inesperado al preparar firma',
		});
	}
};

export const prepare = (document_id) => async (dispatch) => {
	dispatch({ type: PREPAPRE_REQUEST });

	// const fileA = file.getAll("file")[0];
	try {
		//const formData = new FormData();
		//formData.append("File[digitalFile]", fileA);

		//const { data } = await Axios.UploadFile(formData);
		//const data = { debug: 'ok' }
		const { data } = await Axios.signPrepare({
			dossier_document_id: document_id,
		});
		dispatch({ type: PREPARE_REQUEST_SUCCESS, payload: data });

		//Swal.fire('Archivo', 'Documento cargado correctamente', 'success');
	} catch (error) {
		console.log(error);
		dispatch({ type: PREPARE_REQUEST_ERROR });
		Swal.fire({
			icon: 'error',
			title: 'Hubo un error',
			text: 'Error inesperado al preparar firma',
		});
	}
};

export const finish =
	(request_id, signature_id, password, signPosition, callback) =>
	async (dispatch) => {
		dispatch({ type: FINISH_REQUEST });

		try {
			console.log(signPosition);
			const width = Math.abs(signPosition.endX - signPosition.x);
			const height = Math.abs(signPosition.endY - signPosition.y);
			const { data } = await Axios.signFinish({
				request_id: request_id,
				signature_id: signature_id,
				password: password,
				x1: signPosition.x,
				//TODO: Evitar la esta resta quemada sino que lo guarde dependiendo de alto
				y1: 792 - +signPosition.y - +height,
				width: 792 - +signPosition.y,
				height: +signPosition.x + +width,
				page: signPosition.page,
			});
			dispatch({ type: FINISH_REQUEST_SUCCESS, payload: data });
			Swal.fire('Archivo', 'El documento será firmado', 'success').then(
				() => callback(true)
			);
		} catch (error) {
			console.log(error);
			dispatch({ type: FINISH_REQUEST_ERROR });
			Swal.fire({
				icon: 'error',
				title: 'Problema con firma',
				text: 'Asegurese que el certificado, la llave y la contraseña esten correctos',
			});
			callback(false);
		}
	};

export const saveCertificate = (payload) => async (dispatch) => {
	try {
		dispatch({ type: CERTIFICATE_UPLOADED, fileData: payload });
	} catch (error) {
		console.log(error);
	}
};

export const deleteCertificate = () => async (dispatch) => {
	try {
		dispatch({ type: CERTIFICATE_DELETED });
	} catch (error) {
		console.log(error);
	}
};

export const saveKey = (payload) => async (dispatch) => {
	try {
		dispatch({ type: KEY_UPLOADED, fileData: payload });
	} catch (error) {
		console.log(error);
	}
};

export const deleteKey = () => async (dispatch) => {
	try {
		dispatch({ type: KEY_DELETED });
	} catch (error) {
		console.log(error);
	}
};
