import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DOSSIER_STATES } from 'utils/constants';

import Step from './Step';

import styles from '../../assets/jss/material-dashboard-react/components/stepsStyle';

const useStyles = makeStyles(styles);

export default function StepsExpediente(props) {
	const classes = useStyles();
	const currentStep = Math.trunc(props.step);
	return (
		<div className={classes.container2}>
			{DOSSIER_STATES?.map((state) => (
				<Step
					key={state.id}
					step={state.step}
					currentStep={currentStep}
					title={state.name}
				/>
			))}
		</div>
	);
}
