import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Link from '@material-ui/core/Link';

import { deleteFile } from '../../actions/documentActions';

const ListItemCustom = ({ name, size, id }) => {
	const dispatch = useDispatch();

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	const deleteFilee = (id) => {
		dispatch(deleteFile(id));
	};

	return (
		<ListItem>
			<ListItemAvatar>
				<Avatar>
					<PictureAsPdfIcon />
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				onClick={() =>
					openInNewTab(
						'https://archive.glifos.local2/glifos/file/get/viewer/pdf/' +
							id
					)
				}
				primary={name}
				secondary={'Tamano:' + size + 'kb'}
			/>
			<ListItemSecondaryAction>
				<IconButton edge='end' aria-label='delete'>
					<DeleteIcon onClick={() => deleteFilee(id)} />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

export default ListItemCustom;
