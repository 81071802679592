import React, { useState } from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/firmaClienteStyle";
// import logo from '../../assets/img/logo.svg';

import FileUploader from "components/FileUploader/FileUploader";
import Firma from 'components/Firma/Firma';

import { finish } from '../../actions/signActions';

const useStyles = makeStyles(styles);

export default function FirmaUpload(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const signRequest = useSelector((state) => state.signRequest);

  const [password, setPassword] = useState("");

  const handleFirmar = () => {
    if(password === "" || password === undefined) {
      Swal.fire({
				icon: 'error',
				title: 'Campos requerido',
				text: 'Por favor ingrese todos los campos',
			});
      return;
    }
    dispatch(finish(signRequest.signInfo.request_id, signRequest.certificateFile.id, password));
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        md={3}
        className={classNames(classes.column, classes.borderRight)}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.column}>
            <div className={classes.note}>
            <b>¿Tienes la firma correcta?</b> Asegurate de utilizar los archivos correspondientes a tu e.firma, recuerda que esta acción no se puede revertir.
              {/* <b>¿Tienes la firma correcta?</b> Este documento debe firmarse con
              la e.firma correspondiente al RFC <b>{props.user.rfc}</b>. Si
              hay un problema con el RFC, contacta al creador del documento. */}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={9} className={classes.column}>
        <div className={classes.uploadSection}>
          <Firma password={password} setPassword={setPassword} />
        </div>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.rightRow}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonSecondary}
              onClick={props.backStep}
            >
              Volver atrás
            </Button>
            <Button
              variant="contained"
              color="success"
              className={classes.buttonSuccess}
              onClick={handleFirmar}
              disabled={signRequest.loading}
            >
              Aceptar y firmar
            </Button>
            {signRequest.loading && (
              <Button variant="text" className={classes.loader}>
                <i className="fa fa-spinner fa-spin"></i>&nbsp;Cargando ...
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
