import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/verificarDatosClienteStyle";
// import logo from '../../assets/img/logo.svg';

import { useDispatch, useSelector } from "react-redux";

import FileUploader from "components/FileUploader/FileUploader";

const useStyles = makeStyles(styles);

export default function VerificarDatosCliente(props) {
  const classes = useStyles();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  const [rfcUpload, setRfcUpload] = useState(false);
  const [ineUpload, setIneUpload] = useState(false);
  const [curpUpload, setCurpUpload] = useState(false);

  const [fakeUserData, setFakeUserData] = useState({
    firstName: "Rafael",
    secondName: "Alfonso",
	thirdName: "",
    lastName: "Sánchez",
    secondLastName: "Mólina",
	familyLastName: "",
    phone: "123 - 123124 - 12",
    email: "rf.sanchez@example.com",
    rfc: "OORL910509PWA",
  });

  const { firstName, secondName, thirdName, lastName, secondLastName, familyLastName, phone, email, rfc } =
    fakeUserData;

  const handleChange = (e) => {
    setFakeUserData({
      ...fakeUserData,
      [e.target.name]: e.target.value ? e.target.value : e.target.checked,
    });
  };

  return userInfo ? (
    (window.location.href = "/dashboard")
  ) : (
    <div className={classes.wrapper}>
      <div className={classes.header}></div>
      <div className={classes.content}>
        <div className={classes.contentCard}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.title}>
                Verifique su información y agregue los documentos solicitados
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={5} className={classes.sectionLeft}>
                  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Primer nombre</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={firstName}
                        name="firstName"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
                  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Segundo nombre</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={secondName}
                        name="secondName"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
				  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Tercer nombre</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={thirdName}
                        name="thirdName"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
                  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Primer apellido</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={lastName}
                        name="lastName"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
                  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Segundo apellido</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={secondLastName}
                        name="secondLastName"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
				  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Apellido de casada</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={familyLastName}
                        name="familyLastName"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
                  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Correo</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={email}
                        name="email"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
                  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">Teléfono</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={phone}
                        name="phone"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
                  <div className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="body1">RFC</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        value={rfc}
                        name="rfc"
                        onChange={handleChange}
                      />
                    </Grid>
                  </div>
                  <div className={classes.buttonSpace}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonPrimary}
                    >
                      Actualizar datos
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={7} className={classes.sectionRight}>
                  <div className={classes.row}>
                    <Typography variant="h3">
                      Archivos de identificación
                    </Typography>
                  </div>
                  <div className={classes.column}>
                    <Typography variant="h4">
                      RFC
                    </Typography>
                    <FileUploader
                      allowedFiles={".pdf"}
                      singleFile={true}
                      setFileUpload={setRfcUpload}
                    />
                  </div>
				  <div className={classes.column}>
                    <Typography variant="h4">
                      CURP
                    </Typography>
                    <FileUploader
                      allowedFiles={".pdf"}
                      singleFile={true}
                      setFileUpload={setCurpUpload}
                    />
                  </div>
				  <div className={classes.column}>
                    <Typography variant="h4">
                      INE
                    </Typography>
                    <FileUploader
                      allowedFiles={".pdf"}
                      singleFile={true}
                      setFileUpload={setIneUpload}
                    />
                  </div>
				  <div className={classes.buttonSpace}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonSuccess}
                    >
                      Actualizar datos
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
