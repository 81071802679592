import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
	Grid,
	TextField,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	Button,
} from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-react/components/registroDocumentoStyle';
import { useDispatch, useSelector } from 'react-redux';
import FileUploader from 'components/FileUploader/FileUploader';
import classNames from 'classnames';
import { deleteFile } from 'actions/documentActions';
import {
	saveCertificate,
	deleteCertificate,
	saveKey,
	deleteKey,
} from '../../actions/signActions';

const useStyles = makeStyles(styles);

export default function Firma(props) {
	const classes = useStyles();

	const files = useSelector((state) => state.document.archivos);
	const signRequest = useSelector((state) => state.signRequest);

	const [cerUpload, setCerUpload] = useState(false);
	const [keyUpload, setKeyUpload] = useState(false);

	const [removeCert, setRemoveCert] = useState(true);
	const [removeKey, setRemoveKey] = useState(true);

	const [cerFile, setCerFile] = useState(null);
	const [keyFile, setKeyFile] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		if (cerUpload && files[0] && removeCert) {
			setCerFile(files[0]);
			setRemoveCert(false);
			dispatch(deleteFile(files[0].id));
			if (!keyFile) setKeyUpload(false);
			dispatch(saveCertificate(files[0]));
		}
		return () => {};
	}, [cerUpload, files, removeCert]);

	useEffect(() => {
		if (keyUpload && files[0] && removeKey) {
			//console.log(files[0]);
			setKeyFile(files[0]);
			setRemoveKey(false);
			dispatch(deleteFile(files[0].id));
		}
		return () => {};
	}, [keyUpload, files, removeKey]);

	const handleDeleteCer = () => {
		setCerUpload(false);
		setCerFile(null);
		setRemoveCert(true);
		setKeyUpload(false);
		setKeyFile(null);
		setRemoveKey(true);
		props.setName('');
		dispatch(deleteCertificate());
		dispatch(deleteKey());
	};

	const handleDeleteKey = () => {
		setKeyUpload(false);
		setKeyFile(null);
		setRemoveKey(true);
		props.setName('');
		dispatch(deleteKey());
	};

	return (
		<Grid container className={classes.container}>
			<Grid item xs={12} className={classes.column}>
				{/* <div className={classes.title2}>
					<Typography variant='h3'>{props.documentType}</Typography>
				</div> */}
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.column}>
						<Typography variant='h4' className={classes.littleRow}>
							1. Selecciona el certificado (archivo .cer)
						</Typography>
						{!cerUpload ? (
							<FileUploader
								allowedFiles={'.cer'}
								singleFile={true}
								setFileUpload={setCerUpload}
								s3Upload={
									signRequest.signInfo.certificate_endpoint
								}
							/>
						) : (
							<div className={classes.fileContainer}>
								<div className={classes.fileIcon}>
									<i className='fas fa-file fa-3x'></i>
								</div>
								<div className={classes.fileName}>
									{cerFile?.name}
								</div>
								<div className={classes.retry}>
									<Button
										variant='text'
										onClick={handleDeleteCer}
									>
										<i className='fas fa-trash fa-1x'></i>
									</Button>
								</div>
							</div>
						)}
					</Grid>
					<Grid item xs={12} className={classes.column}>
						<Typography variant='h4' className={classes.littleRow}>
							2. Selecciona la llave privada (archivo .key)
						</Typography>
						{cerUpload && (
							<div>
								{!keyUpload ? (
									<FileUploader
										allowedFiles={'.key'}
										singleFile={true}
										setFileUpload={setKeyUpload}
										s3Upload={
											signRequest.signInfo.key_endpoint
										}
									/>
								) : (
									<div className={classes.fileContainer}>
										<div className={classes.fileIcon}>
											<i className='fas fa-file fa-3x'></i>
										</div>
										<div className={classes.fileName}>
											{keyFile?.name}
										</div>
										<div className={classes.retry}>
											<Button
												variant='text'
												onClick={handleDeleteKey}
											>
												<i className='fas fa-trash fa-1x'></i>
											</Button>
										</div>
									</div>
								)}
							</div>
						)}
					</Grid>
					<Grid item xs={12} className={classes.column}>
						<Typography variant='h4' className={classes.littleRow}>
							3. Ingresa el nombre de la firma
						</Typography>
						{cerUpload && keyUpload && (
							<TextField
								fullWidth
								value={props.name}
								name='password'
								type='text'
								onChange={(event) =>
									props.setName(event.target.value)
								}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
