import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/registroStyle';
import {
	Grid,
	Typography,
	Button,
	CircularProgress,
	Snackbar,
	Modal,
	Fade,
	Backdrop,
} from '@material-ui/core';
import { withRouter } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RegistroClientes from 'components/RegistroClientes/RegistroClientes';
import Axios from '../../services/Api.js';

const useStyles = makeStyles(styles);

function RegistroCliente(props) {
	const classes = useStyles();
	const [data, setData] = useState({
		nombres: '',
		apellidos: '',
		correo: '',
		telefono: '',
		rfc: false,
		curp: false,
		ine: false,
	});
	const [dataError, setDataError] = useState({
		nombres: {
			error: false,
			message: '',
		},
		apellidos: {
			error: false,
			message: '',
		},
		correo: {
			error: false,
			message: '',
		},
		telefono: {
			error: false,
			message: '',
		},
		rfc: {
			error: false,
			message: '',
		},
		curp: {
			error: false,
			message: '',
		},
		ine: {
			error: false,
			message: '',
		},
	});
	const [isPosting, setIsPosting] = useState({
		isPosting: false,
		success: false,
		isError: false,
		message: '',
		showModal: false,
	});
	const [clienteID, setClienteID] = useState(null);

	const validateData = () => {
		let isValid = true;
		const dataErrorCopy = { ...dataError };
		const stringFields = ['nombres', 'apellidos', 'correo'];

		stringFields.forEach((field) => {
			if (data[field].trim().length === 0) {
				dataErrorCopy[field].error = true;
				dataErrorCopy[field].message = 'Este campo es requerido';
				isValid = false;
			} else {
				dataErrorCopy[field].error = false;
				dataErrorCopy[field].message = '';
			}
		});

		if (!dataErrorCopy.correo.error) {
			const re = /\S+@\S+\.\S+/;
			if (!re.test(data.correo)) {
				dataErrorCopy.correo.error = true;
				dataErrorCopy.correo.message = 'Correo inválido';
				isValid = false;
			}
		}

		setDataError(dataErrorCopy);
		return isValid;
	};

	const createClient = async () => {
		setIsPosting({
			isPosting: true,
			success: false,
			isError: false,
			message: '',
			showSnackbar: false,
		});
		setClienteID(null);
		try {
			const response = await Axios.CreateUpdateCliente({
				data: {
					nombres: data.nombres,
					apellidos: data.apellidos,
					email: data.correo,
					telefono: data.telefono,
					identificacion: {
						RFC: data.rfc,
						CURP: data.curp,
						INE: data.ine,
					},
				},
			});
			if (Boolean(response.data.result)) {
				setIsPosting((prevData) => ({
					...prevData,
					isPosting: false,
					success: true,
					isError: false,
					message: 'Cliente creado con éxito',
					showModal: true,
				}));
				setData({
					nombres: '',
					apellidos: '',
					correo: '',
					telefono: '',
					rfc: false,
					curp: false,
					ine: false,
				});
				setClienteID(response.data.create_or_update.state[0].state.id);
			} else {
				//console.log( response.data.error_log);
				setIsPosting((prevData) => ({
					...prevData,
					isPosting: false,
					success: false,
					isError: true,
					message: '',
					showModal: true,
				}));
			}
		} catch (error) {
			console.log(error);
			setIsPosting((prevData) => ({
				...prevData,
				isPosting: false,
				success: false,
				isError: true,
				message: error.message,
				showModal: true,
			}));
		}
	};

	const nextStep = () => {
		const isValidData = validateData();
		if (!isValidData) return;
		createClient();
	};

	const goBack = () => {
		props.history.goBack();
	};

	const handleClose = () => {
		if (isPosting.success) {
			// IR al detalle del cliente
			props.history.push(`/clientes/cliente/${clienteID}`);
		}
		if (isPosting.isError) {
			setIsPosting({
				isPosting: false,
				success: false,
				isError: false,
				message: '',
				showModal: false,
			});
			setClienteID(null);
		}
	};

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setData({ ...data, [name]: type === 'checkbox' ? checked : value });
		setDataError({
			...dataError,
			[name]: {
				error: false,
				message: '',
			},
		});
	};

	return (
		<div>
			<Grid container className={classes.container}>
				<Grid item xs={1} className={classes.column}>
					<div className={classes.clickable} onClick={goBack}>
						<ArrowBackIcon />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className={classes.row}>
						<Typography variant='h3'>Nuevo Cliente</Typography>
					</div>
					<Grid item className={classes.rowContainer}>
						<Grid item xs={12} className={classes.formContainer}>
							<RegistroClientes
								data={data}
								handleChange={handleChange}
								dataError={dataError}
							/>
							<div className={classes.buttonSpace}>
								<Button
									variant='contained'
									color='primary'
									onClick={nextStep}
									className={classes.button}
									disabled={isPosting.isPosting}
									endIcon={
										isPosting.isPosting ? (
											<CircularProgress size={20} />
										) : null
									}
								>
									Registrar Cliente
								</Button>
							</div>
							{/* {isPosting.showSnackbar && !isPosting.isPosting && (
								<div
									className={
										isPosting.isError
											? classes.alertError
											: classes.alertSuccess
									}
								>
									{isPosting.success &&
										(isPosting.message ||
											'Cliente creado con éxito')}
									{isPosting.isError &&
										(isPosting.message ||
											'Ocurrio un error, por favor intentelo más tarde')}
									<span
										className={classes.closeIconAlert}
										onClick={() => {
											setIsPosting((prevData) => ({
												...prevData,
												isError: false,
												message: '',
												showSnackbar: false,
											}));
										}}
									>
										X
									</span>
								</div>
							)} */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className={classes.modal}
				open={isPosting.showModal}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isPosting.showModal}>
					<div className={classes.paper}>
						<Typography
							variant='h3'
							gutterBottom
							id='transition-modal-title'
						>
							{isPosting.success ? 'Éxito' : 'Ocurrio un error'}
						</Typography>
						<p id='transition-modal-description'>
							{isPosting.message}
						</p>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

export default withRouter(RegistroCliente);
