import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-react/views/expedienteDetaillStyle";
import { Input, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import DynamicFilesList from 'components/Files/DynamicFilesList';
import FadeInModal from 'components/FadeInModal/FadeInModal';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import EntitledContainer from 'components/EntitledContainer/EntitledContainer';

const useStyles = makeStyles(styles);

export default function Registry(props) {
    const classes = useStyles();
    const documentsState = useSelector(state => state.document);
    const [continueModalState, setContinueModalState] = React.useState(false);

    const handleCloseModal = () => {
        setContinueModalState(false);
    }

    const handleContinueButtonClick = () => {
        if (documentsState.isEdittingNewFile) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo',
                text: 'Hay informacion sin guardar'
            });
        }
        else
            setContinueModalState(true);
    }
    return (
        <EntitledContainer title="Ingreso y Registro x">
            <div className={classes.row}>
                <div className={classes.labelWithText}>
                    <Typography variant="h4">Fecha de Ingreso:</Typography>
                    <Input className={classes.dateInput} defaultValue="10/05/2021" type="date" disableUnderline={true} />
                </div>
                <div className={classes.labelWithText}>
                    <Typography variant="h4">Plazo:</Typography>
                    <Input className={classes.dateInput} defaultValue="10/05/2021" type="date" onClick={(e) => e.preventDefault()} disableUnderline={true} />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.labelWithText}>
                    <Typography variant="h4">Tipo de Expediente:</Typography>
                    <Select
                        value={10}
                        onChange={undefined}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                        disableUnderline={true}
                        className={classes.dateInput}
                    >
                        <MenuItem value="" disabled>
                            Placeholder
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.labelWithText} style={{ width: '100%' }}>
                    <Typography variant="h4">Asunto:</Typography>
                    <TextField
                        className={classes.dateInput}
                        style={{ flex: '1' }}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                    />
                </div>
            </div>
            <div className={classes.row} >
                <div className={classes.labelWithText} style={{ width: '100%', alignItems: 'stretch' }}>
                    <Typography variant="h4" style={{ marginTop: '15px' }}>Descripcion y notas:</Typography>
                    <TextField
                        className={classes.dateInput}
                        style={{ minWidth: '300px', flex: '1' }}
                        margin="normal"
                        variant="outlined"
                        multiline
                        rows={4}
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                    />
                </div>
            </div>
            {/* <FileUploader /> */}
            <DynamicFilesList />
            <div className={classes.row}>
                <div className={clsx(classes.rowLeftAlign, classes.floatingButton)}>
                    <Typography variant="caption">Cancelar</Typography>
                </div>
                <div className={clsx(classes.rowRightAlign, classes.floatingButton)} onClick={handleContinueButtonClick}>
                    <Typography variant="caption">Continuar</Typography>
                </div>
            </div>
            <FadeInModal open={continueModalState} handleClose={handleCloseModal}>
                <div>
                    <div className={classes.modalRow}>
                        <Typography variant="h4" className={classes.columnTitle}>
                            Acción a Realizar
                        </Typography>
                    </div>
                    <div className={classes.modalRowEvenlyDistributed}>
                        <div className={classes.column}>
                            <div className={clsx(classes.rowLeftAlign, classes.floatingButton)}>
                                <Typography variant="caption">Archivar</Typography>
                            </div>
                        </div>
                        <div className={classes.column}>
                            <div className={clsx(classes.rowRightAlign, classes.floatingButton)}>
                                <Typography variant="caption">Solicitar Revision</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeInModal>
        </EntitledContainer>
    )
}