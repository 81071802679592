const stepsStyle = (theme) => ({
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		//height: '100%',
		maxWidth: '900px',
		border: '1px solid #afafaf',
		boxShadow: '2px 2px 2px #ccc',
		justifyContent: 'center',
		alignItems: 'center',
	},
	infoRow: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '2.5rem',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '10px',
	},
	buttonsSpace: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		alignItems: 'center',
	},
	infoPage: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		alignSelf: 'center',
		alignItems: 'center',
		paddingRight: '1rem',
	},
	button: {
		display: 'block',
		cursor: 'pointer',
	},
	noPDF: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		color: '#afafaf',
	},
});

export default stepsStyle;
