import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-react/views/expedienteDetaillStyle";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Collapse, FormControlLabel, Icon, Input, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import ContentTable from "components/ContentTable/ContentTable";
import FetchedFile from "components/Files/FetchedFile";
import FadeInModal from "components/FadeInModal/FadeInModal";
import clsx from "clsx";
import EntitledContainer from "components/EntitledContainer/EntitledContainer";

const useStyles = makeStyles(styles);
const fakeRows = [
    {
        id: 12188,
        fecha: '12/08/2021',
        asunto: '0 Compra computadora',
        plazo: '31/08/2021',
        designado: 'Pendiente',
        revisadoPor: 'vcr,df',
        actions: ["visibility", "delete"]
    },
    {
        id: 12189,
        fecha: '12/08/2021',
        asunto: '1 Compra computadora',
        plazo: '31/08/2021',
        designado: 'Pendiente',
        revisadoPor: 'vcr,df',
        actions: ["visibility", "delete"]
    },
    {
        id: 12190,
        fecha: '12/08/2021',
        asunto: '2 Compra computadora',
        plazo: '31/08/2021',
        designado: 'Pendiente',
        revisadoPor: 'vcr,df',
        actions: ["visibility", "delete"]
    },
];

var fakeColumns = ['No. Registro', 'Fecha de Ingreso', 'Asunto', 'Plazo', 'Designado', 'Revisado por'];
export default function ExpedienteDetail(props) {
    const classes = useStyles();
    var { id } = useParams();
    const [dataRep, setDataRep] = React.useState({ isLoading: true, data: undefined });
    const [modalArchivarState, setModalArchivarState] = React.useState(false);
    const [modalAsignarState, setModalAsignarState] = React.useState(false);
    const [hasClickedContinue, setHasClickedContinue] = React.useState(false); //asignar modal related

    console.log('id received ', id)
    React.useEffect(() => {
        ///fetch expediente data
    }, [id]);

    const handleCloseArchivarModal = () => {
        setModalArchivarState(false);
    }

    const handleCloseAsignarModal = () => {
        setModalAsignarState(false);
    }

    const handleAsignarConfirmation = () =>{
        ///aqui pues iria la peticion post a la api donde se manda a actualizar los cambios
        setHasClickedContinue(true);
    }

    return (
        <EntitledContainer title="Expediente #12688">
        <div style={{ position: 'relative' }}>
            <div className={classes.row}>
                <div className={classes.labelWithText}>
                    <Typography variant="caption">Fecha de Ingreso:</Typography>
                    <Input className={classes.dateInput} defaultValue="10/05/2021" type="date" disableUnderline={true} />
                </div>
                <div className={classes.labelWithText}>
                    <Typography variant="caption">Plazo:</Typography>
                    <Input className={classes.dateInput} defaultValue="10/05/2021" type="date" onClick={(e) => e.preventDefault()} disableUnderline={true} />
                </div>
            </div>
            <div className={classes.floatingButtonsContainer} >
                <div className={classes.floatingButton} style={{ marginBottom: '8px' }} onClick={() => setModalArchivarState(true)}>Archivar</div>
                <div className={classes.floatingButton} onClick={() => setModalAsignarState(true)}>Asignar</div>
            </div>
            <div className={classes.row}>
                <div className={classes.labelWithText}>
                    <Typography variant="caption">Tipo de Expediente:</Typography>
                    <Select
                        value={10}
                        onChange={undefined}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                        disableUnderline={true}
                        className={classes.dateInput}
                    >
                        <MenuItem value="" disabled>
                            Placeholder
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.labelWithText}>
                    <Typography variant="caption">Asunto:</Typography>
                    <TextField
                        className={classes.dateInput}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                    />
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.labelWithText}>
                    <Typography variant="caption">Descripcion y notas:</Typography>
                    <TextField
                        className={classes.dateInput}
                        margin="normal"
                        variant="outlined"
                        inputProps={{
                            style: {
                                padding: 5
                            }
                        }}
                    />
                </div>
            </div>
            <div className={classes.row}>

                <Accordion className={classes.accordionStyle}>
                    <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                    >
                        <Typography variant="caption">Avisos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FetchedFile />
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={classes.row} >

                <Accordion className={classes.accordionStyle}>
                    <AccordionSummary
                        expandIcon={<Icon>expand_more</Icon>}
                    >
                        <Typography variant="caption">Revisiones</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <ContentTable pseudoRows={fakeRows} columnDefinitions={fakeColumns} renderActionsHeader />

                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={classes.mobileFloatingButtons}>
                <div className={classes.floatingButton} onClick={() => setModalArchivarState(true)}>Archivar</div>
                <div className={classes.floatingButton} onClick={() => setModalAsignarState(true)}>Asignar</div>
            </div>
            <FadeInModal open={modalArchivarState} handleClose={handleCloseArchivarModal}>

                <div>
                    <div className={classes.modalRow}>
                        <div className={classes.column}>
                            <Typography variant="h4" className={classes.columnTitle}>
                                Acción a Realizar
                            </Typography>
                            <i class="fas fa-check fa-2x"></i>
                            <Typography variant="caption" className={classes.alignedText}>El expediente ha sido archivado con éxito</Typography>
                        </div>
                    </div>
                </div>
            </FadeInModal>
            <FadeInModal open={modalAsignarState} handleClose={handleCloseAsignarModal}>

                {!hasClickedContinue ?
                    (
                        <div>
                            <div className={classes.modalRow}>
                                <Typography className={classes.columnTitle} variant="h4">Asignar</Typography>
                            </div>
                            <div className={classes.modalRowEvenlyDistributed}>
                                <div className={classes.column}>
                                    <Typography variant="caption">Designado</Typography>
                                    <Select
                                        value={10}
                                        onChange={undefined}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        disableUnderline={true}
                                    >
                                        <MenuItem value="" disabled>
                                            Placeholder
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </div>
                                <div className={classes.column}>
                                    <FormControlLabel
                                        label="Designación futura"
                                        control={<Checkbox checked={false} />}
                                    />
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={clsx(classes.rowRightAlign, classes.floatingButton)} onClick={handleAsignarConfirmation}>
                                    <Typography variant="caption">Continuar</Typography>
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className={classes.modalRow}>
                            <div className={classes.column}>
                                <i class="fas fa-check fa-2x"></i>
                                <Typography variant="caption" className={classes.alignedText}>El expediente ha sido asignado con éxito</Typography>
                            </div>
                        </div>
                    )
                }

            </FadeInModal>

        </div>
        </EntitledContainer>
    );
}