import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Axios from 'services/Api';
import { CircularProgress } from '@material-ui/core';
import { WebVerification } from 'vdid-sdk-web';
// import logo from '../../assets/img/logo.svg';

const styles = (theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '90%',
		height: '100vh',
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '50%',
		height: '40vh',
		backgroundColor: '#ffffff',
		border: '2px solid #000000',
		flexWrap: 'wrap',
		padding: '2rem',

		[theme.breakpoints.down('md')]: {
			width: '60%',
			height: '50vh',
		},
		[theme.breakpoints.down('sm')]: {
			width: '70%',
			height: '60vh',
		},
		[theme.breakpoints.down('xs')]: {
			width: '80%',
			height: '80vh',
		},
	},
	cardBody: {
		//overflowY: 'scroll',
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		width: '90%',
	},
});

const useStyles = makeStyles(styles);

export default function PrivacyPolicy(props) {
	const classes = useStyles();

	return (
		<main className={classes.mainContainer}>
			<Typography variant='h1' className={classes.title}>
				Privacy Policy
			</Typography>
			<section className={classes.cardBody}>
				{/* <p>
					<em>Effective Date: 20/07/2023</em>
				</p>
 */}
				<p>
					Thank you for using the FIRMA La Venta Capital! Your privacy
					is important to us, and we are committed to protecting your
					personal information. This Privacy Policy outlines how we
					collect, use, disclose, and safeguard your data when you use
					the Zoom App (the "App") provided by La venta capital, S.A.
					de C.V. ("La venta capital," "we," "us," or "our").
				</p>

				<p>
					By accessing and using the FIRMA La Venta Capital, you agree
					to the terms and practices described in this Privacy Policy.
					If you do not agree with this policy, please refrain from
					using the App.
				</p>

				<h2>1. Information We Collect</h2>

				<h3>1.1. Personal Information</h3>
				<p>
					When you use the FIRMA La Venta Capital, we may collect
					certain personal information that you provide to us
					voluntarily. This information may include but is not limited
					to your name, email address, phone number, and any other
					information you provide when you register for an account or
					participate in our services.
				</p>

				<h3>1.2. Usage Data</h3>
				<p>
					We may collect information about how you interact with the
					App, such as your IP address, device information, operating
					system, and app usage statistics. This data is collected
					through cookies and similar technologies and is used to
					improve the performance and features of the App.
				</p>

				<h3>1.3. Meeting Data</h3>
				<p>
					When you host or join a meeting using the FIRMA La Venta
					Capital, we collect data related to the meeting, including
					meeting duration, participants' names, email addresses, and
					meeting content such as chat messages, files, and
					recordings. Hosts have the option to record meetings, and in
					such cases, recordings will be stored on our servers.
				</p>

				<h2>2. How We Use Your Information</h2>
				<p>
					We use the collected information for various purposes,
					including but not limited to:
				</p>
				<ul>
					<li>
						Providing and improving the FIRMA La Venta Capital's
						functionality and user experience.
					</li>
					<li>
						Sending you updates, notifications, and important
						communications related to the App.
					</li>
					<li>
						Analyzing App usage patterns to enhance features and
						identify issues.
					</li>
					<li>Enforcing our Terms of Service and other policies.</li>
					<li>
						Responding to your customer support inquiries and
						providing assistance.
					</li>
				</ul>

				<h2>3. Data Sharing and Disclosure</h2>
				<h3>3.1. Third-Party Service Providers</h3>
				<p>
					We may share your information with third-party service
					providers who assist us in operating and maintaining the
					App, perform data analytics, and help improve our services.
					These third parties are bound by confidentiality obligations
					and are prohibited from using your personal information for
					any other purpose.
				</p>

				<h3>3.2. Legal Compliance</h3>
				<p>
					We may disclose your information if required to do so by
					law, regulation, or legal process, or if we believe such
					disclosure is necessary to protect our rights, privacy,
					safety, or property, or that of others.
				</p>

				<h2>4. Data Security</h2>
				<p>
					We employ industry-standard security measures to protect
					your data from unauthorized access, loss, alteration, or
					disclosure. However, no method of transmission or storage is
					100% secure, and we cannot guarantee absolute security.
				</p>

				<h2>5. Your Choices</h2>
				<h3>5.1. Access and Update Your Information</h3>
				<p>
					You have the right to access, update, and delete your
					personal information. You can do this by accessing your
					account settings or contacting us directly.
				</p>

				<h3>5.2. Opt-Out of Promotional Emails</h3>
				<p>
					You can opt-out of receiving promotional emails from us by
					following the instructions in the emails.
				</p>

				<h2>6. Updates to this Policy</h2>
				<p>
					We may update this Privacy Policy from time to time to
					reflect changes in our practices or for other operational,
					legal, or regulatory reasons. The updated version will be
					posted on the App or our website, and the revised effective
					date will be included.
				</p>

				<h2>7. Contact Us</h2>
				<p>
					If you have any questions, concerns, or requests related to
					this Privacy Policy, please contact us at:
				</p>
				<p>Email: [Your contact email]</p>
				<p>Address: [Your address]</p>

				<p>
					Thank you for trusting La venta capital, S.A. de C.V. with
					your personal information. We value your privacy and will
					continue to strive to protect it to the best of our
					abilities.
				</p>
			</section>
		</main>
	);
}
