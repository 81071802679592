const stepsStyle = (theme) => ({
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		borderRight: '2px solid black',
		paddingRight: '20px',
		gap: '1.5rem',
		[theme.breakpoints.down('sm')]: {
			borderRight: '0',
			paddingRight: '0',
		},
	},
	container2: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		//paddingRight: '20px',
		gap: '1rem',
		[theme.breakpoints.down('sm')]: {
			borderRight: '0',
			paddingRight: '0',
		},
	},
	step: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '2.5rem',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: '10px',
		//marginBottom: '1.5rem',
	},
	stepDone: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		width: '2.5rem',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#3b6fb5', //'#c6e0b7',
		color: 'white',
		borderRadius: '50%',
		//marginRight: '10px',
		boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
		transform: 'scale(1)',
		aspectRatio: '1/1',
	},
	actualStep: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		width: '2.5rem',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ecb652', //'#c6e0b7',
		color: 'white',
		borderRadius: '50%',
		//marginRight: '10px',
		boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
		transform: 'scale(1)',
		animation: 'pulse 2s infinite',
		aspectRatio: '1/1',
		flexWrap: 'wrap',
	},
	stepNext: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		width: '2.5rem',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#4b5056', //'#AAAAAA',
		borderRadius: '10%',
		aspectRatio: '1/1',
		//marginRight: '10px',
	},
	text: {
		marginLeft: '5px',
	},
	stepNumberLabel: {
		color: 'white',
	},
});

export default stepsStyle;
