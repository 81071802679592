import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
	Grid,
	TextField,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
} from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-react/components/registroClienteStyle';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

export default function ResgistroClientes({ handleChange, data, dataError }) {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				<Typography variant='h3'>Detalles del cliente</Typography>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={3}>
					<Typography variant='body1'>
						Nombres <span style={{ color: 'red' }}>*</span>
					</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<TextField
						fullWidth
						autoFocus
						value={data.nombres}
						name='nombres'
						required
						onChange={handleChange}
						type='text'
						inputProps={{
							type: 'text',
						}}
						error={dataError.nombres.error}
						helperText={dataError.nombres.message}
					/>
				</Grid>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={3}>
					<Typography variant='body1'>
						Apellidos <span style={{ color: 'red' }}>*</span>
					</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<TextField
						fullWidth
						value={data.apellidos}
						name='apellidos'
						required
						onChange={handleChange}
						type='text'
						inputProps={{
							type: 'text',
						}}
						error={dataError.apellidos.error}
						helperText={dataError.apellidos.message}
					/>
				</Grid>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={3}>
					<Typography variant='body1'>
						Correo <span style={{ color: 'red' }}>*</span>
					</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<TextField
						fullWidth
						value={data.correo}
						name='correo'
						onChange={handleChange}
						type='email'
						required
						InputProps={{
							type: 'email',
						}}
						error={dataError.correo.error}
						helperText={dataError.correo.message}
					/>
				</Grid>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={3}>
					<Typography variant='body1'>Teléfono</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<TextField
						fullWidth
						value={data.telefono}
						name='telefono'
						onChange={handleChange}
						type='tel'
						InputProps={{
							type: 'tel',
						}}
						error={dataError.telefono.error}
						helperText={dataError.telefono.message}
					/>
				</Grid>
			</div>
			{/* <div className={classes.row}>
				<Grid item xs={12} md={3} className={classes.alignStart}>
					<Typography variant='body1'>Identificación</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<FormControl component='fieldset'>
						<FormGroup>
							<FormControlLabel
								className={data.rfc ? classes.formControl : ''}
								control={
									<Checkbox
										name='rfc'
										checked={data.rfc}
										onChange={handleChange}
										value={data.rfc}
									/>
								}
								label='Requerir RFC'
							/>
							<FormControlLabel
								className={data.curp ? classes.formControl : ''}
								control={
									<Checkbox
										name='curp'
										checked={data.curp}
										onChange={handleChange}
										value={data.curp}
									/>
								}
								label='Requerir CURP'
							/>
							<FormControlLabel
								className={data.ine ? classes.formControl : ''}
								control={
									<Checkbox
										name='ine'
										checked={data.ine}
										onChange={handleChange}
										value={data.ine}
									/>
								}
								label='Requerir INE'
							/>
						</FormGroup>
					</FormControl>
				</Grid>
			</div> */}
		</div>
	);
}
