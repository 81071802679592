import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

//import styles from 'assets/jss/material-dashboard-react/views/registroStyle';
import {
	Grid,
	Typography,
	Button,
	TextField,
	FormControl,
	Input,
	FormHelperText,
	Select,
	MenuItem,
} from '@material-ui/core';
//import { withRouter } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getDocumentTypes } from 'actions/documentActions';
import { getUserClients } from 'actions/userActions';
import StepsExpediente from 'components/Steps/StepsExpediente';
import { Delete } from '@material-ui/icons';
import Axios from 'services/Api.js';
import Swal from 'sweetalert2';

const styles = (theme) => ({
	verticalCenter: {
		display: 'flex',
		alignItems: 'center',
	},
	leftContainer: {
		padding: '1.1rem',
		gap: '1rem',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	rightContainer: {
		padding: '1.1rem',
		gap: '1rem',
		display: 'flex',
		width: '100%',
		border: '1px solid black',
		height: 'fit-content',
		flexDirection: 'column',
	},
	form: {
		width: '100%',
		gap: '1rem',
		display: 'flex',
		flexDirection: 'column',
	},
	formField: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '0.5rem',
		paddingTop: '0.5rem',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'flex-start',
		},
		[theme.breakpoints.up('md')]: {
			justifyContent: 'flex-end',
		},
	},
	clientRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		margin: '0.5rem 0',
		gap: '0.5rem',
	},
	clientButton: {
		marginLeft: 'auto',
	},
	buttonCnt: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	formSubmitting: {
		opacity: '0.8',
	},
});

const useStyles = makeStyles(styles);

export default function NuevoDocumento(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [data, setData] = useState({
		titulo: '',
		tipo_documento: 0,
		descripcion: '',
		clientes: [],
	});
	const [errorData, setErrorData] = useState({
		titulo: {
			error: false,
			helperText: '',
		},
		tipo_documento: {
			error: false,
			helperText: '',
		},
		descripcion: {
			error: false,
			helperText: '',
		},
		clientes: {
			error: false,
			helperText: '',
		},
	});
	const [dossier, setDossier] = useState({
		isLoading: false,
		isError: false,
		error: null,
		payload: null,
	});

	const { loading: isDocumentsLoading, document_types } = useSelector(
		(state) => state.document_types
	);

	const { loading: isClientsLoading, clients } = useSelector(
		(state) => state.user_clients
	);

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		if (errorData[name].error)
			setErrorData((prevErrorData) => ({
				...prevErrorData,
				[name]: {
					error: false,
					helperText: '',
				},
			}));
	};

	const addClients = (e) => {
		const { name, value } = e.target;
		if (+value === 0) return;
		const { id, nombre } = JSON.parse(value);
		setData((prevData) => ({
			...prevData,
			[name]: [...prevData[name], { id, nombre }],
		}));
		if (errorData[name].error)
			setErrorData((prevErrorData) => ({
				...prevErrorData,
				[name]: {
					error: false,
					helperText: '',
				},
			}));
	};

	const removeClient = (id) => {
		setData((prevData) => ({
			...prevData,
			clientes: prevData.clientes.filter((cliente) => cliente.id !== id),
		}));
	};

	const goBack = () => {
		props.history.goBack();
	};

	const isValidData = () => {
		let isValid = true;
		if (data.titulo === '') {
			setErrorData((prevErrorData) => ({
				...prevErrorData,
				titulo: {
					error: true,
					helperText: 'El campo es obligatorio',
				},
			}));
			isValid = false;
		}
		if (data.tipo_documento === 0) {
			setErrorData((prevErrorData) => ({
				...prevErrorData,
				tipo_documento: {
					error: true,
					helperText: 'El campo es obligatorio',
				},
			}));
			isValid = false;
		}
		if (data.clientes.length === 0) {
			setErrorData((prevErrorData) => ({
				...prevErrorData,
				clientes: {
					error: true,
					helperText: 'Debe seleccionar al menos un cliente',
				},
			}));
			isValid = false;
		}
		return isValid;
	};

	const postData = async (body) => {
		try {
			setDossier({
				isLoading: true,
				isError: false,
				error: null,
				payload: null,
			});
			const { data: response_data } = await Axios.CreateDossier(body);
			if (!Boolean(response_data?.result))
				throw new Error(
					response_data?.message ?? 'Error al crear el expediente'
				);
			const dossier_data = response_data?.create_or_update.state[0];
			setDossier({
				isLoading: false,
				isError: false,
				error: null,
				payload: dossier_data,
			});

			Swal.fire('Expediente creado!', '', 'success').then((e) => {
				if (e.isConfirmed || e.isDismissed)
					if (
						dossier_data?.state?.id !== null &&
						dossier_data?.state?.id !== undefined
					) {
						props.history.push(
							`/revision_documento/${dossier_data?.state?.id}`
						);
					} else {
						Swal.fire('Error al crear el expediente', '', 'error');
					}
			});
		} catch (error) {
			setDossier({
				isLoading: false,
				isError: true,
				error: error.message,
				payload: null,
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!isValidData()) return;
		const body = {
			data: {
				title: data.titulo,
				description: data.descripcion,
				document_type: data.tipo_documento,
				type: 2,
				signers: { client: data.clientes.map((cliente) => cliente.id) },
			},
		};
		postData(body);
	};
	useEffect(() => {
		dispatch(getDocumentTypes());
		dispatch(getUserClients());
	}, [dispatch]);
	return (
		<Grid container>
			<Grid direction='row' container>
				<Button onClick={goBack}>
					<ArrowBackIcon />
				</Button>
				<Typography
					variant='h3'
					className={classes.verticalCenter}
					align='center'
				>
					Nuevo Documento
				</Typography>
			</Grid>
			<Grid direction='row' container>
				<Grid
					item
					xs={12}
					sm={4}
					lg={3}
					className={classes.leftContainer}
				>
					<Typography
						variant={'h3'}
						className={classes.verticalCenter}
					>
						Proceso
					</Typography>
					<StepsExpediente step={1} />
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					lg={9}
					className={classes.rightContainer}
				>
					<Typography
						variant={'h3'}
						className={classes.verticalCenter}
					>
						Ingrese Detalle del Documento
					</Typography>
					<form
						className={`${classes.form} ${
							dossier.isLoading ? classes.formSubmitting : ''
						}`}
						onSubmit={handleSubmit}
					>
						<Grid container className={classes.formRow}>
							<Grid
								item
								xs={12}
								md={3}
								className={classes.formField}
							>
								<Typography variant={'body1'}>
									Título
								</Typography>
							</Grid>
							<Grid item xs={12} md={9}>
								<FormControl
									fullWidth
									error={errorData.titulo.error}
								>
									<Input
										id='titulo'
										aria-describedby='titulo'
										name='titulo'
										value={data.titulo}
										onChange={handleChangeInput}
									/>
									{errorData.titulo.error && (
										<FormHelperText id='titulo'>
											{errorData.titulo.helperText}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</Grid>
						<Grid container className={classes.formRow}>
							<Grid
								item
								xs={12}
								md={3}
								className={classes.formField}
							>
								<Typography variant={'body1'}>
									Tipo de documento
								</Typography>
							</Grid>
							<Grid item xs={12} md={9}>
								<FormControl
									className={classes.formControl}
									fullWidth
									error={errorData.tipo_documento.error}
								>
									<Select
										fullWidth
										labelId='label'
										name='tipo_documento'
										value={data.tipo_documento}
										onChange={handleChangeInput}
									>
										<MenuItem value={'0'}>
											{isDocumentsLoading
												? 'Cargando...'
												: 'Seleccione tipo de documento'}
										</MenuItem>
										{document_types &&
											document_types.map((tipo, i) => (
												<MenuItem
													key={i}
													value={tipo.id}
												>
													{tipo.name}
												</MenuItem>
											))}
									</Select>
									{errorData.tipo_documento.error && (
										<FormHelperText>
											{
												errorData.tipo_documento
													.helperText
											}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</Grid>
						<Grid container className={classes.formRow}>
							<Grid
								item
								xs={12}
								md={3}
								className={classes.formField}
							>
								<Typography variant='body1'>
									Descripción
								</Typography>
							</Grid>
							<Grid item xs={12} md={9}>
								<FormControl
									fullWidth
									error={errorData.descripcion.error}
								>
									<TextField
										fullWidth
										rows={6}
										multiline
										value={data.descripcion}
										name='descripcion'
										onChange={handleChangeInput}
									/>
									{errorData.descripcion.error && (
										<FormHelperText id='titulo'>
											{errorData.descripcion.helperText}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</Grid>
						<Grid container className={classes.formRow}>
							<Grid
								item
								xs={12}
								md={3}
								className={classes.formField}
							>
								<Typography variant={'body1'}>
									Clientes
								</Typography>
							</Grid>
							<Grid item xs={12} md={9}>
								<FormControl
									className={classes.formControl}
									fullWidth
									error={errorData.clientes.error}
								>
									<Select
										fullWidth
										labelId='label'
										name='clientes'
										value={0}
										onChange={addClients}
									>
										<MenuItem value={'0'}>
											{isClientsLoading
												? 'Cargando...'
												: 'Seleccione Clientes a Asociar'}
										</MenuItem>
										{clients &&
											clients
												?.filter(
													(client) =>
														!data.clientes
															.map(
																(cliente) =>
																	cliente.id
															)
															.includes(
																client.model
																	.client_user
															)
												)
												.map((client, i) => {
													const client_id =
														client.model
															.client_user;
													const name = `${client.relations.clientUser.model?.first_name} ${client.relations.clientUser.model?.last_name}`;
													const email =
														client.relations?.clientUser?.relations?.userContacts?.find(
															(contact) =>
																+contact.model
																	.type === 1
														)?.model?.value;
													const label = `${name} ${
														email
															? `(${email})`
															: ''
													}`;
													const value =
														JSON.stringify({
															id: client_id,
															nombre: label,
														});
													return (
														<MenuItem
															key={client_id}
															value={value}
														>
															{label}
														</MenuItem>
													);
												})}
									</Select>
									{errorData.clientes.error && (
										<FormHelperText>
											{errorData.clientes.helperText}
										</FormHelperText>
									)}
								</FormControl>
							</Grid>
						</Grid>
						{data.clientes.length > 0 && (
							<Grid container direction='column' xs={12}>
								<Grid item xs={12}>
									<Typography variant='h3'>
										Clientes a asociar
									</Typography>
									{data.clientes.map((client, i) => (
										<div
											key={client.id}
											className={classes.clientRow}
										>
											<b>#{client.id}</b>
											<span>{client.nombre}</span>
											<Button
												onClick={() =>
													removeClient(client.id)
												}
												className={classes.clientButton}
											>
												<Delete />
											</Button>
										</div>
									))}
								</Grid>
							</Grid>
						)}
						<div className={classes.buttonCnt}>
							<Button
								variant='contained'
								color='primary'
								type='submit'
								style={{
									alignSelf: 'flex-end',
								}}
								disabled={dossier.isLoading}
							>
								Guardar
							</Button>
						</div>
					</form>
				</Grid>
			</Grid>
		</Grid>
	);
}
