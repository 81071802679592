//import commonStyles from '../components/formsRelated'; didnt work as expected

const style = (theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: '20px',
	},
	rowContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: '20px',
		height: '100%',
		backgroundColor: 'white',
		border: '2px solid black',
		padding: '20px',
		[theme.breakpoints.down('sm')]: {
			gap: '1.5rem',
		},
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	clickable: {
		display: 'flex',
		width: '25px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
	},
	formContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		paddingLeft: '20px',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0',
			justifyContent: 'flex-start',
		},
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			justifyContent: 'space-between',
		},
	},
	buttonSpace: {
		alignSelf: 'flex-end',
	},
	button: {
		//	backgroundColor: '#e0e0e0',
		width: '100%',
		height: '100%',
	},
	alertError: {
		color: 'red',
		border: '1px solid red',
		padding: '10px',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffebee',
		borderRadius: '5px',
		width: '80%',
		alignSelf: 'center',
		position: 'relative',
	},
	closeIconAlert: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
		cursor: 'pointer',
		justifyContent: 'center',
		width: '20px',
		'&:hover': {
			color: 'black',
			borderRadius: '50%',
			aspectRatio: '1/1',
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
	alertSuccess: {
		color: 'green',
		border: '1px solid green',
		padding: '10px',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#e8f5e9',
		borderRadius: '5px',
		width: '80%',
		alignSelf: 'center',
		position: 'relative',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
});

export default style;
