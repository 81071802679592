import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/fileRelated";
import { Typography } from "@material-ui/core";
import ContentTable from "components/ContentTable/ContentTable";
const useStyles = makeStyles(styles);

const fakeRows = [
    {
        id: 12188,
        fecha: '12/08/2021',
        asunto: '0 Compra computadora',
        plazo: '31/08/2021',
        designado: 'Pendiente',
        revisadoPor: 'vcr,df',
        actions: ["visibility", "delete"]
    },
    {
        id: 12189,
        fecha: '12/08/2021',
        asunto: '1 Compra computadora',
        plazo: '31/08/2021',
        designado: 'Pendiente',
        revisadoPor: 'vcr,df',
        actions: ["visibility", "delete"]
    },
    {
        id: 12190,
        fecha: '12/08/2021',
        asunto: '2 Compra computadora',
        plazo: '31/08/2021',
        designado: 'Pendiente',
        revisadoPor: 'vcr,df',
        actions: ["visibility", "delete"]
    },
];

var fakeColumns = ['No. Registro', 'Fecha de Ingreso', 'Asunto', 'Plazo', 'Designado', 'Revisado por'];

export default function FetchedFile(props) { //commonly used for accodions, where you display fetched data. Theres another component to represent uploadedFiles with the FileUploader component
    const { fileRep } = props;
    const classes = useStyles();
    return (
        <div className={classes.fetchedFileContainer}>
            <div className={classes.fileInfoRow}>
                <i class="fas fa-file-pdf fa-5x"></i>
                <div className={classes.fileDetail}>
                    <Typography variant="caption">
                        Origen:
                    </Typography>
                    <Typography variant="subtitle2" className={classes.fileDetailValue}>
                        H-Amc
                    </Typography>
                </div>
                <div className={classes.fileDetail}>
                    <Typography variant="caption">
                        Correlativo:
                    </Typography>
                    <Typography variant="subtitle2" className={classes.fileDetailValue}>
                        222
                    </Typography>
                </div>
                <div className={classes.fileDetail}>
                    <Typography variant="caption">
                        Año:
                    </Typography>
                    <Typography variant="subtitle2" className={classes.fileDetailValue}>
                        2021
                    </Typography>
                </div>
            </div>
            <div className={classes.fileRevisions}>
                <Typography variant="caption">Comentarios:</Typography>
                <ContentTable pseudoRows={fakeRows} columnDefinitions={fakeColumns} renderActionsHeader />
            </div>
        </div>
    );
};