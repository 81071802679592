import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/contentTableStyle";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(styles);
export default function EntitledContainer(props) {
    const classes = useStyles();
    const { title } = props;

    return (
        <div className={classes.mainContainer}>
            {title && (
                <div className={classes.overflowingTitleBox}>
                    <Typography variant="h2"> {title}</Typography>
                </div>
            )}
            <div className={classes.entitledBoxContents}>
                {props.children}
            </div>
        </div>
    )
}