import React, { useEffect, useState } from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ContentTable from 'components/ContentTable/ContentTable';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserClients } from 'actions/userActions';
const useStyles = makeStyles(styles);

const columns = [
	{ name: 'ID', flex: 1, align: 'center', minWidth: 100 },
	{ name: 'Cliente', flex: 3, align: 'left', minWidth: 200 },
	//{ name: 'Estado', align: 'center' },
];

const USER_STATES_VALUES = Object.freeze({
	VERIFIED: 'Datos verificados',
	NOT_VERIFIED: 'Sin verificar datos',
	IN_PROGRESS: 'En proceso de verificación',
	ERROR: 'Error al verificar',
});

const USER_STATES = Object.freeze({
	'-3': USER_STATES_VALUES.ERROR,
	0: USER_STATES_VALUES.NOT_VERIFIED,
	1: USER_STATES_VALUES.VERIFIED,
});

function Clientes(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [rows, setRows] = useState([]);
	const user_clients = useSelector((state) => state.user_clients);

	useEffect(() => {
		if (!user_clients?.loading && user_clients?.clients?.length > 0) {
			const mappedRows = user_clients.clients.map((client) => {
				const name = `${client?.relations?.clientUser?.model?.first_name} ${client?.relations?.clientUser?.model?.last_name}`;
				const status = client?.relations?.clientUser?.model?.status;
				return {
					id: client?.model?.client_user,
					cliente: name,
					estado:
						USER_STATES[status] ?? USER_STATES_VALUES.IN_PROGRESS,
					actions: ['visibility'],
				};
			});
			console.log(mappedRows);
			setRows(mappedRows);
		}
	}, [user_clients]);

	const visibilityActionClick = (id) => {
		props.history.push('/clientes/cliente/' + id);
	};

	const nuevoCliente = () => {
		props.history.push('/registro_cliente');
	};

	useEffect(() => {
		dispatch(getUserClients());
	}, []);

	return (
		<div style={{ height: '100%' }}>
			<Grid container className={classes.accesoRapidoContainer}>
				<Grid item xs={12} sm={4}>
					<div className={classes.accesoRapidoContentContainer}>
						<div
							className={classes.accesoRapidoButton}
							onClick={nuevoCliente}
						>
							<Typography variant='caption'>
								Nuevo Cliente
							</Typography>
						</div>
					</div>
				</Grid>
			</Grid>
			<ContentTable
				title='Clientes'
				pseudoRows={rows}
				columnDefinitions={columns}
				renderActionsHeader
				visibilityHandler={visibilityActionClick}
				loading={user_clients?.loading}
				onRowClick={(row) => visibilityActionClick(row?.id)}
			/>
		</div>
	);
}

export default withRouter(Clientes);
