const style = (theme) => ({

    container: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row'
        },
        padding: '16px',
        boxShadow: theme.customConstants.defaultBoxShadow,
        border: theme.customConstants.defaultBorder,
    },
    fetchedFileContainer:{
        width:'100%',
        padding: '16px',
        boxShadow: theme.customConstants.defaultBoxShadow,
        border: theme.customConstants.defaultBorder,    
    },
    rightSideColumn: {
        [theme.breakpoints.up('sm')]: {
            width: '50px',
            justifySelf: 'end',
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'space-evenly'
        }
    },
    mainColumn: {
        marginRight: '50px',
        width: '100%',
    },
    fileInfoRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
            justifyContent:'center',
            marginBottom:'10px'
        }
    },
    fileIcon: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom:'10px'
    },
    fileDetail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '10px'
        },
    },
    fileDetailValue: {
        marginLeft: '5px'
    },
    fileRevisions: {
        marginTop: '15px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    fieldWithLabel:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        alignItems:'center',
        [theme.breakpoints.up('sm')]: {
			marginLeft:'10px'
		},
    },
    field:{
        marginLeft:'5px'
    }

});

export default style;