import React from "react";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
//import 'draft-js/dist/Draft.css';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { makeStyles } from "@material-ui/core/styles";
import styles from 'assets/jss/material-dashboard-react/components/richTextBoxStyle';

const useStyles = makeStyles(styles);

export default function RichTextBox(props) {
    const classes = useStyles();
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
    const editor = React.useRef(null);
    const wrapperStyle = {
        border: '1px solid red',
        height: '500rem'
    }
    const editorStyle = {
        minHeight:'500px',
        padding:'1rem',
        border: '1px solid red',
    }
    return (
        <div className={classes.editorContainer}>
            <Editor
                editorState={editorState}
                wrapperClassName={wrapperStyle}
                editorClassName={editorStyle}

                editorStyle={{ height: "300px", overflow: 'auto', background:'white'}}
                toolbarClassName={classes.toolbar}
                toolbar={
                    {
                        options: ['inline', 'list', 'blockType', 'fontSize', 'textAlign',
                            'history', 'colorPicker', 'link'],

                        inline: {
                            options: ['italic', 'bold', 'underline', 'strikethrough'],
                            bold: { className: 'demo-option-custom' },
                            italic: { className: 'demo-option-custom' },
                            underline: { className: 'demo-option-custom' },
                            strikethrough: { className: 'demo-option-custom' },
                            monospace: { className: 'demo-option-custom' },
                            superscript: { className: 'demo-option-custom' },
                            subscript: { className: 'demo-option-custom' }
                        },
                        list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered', 'indent', 'outdent'],
                            unordered: { className: undefined },
                            ordered: { className: undefined },
                            indent: { className: undefined },
                            outdent: { className: undefined },
                        },
                        blockType: {
                            className: 'demo-option-custom-wide',
                            dropdownClassName: 'demo-dropdown-custom'
                        },
                        fontSize: { className: 'demo-option-custom-medium' },
                    }
                }
                onEditorStateChange={(newState)=>setEditorState(newState)}
            />
        </div>
    )
};
