import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/glifos/mainLayoutStyle';
import logo from '../../assets/img/logo.svg';

import MessageBox from '../../components/MessageBox/MessageBox';
import LoadingBox from '../../components/LoadingBox/LoadingBox';

import Axios from 'services/Api';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(styles);

function AccessUser(props) {
	const classes = useStyles();
	const { accessToken } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [passwordError, setPasswordError] = useState(false);
	const [repeatPasswordError, setRepeatPasswordError] = useState(false);
	const isValidData = () => {
		if (password === '' || repeatPassword === '') {
			setPasswordError(true);
			setRepeatPasswordError(true);
			setError('Por favor ingrese una contraseña válida');
			return false;
		}
		if (password !== repeatPassword) {
			setPasswordError(true);
			setRepeatPasswordError(true);
			setError('Las contraseñas no coinciden');
			return false;
		}
		return true;
	};

	const sendData = async () => {
		setIsLoading(true);
		try {
			const { data } = await Axios.ValidateUser({
				token: accessToken,
				password: password,
			});
			if (Boolean(data?.success)) {
				props.history.push('/login');
			} else {
				setError(data?.error_log);
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (isValidData()) {
			sendData();
		}
	};

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div className={classes.paper}>
				{
					<div className={classes.mainLogo}>
						<img
							src={logo}
							alt='Logo'
							className={classes.fitImage}
						/>
					</div>
				}
				<Typography component='h1' variant='h2'>
					Validación de Usuario
				</Typography>
				{isLoading && <LoadingBox></LoadingBox>}

				<form
					className={classes.form}
					noValidate
					onSubmit={submitHandler}
				>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='password'
						label='Contraseña'
						name='password'
						autoComplete='current-password'
						error={passwordError}
						autoFocus
						type='password'
						className={classes.input}
						onChange={(e) => {
							setPassword(e.target.value);
							setPasswordError(false);
							setRepeatPasswordError(false);
							setError('');
						}}
					/>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='repeatPassword'
						label='Repeat Password'
						type='password'
						id='repeatPassword'
						error={repeatPasswordError}
						autoComplete='current-password'
						className={classes.input}
						onChange={(e) => {
							setRepeatPassword(e.target.value);
							setRepeatPasswordError(false);
							setPasswordError(false);
							setError('');
						}}
					/>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						className={classes.submit}
					>
						Válidar Usuario
					</Button>

					{(repeatPasswordError || passwordError || error) && (
						<MessageBox variant='danger' severity='error'>
							{error}
						</MessageBox>
					)}
				</form>
			</div>
		</Container>
	);
}

export default AccessUser;
