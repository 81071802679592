import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-dashboard-react/components/stepsStyle';

const useStyles = makeStyles(styles);

export default function Step(props) {
	const classes = useStyles();
	let stepClass = (props.step == props.currentStep)? "actualStep": ((props.step < props.currentStep)?"stepDone":"stepNext");
	return (
		<div className={classes.step}>
			<div
				className={
					/*props.step <= props.currentStep
						? classes.actualStep
						: classes.stepNext*/
					classes[stepClass]
				}>
				<Typography className={classes.stepNumberLabel} variant='caption'>{props.step}</Typography>
			</div>
			<div className={classes.text}>
				<Typography variant='body1'>{props.title}</Typography>
			</div>
		</div>
	);
}
