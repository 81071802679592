import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
	Grid,
	TextField,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	FormHelperText,
} from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-react/components/registroDocumentoStyle';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

export default function RegistroDocumentos(props) {
	const classes = useStyles();

	const document_types = useSelector((state) => state.document_types);

	const { titulo, tipo_documento, descripcion, rfc, curp, ine } =
		props.documentData;

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				<Typography variant='h3'>
					Ingrese los detalles del documento
				</Typography>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={3}>
					<Typography variant='body1'>Título</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<TextField
						error={props.error.titulo}
						helperText={
							props.error.titulo && 'El campo es obligatorio'
						}
						fullWidth
						value={titulo}
						name='titulo'
						onChange={props.handleChange}
					/>
				</Grid>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={3}>
					<Typography variant='body1'>Tipo de documento</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<FormControl
						className={classes.formControl}
						error={props.error.tipo_documento}
					>
						<Select
							fullWidth
							labelId='label'
							name='tipo_documento'
							value={tipo_documento}
							onChange={props.handleChange}
						>
							<MenuItem value={'0'}>
								Seleccione tipo de documento
							</MenuItem>
							{document_types.document_types &&
								document_types.document_types.map((tipo, i) => (
									<MenuItem key={i} value={tipo.id}>
										{tipo.name}
									</MenuItem>
								))}
						</Select>
						{props.error.tipo_documento && (
							<FormHelperText>
								Debe seleccionar que tipo de documento esta
								registrando
							</FormHelperText>
						)}
					</FormControl>
				</Grid>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={3} className={classes.alignStart}>
					<Typography variant='body1'>Descripción</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<TextField
						fullWidth
						rows={6}
						multiline
						value={descripcion}
						name='descripcion'
						onChange={props.handleChange}
					/>
				</Grid>
			</div>
			{/* <div className={classes.row}>
				<Grid item xs={12} md={3} className={classes.alignStart}>
					<Typography variant='body1'>Identificación</Typography>
				</Grid>
				<Grid item xs={12} md={9}>
					<FormControl component='fieldset'>
						<FormGroup>
							<FormControlLabel
								className={rfc ? classes.formControl : ''}
								control={
									<Checkbox
										name='rfc'
										checked={rfc}
										onChange={props.handleChange}
									/>
								}
								label='Requerir RFC'
							/>
							<FormControlLabel
								className={curp ? classes.formControl : ''}
								control={
									<Checkbox
										name='curp'
										checked={curp}
										onChange={props.handleChange}
									/>
								}
								label='Requerir CURP'
							/>
							<FormControlLabel
								className={ine ? classes.formControl : ''}
								control={
									<Checkbox
										name='ine'
										checked={ine}
										onChange={props.handleChange}
									/>
								}
								label='Requerir INE'
							/>
						</FormGroup>
					</FormControl>
				</Grid>
			</div> */}
		</div>
	);
}
