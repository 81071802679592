///NETWORK RELATED
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_REQUEST_SUCCESS = 'UPLOAD_REQUEST_SUCCESS';
export const UPLOAD_REQUEST_ERROR = 'UPLOAD_REQUEST_ERROR';
///END OF NETWORK RELATED

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_ERROR = 'DELETE_REQUEST_ERROR';

///DynamicFileLists related
export const SAVE_REQUEST = 'SAVE_REQUEST';
export const SAVE_REQUEST_SUCCESS = 'SAVE_REQUEST_SUCCESS';
export const SAVE_REQUEST_ERROR = 'SAVE_REQUEST_ERROR';

//END OF DynamicFileLists related

//Document types related
export const DOC_TYPES_REQUEST = 'DOC_TYPES_REQUEST';
export const DOC_TYPES_REQUEST_SUCCESS = 'DOC_TYPES_REQUEST_SUCCESS';
export const DOC_TYPES_REQUEST_ERROR = 'DOC_TYPES_REQUEST_ERROR';
//END OF Document types
