import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

//import styles from 'assets/jss/material-dashboard-react/views/revisionStyle';
import {
	Grid,
	Typography,
	Button,
	Chip,
	Collapse,
	FormControlLabel,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Icon,
} from '@material-ui/core';
import { withRouter } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FileUploader from 'components/FileUploader/FileUploader';
import PDFViewer from 'components/PDFViewer/PDFViewer';
import ModalClientes from 'components/Modal/ModalClientes';
import Firma from 'components/Firma/Firma';
import { useParams } from 'react-router-dom';
import { getDossier } from 'actions/dossierActions';
import { getUserClients } from 'actions/userActions';
import { updateDossier } from 'actions/dossierActions';
import { deleteFile } from 'actions/documentActions';
import { getDossierHistory } from 'actions/dossierActions';
import { prepare, finish } from '../../actions/signActions';
import ApiURLs from '../../config/ApiURLs.js';
import { getDossierComments } from 'actions/dossierActions';
import { requestSignature } from 'actions/dossierActions';
import Swal from 'sweetalert2';
import { addDocuments } from 'actions/dossierActions';
import { finalizeDossier } from 'actions/dossierActions';
import { FormControl } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import Axios from 'services/Api';
import { USER_STATES_VALUES } from 'utils/constants';
import StepsExpediente from 'components/Steps/StepsExpediente';
import { DOSSIER_STATES } from 'utils/constants';
import LoadingBox from 'components/LoadingBox/LoadingBox';
import RevisionGenerales from './RevisionGenerales/RevisionGenerales';
import FirmaSelection from 'components/FirmaSelection/FirmaSelection';
import { WORD_MIME_TYPES } from 'utils/constants';

const styles = (theme) => ({
	verticalCenter: {
		display: 'flex',
		alignItems: 'center',
	},
	leftContainer: {
		padding: '1.1rem',
		gap: '1rem',
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
	},
	rightContainer: {
		padding: '1.1rem',
		gap: '1rem',
		display: 'flex',
		width: '100%',
		border: '1px solid black',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	rightContainerWClientes: {
		backgroundColor: 'white',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',
	},
	rightContainerButtons: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		marginTop: 'auto',
	},
	processContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	clienteRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		minHeight: '2.5rem',
		alignItems: 'center',
		marginBottom: '1rem',
		color: '#000000',
		backgroundColor: '#42BBEC',
	},

	clientID: {
		fontWeight: 'bold',
	},
	versionContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},
	versionRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		height: '2.5rem',
		alignItems: 'center',
		marginBottom: '1rem',
	},
	version: {
		display: 'flex',
		backgroundColor: '#42BBEC',
		padding: '5px',
		alignItems: 'center',
		cursor: 'pointer',
		marginRight: '5px',
		color: '#000000',
	},
	clientRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		gap: '5px',
	},
	clienteRow: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		minHeight: '2.5rem',
		alignItems: 'center',
		marginBottom: '1rem',
		gap: '5px',
		color: '#000000',
		backgroundColor: '#42BBEC',
	},
	cliente: {
		display: 'flex',
		padding: '5px',
		alignItems: 'center',
		cursor: 'pointer',
		fontSize: '0.9rem',
	},
	fecha: {
		fontSize: '0.9rem',
	},
	chipWarning: {
		backgroundColor: '#ff9800',
		color: '#fff',
	},
	chipSuccess: {
		backgroundColor: '#4caf50',
		color: '#fff',
	},
	chipDanger: {
		backgroundColor: '#f44336',
		color: '#fff',
	},
	chipInfo: {
		backgroundColor: '#2196f3',
		color: '#fff',
	},
	clienteRowSelected: {
		border: '2px solid black',
	},
	clienteRowDone: {
		backgroundColor: '#3b6fb5',
		color: '#fff',
	},

	modal: {
		'& > div >div': {
			[theme.breakpoints.up('sm')]: {
				maxWidth: '65vw',
				width: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				maxWidth: '100vw',
			},
		},
	},
	textAreaContainer: {
		overflowY: 'initial',
	},
	textArea: {
		width: '100%',
		resize: 'none',
		boxSizing: 'border-box',
		'-webkit-box-sizing': 'border-box',
		'-moz-box-sizing': 'border-box',
	},
	buttonSpace: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',
		justifyContent: 'space-between',
	},

	modalTitle: {
		'& > h2': {
			fontSize: '1.5rem',
		},
	},
	signerContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
	},
});

const useStyles = makeStyles(styles);

function RevisionDocumento(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [step, setStep] = useState(1);
	const [newVersion, setNewVersion] = useState(false);
	const [showClientes, setShowClientes] = useState(false);
	const [file, setFile] = useState({
		archivo: '',
	});
	const [actualClient, setActualClient] = useState(0);
	const [isSelectingSignPosition, setIsSelectingSignPosition] =
		useState(false);
	const [signPositions, setSignPositions] = useState([]);
	const [password, setPassword] = useState('');
	const [meeting, setMeeting] = useState('');
	const [showModalMeeting, setShowModalMeeting] = useState(false);
	const [showModalVerifications, setShowModalVerifications] = useState(false);
	const [isLookingFile, setIsLookingFile] = useState(true);
	const [showComments, setShowComments] = useState(true);
	const [isSignRequest, setIsSignRequest] = useState(false);
	const [showSignForm, setShowSignForm] = useState(false);
	const [fileUpload2, setFileUpload2] = useState(false);
	const [signID, setSignID] = useState(null);

	const { archivos } = useSelector((state) => state.document);
	const { comments } = useSelector((state) => state.dossierComments);
	const { history } = useSelector((state) => state.dossierHistory);
	const { clients } = useSelector((state) => state.user_clients);
	const { userInfo } = useSelector((state) => state.userSignin);
	const signRequest = useSelector((state) => state.signRequest);
	const requestDraft = useSelector((state) => state.requestDraft);

	const [fileUpload, setFileUpload] = useState(false);

	const { loading: isDossierLoading, data: dossier } = useSelector(
		(state) => state.dossier
	);

	const goBack = () => {
		props.history.goBack();
	};
	const handleVersion = (version, rest) => {
		//console.log(version, rest);
		if (version == file.archivo) return;
		dispatch(
			getDossierComments(
				dossier?.relations.sections[0].relations.documents[0].model.id,
				version
			)
		);
		setFile({
			archivo: `${ApiURLs.baseURL}file/get?id=${version}`,
			id: version,
			isWordFile: WORD_MIME_TYPES.includes(rest?.mime),
		});
	};
	const getClientName = (id) => {
		const client = clients?.find((item) => item.model.client_user == id);
		return `${client?.relations.clientUser.model.first_name} ${client?.relations.clientUser.model.last_name}`;
	};
	const resolvedComments = async (id) => {
		const comment = comments.find((item) => item.model.id == id);
		if (comment?.model?.status == 0) return;
		try {
			const { data } = await Axios.ResolveComment({
				comment_id: id,
			});
			if (data?.result) {
				dispatch(
					getDossierComments(
						dossier?.relations.sections[0].relations.documents[0]
							.model.id,
						dossier?.relations.sections[0].relations.documents[0]
							.model.file
					)
				);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const unResolvedComments = async (id) => {
		const comment = comments.find((item) => item.model.id == id);
		if (comment?.model?.status == 1) return;
		try {
			const { data } = await Axios.unResolveComment({
				comment_id: id,
			});
			if (data?.result) {
				dispatch(
					getDossierComments(
						dossier?.relations.sections[0].relations.documents[0]
							.model.id,
						dossier?.relations.sections[0].relations.documents[0]
							.model.file
					)
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const generateMeeting = () => {
		const clients_problem = /* dossier?.relations?.sections
			?.find((section) => +section?.model?.type === 6)
			?.relations?.subSections?.filter(
				(signer) => signer?.model?.status < 100
			); */ [];
		if (clients_problem.length > 0) {
			setShowModalVerifications(true);
		} else {
			setShowModalMeeting(true);
		}
	};
	const requestZoomMeeting = async () => {
		try {
			const post_data = {
				dossier_id: id,
				topic: dossier?.model?.name,
				start_time: new Date().toISOString(),
				meeting: meeting,
			};
			setShowModalMeeting(false);

			const { data } = await Axios.CreateZoomMeeting(post_data);
			if (data?.redirect) {
				/* window.open(data.redirect, '_blank');
				dispatch(getDossier(id)); */
				//window.location = data.redirect;
				dispatch(getDossier(id));
			}
			//console.log(data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		dispatch(getDossier(id));
		dispatch(getUserClients());
	}, [id]);

	useEffect(() => {
		if (dossier?.model?.id) {
			dispatch(
				getDossierHistory(
					dossier?.relations.sections[0]?.relations.documents[0].model
						.id,
					dossier?.relations.sections[0]?.relations.documents[0].model
						.file
				)
			);
			dispatch(
				getDossierComments(
					dossier?.relations.sections[0].relations.documents[0].model
						.id,
					dossier?.relations.sections[0].relations.documents[0].model
						.file
				)
			);
			const file_data =
				dossier?.relations?.sections?.[0]?.relations?.documents?.[0]
					?.relations?._File0?.[0]?.model;
			setFile({
				archivo: `${ApiURLs.baseURL}file/get?id=${dossier?.relations?.sections[0]?.relations?.documents[0]?.model?.file}`,
				id: dossier?.relations?.sections[0]?.relations?.documents[0]
					?.model?.file,
				isWordFile: WORD_MIME_TYPES.includes(file_data?.mime),
			});
		}
		if (dossier?.model?.status > 2071 && dossier?.model?.status < 2130) {
			const firmas = dossier?.relations?.sections?.filter(
				(section) => +section?.model?.type === 4
			);
			const positions = firmas.map((firma) => {
				const data = JSON.parse(firma?.model?.data)?.transaction
					?.signature_info;
				const page = data?.page;
				const x = +data?.x;
				const y = +data?.y;
				const endX = x + +data?.width;
				const endY = y + +data?.height;
				const id = firma?.model?.owner;
				return { id, page, x, y, endX, endY, blocked: true };
			});
			setSignPositions(positions);
			setIsSignRequest(true);
		}
		if (dossier?.model?.status > 2130) {
			setIsSignRequest(true);
		}
	}, [dossier]);

	useEffect(() => {
		const getStatus = (status) =>
			DOSSIER_STATES.find(
				(step) => step.minRange <= status && step.maxRange >= status
			)?.step || 1;
		if (dossier) {
			setStep(getStatus(dossier?.model?.status));
		}
	}, [dossier]);

	const sentToRevision = () => {
		const file_id = archivos[0].id;
		dispatch(updateDossier(id, file_id, () => dispatch(getDossier(id))));
		dispatch(deleteFile(file_id));
		setFileUpload(false);
	};
	const firmaNotario = (page, x, y, endX, endY) => {
		setSignPositions([{ id: actualClient?.id, page, x, y, endX, endY }]);
	};
	const handleFirmar = () => {
		if (password === '' || password === undefined) {
			Swal.fire({
				icon: 'error',
				title: 'Campos requeridos',
				text: 'Por favor ingrese todos los campos',
			});
			return;
		}
		const sign = [...signPositions];
		setSignPositions([]);
		dispatch(
			finish(
				signRequest.signInfo.request_id,
				signID,
				password,
				sign[0],
				(result) => {
					if (result) setStep(step + 0.5);
				}
			)
		);
	};

	const handleSignPosition = (page, x, y, endX, endY) => {
		if (signPositions.some((item) => item.id == actualClient?.id)) {
			setSignPositions([
				...signPositions.filter((item) => item.id != actualClient?.id),
				{ id: actualClient?.id, page, x, y, endX, endY },
			]);
		} else {
			setSignPositions([
				...signPositions,
				{ id: actualClient?.id, page, x, y, endX, endY },
			]);
		}
		setIsSelectingSignPosition(false);
		setActualClient(null);
	};

	const removeSignPosition = (id) => {
		setSignPositions([...signPositions.filter((item) => item.id != id)]);
	};

	const prepareSignatureRequest = async () => {
		//console.log(signPositions);
		dispatch(
			prepare(
				dossier?.relations.sections[0]?.relations.documents[0]?.model.id
			)
		);
	};
	const finalizarExpediente = async () => {
		console.log(archivos);
		/* archivos.forEach((archivo) => {
			dispatch(addDocuments(id, archivo.id));
			dispatch(deleteFile(archivo.id));
		}); 
		await dispatch(finalizeDossier(id));
		Swal.fire('Expediente finalizado!', '', 'success');
		window.location.href = '/dashboard'; */

		dispatch(
			addDocuments(id, archivos, () => dispatch(finalizeDossier(id)))
		);
	};

	useEffect(() => {
		if (signRequest.isSetup && step == 6) setShowSignForm(true);
	}, [signRequest]);

	const addNewVersion = () => {
		if (!newVersion) {
			setNewVersion(true);
			return;
		}

		if (fileUpload) {
			if (
				comments.filter((comment) => comment.model.status == 1).length >
				0
			) {
				Swal.fire({
					icon: 'info',
					title: 'Comentarios sin resolver',
					text: 'Quiere marcar los comentarios pendientes como resueltos?',
					showDenyButton: true,
					confirmButtonText: 'Continuar',
					denyButtonText: `Cancelar`,
				}).then((result) => {
					console.log(result);
					if (result.isConfirmed) {
						const resolveAll = async () => {
							if (
								comments.filter(
									(comment) => comment.model.status == 1
								).length == 0
							)
								return;
							try {
								const { data } = await Axios.ResolveAllComments(
									{
										comments: comments
											.filter(
												(comment) =>
													comment.model.status == 1
											)
											.map((item) => item.model.id),
									}
								);
								if (data?.result) {
									const file_id = archivos[0].id;
									dispatch(
										updateDossier(id, file_id, () =>
											dispatch(getDossier(id))
										)
									);
									dispatch(deleteFile(file_id));
									setNewVersion(false);
								}
							} catch (error) {
								console.log(error);
							}
						};
						resolveAll();

						/* const file_id = archivos[0].id;
						dispatch(updateDossier(id, file_id));
						dispatch(deleteFile(file_id));
						setNewVersion(false); */
					}
				});
			} else {
				const file_id = archivos[0].id;
				dispatch(
					updateDossier(id, file_id, () => dispatch(getDossier(id)))
				);
				dispatch(deleteFile(file_id));
				setNewVersion(false);
			}
			/* setInterval(() => {
				dispatch(getDossier(id));
			}, 2000); */
			/* setInterval(() => {
				dispatch(
					getDossierHistory(
						dossier?.relations.sections[0].relations.documents[0]
							.model.id,
						dossier?.relations.sections[0].relations.documents[0]
							.model.file
					)
				);
			}, 3000); */
		} else {
		}
	};

	useEffect(() => {
		if (signRequest.isSetup && step == 4) setStep(step + 0.5);
	}, [signRequest]);

	useEffect(() => {
		if (requestDraft.requestDone) {
			dispatch(getDossier(id));
			dispatch(
				getDossierHistory(
					dossier?.relations.sections[0].relations.documents[0].model
						.id,
					dossier?.relations.sections[0].relations.documents[0].model
						.file
				)
			);
		}
	}, [requestDraft.requestDone]);

	const setClientSign = (client) => {
		if (signPositions.some((item) => item.id == client?.id)) return;
		if (actualClient?.id == client.id) {
			setActualClient(null);
			setIsSelectingSignPosition(false);
			return;
		}
		setActualClient(client);
		setIsSelectingSignPosition(true);
	};

	const requestSign = () => {
		if (
			signPositions.length == 0 ||
			Object.values(dossier.model.data.data.signers.client).length !=
				signPositions.length
		) {
			Swal.fire({
				icon: 'error',
				title: 'Firma(s) no seleccionada(s)',
				text: 'Debe seleccionar la posición de las firmas.',
			});
			return;
		}
		//console.log(signPositions);
		const signs = [...signPositions];
		dispatch(requestSignature(id, signs));
		setSignPositions([]);
		//setStep(step + 0.3);
		Swal.fire('Solicitud de firmas enviada', '', 'success');
	};

	return (
		<Grid container>
			<Grid direction='row' container>
				<Button onClick={goBack}>
					<ArrowBackIcon />
				</Button>
				<Typography
					variant='h3'
					className={classes.verticalCenter}
					align='center'
				>
					{isDossierLoading ? 'Cargando...' : dossier?.model?.name}
				</Typography>
			</Grid>
			<Grid direction='row' container>
				<Grid
					item
					xs={12}
					sm={4}
					lg={3}
					className={classes.leftContainer}
				>
					<Typography
						variant={'h3'}
						className={classes.verticalCenter}
					>
						Proceso
					</Typography>
					<StepsExpediente step={step} />
					{step > 3 && (
						<div className={classes.processContainer}>
							<Typography
								variant={'h3'}
								className={classes.titleContainer}
							>
								Clientes asociados
							</Typography>
							<div className={classes.clientContainer}>
								{dossier?.relations?.sections
									.filter(
										(section) => section?.model?.type == 6
									)?.[0]
									?.relations?.subSections?.filter(
										(subsection) =>
											dossier?.model?.status < 2071 ||
											+subsection.model.weight >= 10
									)
									?.map((signer, i) => {
										const client =
											signer?.relations?.dossier0[0];
										const status = signer?.model?.status;
										return (
											<div
												className={classes.clientRow}
												key={i}
											>
												<div
													className={classes.clientID}
												>
													#{client.model.code1}
												</div>
												<div
													className={
														classes.clientName
													}
												>
													{`${client?.model?.name}`}
												</div>
												<Chip
													label={
														USER_STATES_VALUES[
															status
														]
													}
													color='secondary'
													size='small'
													className={
														+status === -50
															? classes.chipDanger
															: [0, 52].includes(
																	status
															  )
															? classes.chipWarning
															: status >= 100
															? classes.chipSuccess
															: classes.chipInfo
													}
												/>
											</div>
										);
									})}
							</div>
						</div>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					lg={9}
					className={classes.rightContainer}
					style={{
						flexDirection: [4, 8].includes(step) ? 'row' : 'column',
					}}
				>
					{isDossierLoading && (
						<LoadingBox message='Cargando expediente...' />
					)}
					{!isDossierLoading &&
						isSignRequest &&
						!showSignForm &&
						step >= 5 &&
						step <= 6 && (
							<Grid item xs={12}>
								<div className={classes.note}>
									<Typography
										variant='h3'
										className={classes.titleContainer}
									>
										Marca el lugar donde colocaremos las
										firmas
									</Typography>
									Para indicar donde debemos colocar as
									firmas, sigue los siguientes pasos:
									<ol>
										<li>
											Haga click en el nombre del firmante
										</li>
										<li>
											Dibuja el área donde quieres que la
											firma quede estampada.
										</li>
									</ol>
								</div>
							</Grid>
						)}
					{!isDossierLoading && step === 2 && (
						<RevisionGenerales
							clients={
								dossier?.relations?.sections.filter(
									(section) => section?.model?.type == 6
								)?.[0]?.relations?.subSections ?? []
							}
							actions
						/>
					)}
					{!isDossierLoading && step === 3 && (
						<div className={classes.rightContainerWClientes}>
							{showClientes && (
								<RevisionGenerales
									clients={
										dossier?.relations?.sections.filter(
											(section) =>
												section?.model?.type == 6
										)?.[0]?.relations?.subSections ?? []
									}
									actions
								/>
							)}

							{!showClientes && (
								<>
									<Typography variant='h3'>
										Carga de Borrador
									</Typography>
									<FileUploader
										allowedFiles={['.pdf', '.doc', '.docx']}
										singleFile={true}
										setFileUpload={setFileUpload}
									/>
								</>
							)}
							<div className={classes.rightContainerButtons}>
								<Button
									variant='contained'
									color='primary'
									onClick={() => {
										setShowClientes(
											(prevState) => !prevState
										);
									}}
									className={classes.button}
								>
									{showClientes
										? 'Carga de Borrador'
										: 'Ver Clientes'}
								</Button>
								<Button
									variant='contained'
									color='primary'
									onClick={() => {
										sentToRevision();
									}}
									className={classes.button}
									disabled={!fileUpload}
								>
									Guardar Borrador
								</Button>
							</div>
						</div>
					)}
					{!isDossierLoading && step === 4 && (
						<>
							<Grid
								item
								md={3}
								sm={4}
								xs={12}
								className={classes.leftColumn}
							>
								<Typography
									variant={'h3'}
									className={classes.titleContainer}
								>
									{history.length + 1} versiones
								</Typography>
								<div className={classes.versionContainer}>
									{dossier?.relations?.sections
										?.filter(
											(section) =>
												+section?.model?.type === 2
										)
										?.map((version, i) => (
											<div
												className={classes.versionRow}
												key={version.model.id}
											>
												<div
													className={classes.version}
													onClick={() =>
														handleVersion(
															version?.relations
																?.documents?.[0]
																?.relations
																?._File0?.[0]
																?.model.id,
															version?.relations
																?.documents?.[0]
																?.relations
																?._File0?.[0]
																?.model
														)
													}
												>
													Versión {history.length + 1}
												</div>
												<div className={classes.fecha}>
													{new Date(
														version.relations.documents[0]?.model.created_on
													).toLocaleDateString()}
												</div>
											</div>
										))}
									{history.length > 0 &&
										history.map((version, i) => (
											<div
												className={classes.versionRow}
												key={i}
											>
												<div
													className={classes.version}
													onClick={() =>
														handleVersion(
															version?.model
																?.file,
															version?.relations
																?._File0?.[0]
																?.model
														)
													}
												>
													Versión {history.length - i}{' '}
												</div>
												<div className={classes.fecha}>
													{new Date(
														version?.relations?.document0?.[0]?.model?.created_on
													).toLocaleDateString()}
												</div>
											</div>
										))}
								</div>
							</Grid>
							<Grid
								item
								md={10}
								sm={12}
								xs={12}
								className={classes.formContainer}
							>
								{newVersion && (
									<>
										<FileUploader
											allowedFiles={
												dossier?.model?.status < 2069
													? ['.pdf', '.doc', '.docx']
													: ['.pdf']
											}
											singleFile={true}
											setFileUpload={setFileUpload.bind(
												this
											)}
										/>
										{dossier?.model?.status != 2069 && (
											<div style={{ color: 'red' }}>
												Al cambiar la version del
												documento, las revisiones
												realizadas anteriormente ya no
												serán válidas
											</div>
										)}
									</>
								)}
								{!newVersion && (
									<PDFViewer url={file.archivo} file={file} />
								)}
								{dossier?.model?.status > 2040 &&
									!newVersion && (
										<Collapse in={showComments}>
											{comments.filter(
												(comment) =>
													comment.model.status == 1
											).length > 0 && (
												<>
													<Typography variant='h3'>
														Comentarios Pendientes:
													</Typography>
													<FormControl
														component='fieldset'
														style={{
															width: '100%',
														}}
													>
														<FormGroup>
															{comments
																.filter(
																	(comment) =>
																		comment
																			.model
																			.status ==
																		1
																)
																.map(
																	(
																		comment,
																		i
																	) => (
																		<FormControlLabel
																			key={
																				comment
																					.model
																					.id
																			}
																			control={
																				<Checkbox
																					name={
																						comment
																							.model
																							.id
																					}
																					onChange={() =>
																						resolvedComments(
																							comment
																								.model
																								.id
																						)
																					}
																				/>
																			}
																			style={{
																				color: 'black',
																			}}
																			label={`# ${
																				comment
																					.model
																					.user
																			}  ${getClientName(
																				comment
																					.model
																					.user
																			)}: ${
																				comment
																					.model
																					.comment
																			}`}
																		/>
																	)
																)}
														</FormGroup>
													</FormControl>
												</>
											)}
											{comments.filter(
												(comment) =>
													comment.model.status == 0
											).length > 0 && (
												<>
													<Typography variant='h3'>
														Comentarios Finalizados:
													</Typography>
													<FormControl
														component='fieldset'
														style={{
															width: '100%',
														}}
													>
														<FormGroup>
															{comments
																.filter(
																	(comment) =>
																		comment
																			.model
																			.status ==
																		0
																)
																.map(
																	(
																		comment,
																		i
																	) => (
																		<FormControlLabel
																			key={
																				comment
																					.model
																					.id
																			}
																			control={
																				<Checkbox
																					name={
																						comment
																							.model
																							.id
																					}
																					defaultChecked={
																						true
																					}
																					onChange={() =>
																						unResolvedComments(
																							comment
																								.model
																								.id
																						)
																					}
																				/>
																			}
																			style={{
																				color: 'black',
																			}}
																			label={`# ${
																				comment
																					.model
																					.user
																			}  ${getClientName(
																				comment
																					.model
																					.user
																			)}: ${
																				comment
																					.model
																					.comment
																			}`}
																		/>
																	)
																)}
														</FormGroup>
													</FormControl>
												</>
											)}
										</Collapse>
									)}
								<div className={classes.buttonSpace}>
									<Button
										variant='contained'
										color='primary'
										onClick={() =>
											setNewVersion(!newVersion)
										}
										className={classes.button}
									>
										{newVersion
											? 'Cancelar'
											: dossier?.model?.status == 2069
											? 'Subir versión Final'
											: 'Cambiar versión'}
									</Button>
									{newVersion && (
										<Button
											variant='contained'
											color='primary'
											onClick={() => addNewVersion()}
											className={classes.button}
										>
											Guardar documento
										</Button>
									)}
									{dossier?.model?.status < 2011 && (
										<ModalClientes
											clientes={
												dossier?.model?.data.data
													.signers?.client
											}
											file={{
												documento: dossier?.model?.name,
												version: history.length + 1,
											}}
										/>
									)}
								</div>
							</Grid>
						</>
					)}
					{!isDossierLoading && step === 5 && (
						<Grid
							item
							xs={12}
							className={
								isSignRequest ? classes.signerContainer : ''
							}
						>
							{isSignRequest && (
								<Grid item md={3} sm={12} xs={12}>
									<Typography
										variant={'h4'}
										className={classes.titleContainer}
									>
										Firmas disponibles
									</Typography>
									<div className={classes.versionContainer}>
										{dossier?.model?.data.data.signers
											?.client &&
											Object.values(
												dossier.model.data.data.signers
													.client
											).map((id, i) => {
												const client = clients?.find(
													(item) =>
														item.model
															.client_user == id
												);
												return (
													<div
														className={`${
															classes.clienteRow
														} ${
															actualClient?.id ===
															client?.relations
																.clientUser
																.model.id
																? classes.clienteRowSelected
																: ''
														} ${
															signPositions?.find(
																(sign) =>
																	sign.id ==
																	client
																		?.relations
																		.clientUser
																		.model
																		.id
															)
																? classes.clienteRowDone
																: ''
														} `}
														onClick={() => {
															if (isSignRequest)
																setClientSign({
																	id: client
																		?.relations
																		.clientUser
																		.model
																		.id,
																	name: `${client?.relations.clientUser.model.first_name} ${client?.relations.clientUser.model.last_name}`,
																});
														}}
														key={i}
													>
														<div
															className={
																classes.cliente
															}
														>
															{`#${id} ${client?.relations.clientUser.model.first_name} ${client?.relations.clientUser.model.last_name}`}
														</div>
													</div>
												);
											})}
										{step === 4 && (
											<div
												className={`${
													classes.clienteRow
												} ${
													actualClient?.id ===
													userInfo.id
														? classes.clienteRowSelected
														: ''
												} ${
													signPositions?.find(
														(sign) =>
															sign.id ==
															userInfo.id
													)
														? classes.clienteRowDone
														: ''
												} `}
												onClick={() =>
													setClientSign({
														id: userInfo.id,
														name: `${userInfo.name}`,
													})
												}
											>
												<div
													className={classes.cliente}
												>
													{`#${userInfo.id} ${
														userInfo?.name ||
														'Mi Firma'
													}`}
												</div>
											</div>
										)}
									</div>
								</Grid>
							)}
							<Grid
								item
								md={!isSignRequest ? 12 : 9}
								sm={12}
								xs={12}
								className={classes.formContainer}
								style={{ alignItems: 'center' }}
							>
								<PDFViewer
									url={file.archivo}
									file={file}
									handleSignPosition={handleSignPosition.bind(
										this
									)}
									isSelectingSignPosition={
										isSelectingSignPosition
									}
									client={actualClient}
									signPositions={signPositions}
									removeSignPosition={removeSignPosition}
								/>
								<div className={classes.buttonSpace}>
									{!dossier?.relations?.dossierMeeting?.[0]
										?.model?.join_url ? (
										<Button
											variant='contained'
											color='primary'
											onClick={generateMeeting}
											className={classes.button}
										>
											Agendar cita con Zoom
										</Button>
									) : (
										<Button
											variant='contained'
											color='primary'
											onClick={() =>
												window.open(
													dossier?.relations
														?.dossierMeeting?.[0]
														?.model?.join_url,
													'_blank'
												)
											}
											className={classes.button}
										>
											Unirse a Videollamada
										</Button>
									)}

									{!isSignRequest &&
										dossier?.model?.status == 2071 && (
											<Button
												variant='contained'
												color='primary'
												onClick={() =>
													setIsSignRequest(true)
												}
												className={classes.button}
											>
												Seleccionar Firmas
											</Button>
										)}
									{isSignRequest &&
										dossier?.model?.status == 2071 && (
											<Button
												variant='contained'
												color='primary'
												onClick={requestSign}
												className={classes.button}
											>
												Solicitar firmas
											</Button>
										)}
								</div>
								<Dialog
									open={showModalMeeting}
									onClose={() => setShowModalMeeting(false)}
									aria-labelledby='form-dialog-title'
									className={classes.modal}
								>
									<DialogTitle
										id='form-dialog-title'
										style={{ fontSize: '1.5rem' }}
										className={classes.modalTitle}
									>
										Ingresar liga de reunión
									</DialogTitle>
									<DialogContent
										className={classes.textAreaContainer}
									>
										<TextField
											id='standard-basic'
											label='Liga para reunión'
											value={meeting}
											style={{ width: '100%' }}
											onChange={(e) =>
												setMeeting(e.target.value)
											}
										/>
									</DialogContent>
									<DialogActions
										className={classes.modalActions}
									>
										<Button
											onClick={() =>
												setShowModalMeeting(false)
											}
											color='secondary'
											variant='contained'
										>
											Cancelar
										</Button>
										<Button
											onClick={requestZoomMeeting}
											color='primary'
											variant='contained'
										>
											Enviar
										</Button>
									</DialogActions>
								</Dialog>
							</Grid>
						</Grid>
					)}
					{!isDossierLoading && step >= 6 && step < 7 && (
						<Grid
							item
							xs={12}
							className={
								isSignRequest ? classes.signerContainer : ''
							}
						>
							{isSignRequest && !showSignForm && step === 6 && (
								<Grid item md={3} sm={12} xs={12}>
									<Typography
										variant={'h4'}
										className={classes.titleContainer}
									>
										Firmas disponibles
									</Typography>
									<div className={classes.versionContainer}>
										<div
											className={`${classes.clienteRow} ${
												actualClient?.id === userInfo.id
													? classes.clienteRowSelected
													: ''
											} ${
												signPositions?.find(
													(sign) =>
														sign.id == userInfo.id
												)
													? classes.clienteRowDone
													: ''
											} `}
											onClick={() =>
												setClientSign({
													id: userInfo.id,
													name: `${userInfo.name}`,
												})
											}
										>
											<div className={classes.cliente}>
												{`#${userInfo.id} ${
													userInfo?.name || 'Mi Firma'
												}`}
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								md={
									!isSignRequest ||
									showSignForm ||
									step === 6.5
										? 12
										: 9
								}
								sm={12}
								xs={12}
								className={classes.formContainer}
								style={{ alignItems: 'center' }}
							>
								{!showSignForm && (
									<PDFViewer
										url={file.archivo}
										file={file}
										handleSignPosition={firmaNotario.bind(
											this
										)}
										isSelectingSignPosition={
											isSelectingSignPosition
										}
										client={actualClient}
										signPositions={signPositions}
										removeSignPosition={() =>
											setSignPositions([])
										}
									/>
								)}
								{showSignForm && (
									<FirmaSelection
										backStep={() => setShowSignForm(false)}
										signPosition={signPositions?.[0]}
										meeting={
											dossier?.relations
												?.dossierMeeting?.[0]?.model
												?.join_url
										}
										callback={() => {
											setShowSignForm(false);
											setStep(step + 0.5);
										}}
									/>
								)}
								{!showSignForm && (
									<div className={classes.buttonSpace}>
										<Button
											variant='contained'
											color='primary'
											onClick={() =>
												window.open(
													dossier?.relations
														?.dossierMeeting?.[0]
														?.model?.join_url,
													'_blank'
												)
											}
											className={classes.button}
										>
											Unirse a Videollamada
										</Button>
										{step === 6 && (
											<Button
												variant='contained'
												color='primary'
												className={
													classes.buttonPrimary
												}
												onClick={
													prepareSignatureRequest
												}
												disabled={
													signRequest.loading ||
													signPositions.length == 0
												}
											>
												Firmar
											</Button>
										)}
										{signRequest.loading && (
											<Button
												variant='text'
												className={classes.loader}
											>
												<i className='fa fa-spinner fa-spin'></i>
												&nbsp;Cargando ...
											</Button>
										)}
									</div>
								)}
								<Dialog
									open={showModalMeeting}
									onClose={() => setShowModalMeeting(false)}
									aria-labelledby='form-dialog-title'
									className={classes.modal}
								>
									<DialogTitle
										id='form-dialog-title'
										style={{ fontSize: '1.5rem' }}
										className={classes.modalTitle}
									>
										Ingresar liga de reunión
									</DialogTitle>
									<DialogContent
										className={classes.textAreaContainer}
									>
										<TextField
											id='standard-basic'
											label='Liga para reunión'
											value={meeting}
											style={{ width: '100%' }}
											onChange={(e) =>
												setMeeting(e.target.value)
											}
										/>
									</DialogContent>
									<DialogActions
										className={classes.modalActions}
									>
										<Button
											onClick={() =>
												setShowModalMeeting(false)
											}
											color='secondary'
											variant='contained'
										>
											Cancelar
										</Button>
										<Button
											onClick={requestZoomMeeting}
											color='primary'
											variant='contained'
										>
											Enviar
										</Button>
									</DialogActions>
								</Dialog>
							</Grid>
						</Grid>
					)}
					{!isDossierLoading && step === 7 && (
						<>
							<Grid
								item
								md={12}
								sm={12}
								xs={12}
								className={classes.formContainer}
							>
								{!isLookingFile && (
									<div>
										<Typography variant='h4'>
											Adjunta los documento adicionales
											necesarios para finalizar documento
										</Typography>
										<FileUploader
											allowedFiles={'.pdf'}
											singleFile={false}
											setFileUpload={setFileUpload2.bind(
												this
											)}
										/>
									</div>
								)}
								{isLookingFile && (
									<PDFViewer
										url={file.archivo}
										file={file}
										canDownload
									/>
								)}
								<div className={classes.buttonSpace}>
									<Button
										variant='contained'
										color='primary'
										onClick={() =>
											setIsLookingFile(
												(prevState) => !prevState
											)
										}
										className={classes.button}
									>
										{isLookingFile
											? 'Agregar documentos'
											: 'Cancelar'}
									</Button>
									<Button
										variant='contained'
										color='primary'
										onClick={finalizarExpediente}
										className={classes.button}
									>
										Finalizar documento
									</Button>
								</div>
							</Grid>
						</>
					)}
					{!isDossierLoading && step === 8 && (
						<>
							<Grid
								item
								md={3}
								sm={4}
								xs={12}
								className={classes.leftColumn}
							>
								<Typography
									variant={'h3'}
									className={classes.titleContainer}
								>
									Documentos
								</Typography>
								<div className={classes.versionContainer}>
									{dossier?.relations?.sections
										?.filter(
											(section) =>
												+section?.model?.type === 2
										)
										?.map((version, i) => (
											<div
												className={classes.versionRow}
												key={version.model.id}
											>
												<div
													className={classes.version}
													onClick={() =>
														handleVersion(
															version?.relations
																?.documents?.[0]
																?.relations
																?._File0?.[0]
																?.model.id,
															version?.relations
																?.documents?.[0]
																?.relations
																?._File0?.[0]
																?.model
														)
													}
												>
													Documento Final
												</div>
												{/* <div className={classes.fecha}>
													{new Date(
														version.relations.documents[0]?.model.created_on
													).toLocaleDateString()}
												</div> */}
											</div>
										))}
									{dossier?.relations?.sections
										?.filter(
											(section) =>
												+section?.model?.type === 7
										)?.[0]
										?.relations?.documents?.map(
											(doc, i) => (
												<div
													className={
														classes.versionRow
													}
													key={doc.model.id}
												>
													<div
														className={
															classes.version
														}
														onClick={() =>
															handleVersion(
																doc?.model
																	?.file,
																doc?.relations
																	?._File0?.[0]
																	?.model
															)
														}
													>
														{
															doc?.relations
																?._File0?.[0]
																?.model?.name
														}
													</div>
													{/* <div className={classes.fecha}>
													{new Date(
														version?.relations?.document0?.[0]?.model?.created_on
													).toLocaleDateString()}
												</div> */}
												</div>
											)
										)}
								</div>
							</Grid>
							<Grid
								item
								md={9}
								sm={12}
								xs={12}
								className={classes.formContainer}
							>
								<PDFViewer
									url={file.archivo}
									file={file}
									canDownload
								/>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Dialog
				open={showModalVerifications}
				onClose={() => setShowModalMeeting(false)}
				aria-labelledby='form-dialog-title'
				className={classes.modal}
			>
				<DialogTitle
					id='form-dialog-title'
					style={{ fontSize: '1.5rem' }}
					className={classes.modalTitle}
				>
					Hay verificaciones pendientes
				</DialogTitle>
				<DialogContent className={classes.textAreaContainer}>
					<ul>
						{dossier?.[0]?.relations?.sections
							?.find((section) => +section?.model?.type === 6)
							?.relations?.subSections?.filter(
								(subsection) =>
									dossier?.model?.status < 2071 ||
									+subsection.model.weight >= 10
							)
							?.filter((signer) => signer?.model?.status < 100)
							.map((signer) => {
								const client = signer?.relations?.dossier0[0];
								const status = signer?.model?.status;
								return (
									<li key={signer.model.code1}>
										<div
											className={classes.clientRow}
											style={{ gap: '5px' }}
										>
											<div className={classes.clientID}>
												#{client.model.code1}
											</div>
											<div className={classes.clientName}>
												{`${client?.model?.name}`}
											</div>
											<Chip
												label={
													USER_STATES_VALUES[status]
												}
												color='secondary'
												size='small'
												className={
													+status === -50
														? classes.chipDanger
														: [0, 52].includes(
																signer.model
																	.status
														  )
														? classes.chipWarning
														: status >= 100
														? classes.chipSuccess
														: classes.chipInfo
												}
											/>
											{status === -50 && (
												<Button
													style={{
														marginLeft: 'auto',
													}}
													variant='contained'
													color='primary'
													onClick={() =>
														sendNewToken(
															client.model.code1
														)
													}
												>
													Solicitar Nueva Verificación
												</Button>
											)}
											{(status === 0 ||
												status === 52) && (
												<Button
													style={{
														marginLeft: 'auto',
													}}
													variant='contained'
													color='primary'
													onClick={() =>
														sendReminder(
															client.model.code1
														)
													}
												>
													Enviar Recordatorio
												</Button>
											)}
										</div>
									</li>
								);
							})}
					</ul>
				</DialogContent>
				<DialogActions className={classes.modalActions}>
					<Button
						onClick={() => setShowModalVerifications(false)}
						color='primary'
						variant='contained'
					>
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}

export default withRouter(RevisionDocumento);
