import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ApiURLs from '../../config/ApiURLs';
/*REDUX*/
import { useDispatch } from 'react-redux';
import { deleteFile } from '../../actions/documentActions';
import { Typography } from '@material-ui/core';
/*END OF REDUX RELATED*/
const useStyles = makeStyles((theme) => ({
	descriptorContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		position: 'relative',
		justifyContent: 'flex-start',
	},
	deleteButton: {
		position: 'absolute',
		top: '-20px',
		right: '-15px',
		cursor: 'pointer',
	},
	customP: {
		margin: '0',
		paddingLeft: '25px',
		cursor: 'pointer',
	},
}));
export default function SingleFileDescriptor(props) {
	const { fallbackText } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const files = useSelector((state) => state.document.archivos);
	const focusedFile = files[0];

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	console.log('files', files);
	console.log('focusedFile', focusedFile);
	if (focusedFile != undefined) {
		props.setFileUpload(true);
		return (
			<div className={classes.descriptorContainer}>
				<div
					className={classes.descriptorContainer}
					style={{ cursor: 'pointer' }}
					onClick={(event) => {
						openInNewTab(
							ApiURLs.baseURL + 'file/get?id=' + focusedFile.id
						);
						event.stopPropagation();
					}}>
					<i className='fas fa-file fa-3x'></i>
					<Typography variant='body1' className={classes.customP}>
						{focusedFile.name}
					</Typography>
				</div>
				<div
					className={classes.deleteButton}
					onClick={(event) => {
						props.setFileUpload(false);
						dispatch(deleteFile(focusedFile.id));
						event.stopPropagation();
					}}>
					<i className='fas fa-trash-alt'></i>
				</div>
			</div>
		);
	}
	//the print fallback text
	return <p>{fallbackText}</p>;
}
