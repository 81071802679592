import React, { useState } from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-dashboard-react/views/firmaClienteStyle';
// import logo from '../../assets/img/logo.svg';

import Firma from 'components/Firma/Firma';

import { finishUpload } from '../../actions/signActions';

const useStyles = makeStyles(styles);

export default function SignUpload(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const signRequest = useSelector((state) => state.signRequest);

	const [name, setName] = useState('');

	const handleSave = () => {
		if (name === '' || name === undefined) {
			Swal.fire({
				icon: 'error',
				title: 'Campos requeridos',
				text: 'Por favor ingrese todos los campos',
			});
			return;
		}

		dispatch(finishUpload(signRequest.signInfo.uuid, name));
	};

	return (
		<Grid container className={classes.container}>
			<Grid
				item
				xs={12}
				md={3}
				className={classNames(classes.column, classes.borderRight)}
			>
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.column}>
						<div className={classes.note}>
							<b>¿Tienes la firma correcta?</b> Asegurate de
							utilizar los archivos correspondientes a tu e.firma.
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} md={9} className={classes.column}>
				<div className={classes.uploadSection}>
					<Firma name={name} setName={setName} />
				</div>
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.rightRow}>
						<Button
							variant='contained'
							color='success'
							className={classes.buttonSuccess}
							onClick={handleSave}
							disabled={signRequest.loading}
						>
							Guardar Firma
						</Button>
						{signRequest.loading && (
							<Button variant='text' className={classes.loader}>
								<i className='fa fa-spinner fa-spin'></i>
								&nbsp;Cargando ...
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
