import {
	USER_SIGNIN_FAIL,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS,
	USER_SIGNOUT,
	USER_CLIENTS_REQUEST,
	USER_CLIENTS_SUCCESS,
	USER_CLIENTS_FAIL,
} from '../constants/userConstants.js';
import Axios from '../services/Api.js';

export const signin = (username, password, remember) => async (dispatch) => {
	//console.log(Axios);
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const returnTo = params.get('returnTo');

	dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } });
	try {
		const { data } = await Axios.UserLogin({
			username,
			password,
			remember,
		});
		if (data.success) {
			dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
			localStorage.setItem('userInfo', JSON.stringify(data));
			Axios.resetService();
			if (returnTo) {
				window.location.href = returnTo;
			} else {
				window.location.href = '/'; // The router should handle this default route
			}
		} else {
			dispatch({
				type: USER_SIGNIN_FAIL,
				payload: data.message,
			});
			return data;
		}
	} catch (error) {
		dispatch({
			type: USER_SIGNIN_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
export const signout = () => async (dispatch) => {
	console.log('signing out...');
	localStorage.removeItem('userInfo');
	dispatch({ type: USER_SIGNOUT });
};

export const getUserClients = () => async (dispatch) => {
	dispatch({ type: USER_CLIENTS_REQUEST });

	try {
		const { data } = await Axios.userClients();
		//console.log(data);
		//const data = resultDocType;
		if (data.result) {
			dispatch({
				type: USER_CLIENTS_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result,
			});
		} else {
			dispatch({
				type: USER_CLIENTS_FAIL,
				payload: [],
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: USER_CLIENTS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
