import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Step from './Step';

import styles from '../../assets/jss/material-dashboard-react/components/stepsStyle';

const useStyles = makeStyles(styles);

export default function StepsDocumento(props) {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Step
				step={1}
				currentStep={props.step}
				title='Detalle del documento'
			/>
			<Step step={2} currentStep={props.step} title='Carga de archivo' />
			<Step
				step={3}
				currentStep={props.step}
				title='Especificar clientes'
			/>
		</div>
	);
}
