import { WORD_MIME_TYPES } from 'utils/constants';
import { GLIFOSPDFViewer } from '../GLIFOSPDFViewer/GLIFOSPdfViewer';
import LoadingViewer from '../LoadingViewer/LoadingViewer';
import { Button, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import Axios from 'services/Api';
import LoadingBox from 'components/LoadingBox/LoadingBox';
import MessageBox from 'components/MessageBox/MessageBox';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		padding: 0,
		//maxHeight: '50vh',
	},
}));

export default function GlifosDigitalArchiveViewer({
	src,
	handleSignPosition,
	client,
	isSelectingSignPosition,
	signPositions,
	canDownload,
	removeSignPosition,
	file,
}) {
	const classes = useStyles();
	const [showIframe, setShowIframe] = useState({
		show: false,
		payload: null,
		isLoading: false,
		isError: false,
	});

	const handleOpenInWord = () => {
		setShowIframe({
			show: true,
			payload: null,
			isLoading: true,
			isError: false,
		});
		Axios.GetEditable({ id: file.id })
			.then((res) => {
				if (res.statusText !== 'OK')
					throw new Error('Error al obtener el archivo');
				return res.data;
			})
			.then((res) => {
				console.log(res);
				if (res.success) {
					setShowIframe({
						show: true,
						payload: res,
						isLoading: false,
						isError: false,
					});
				} else {
					setShowIframe({
						show: false,
						payload: 'Hubo un error en la petición',
						isLoading: false,
						isError: true,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className={classes.container}>
			{!src && <LoadingViewer />}
			{src && !file.isWordFile && (
				<GLIFOSPDFViewer
					fileUrl={src}
					handleSignPosition={handleSignPosition}
					client={client}
					isSelectingSignPosition={isSelectingSignPosition}
					signPositions={signPositions}
					canDownload={canDownload}
					removeSignPosition={removeSignPosition}
				/>
			)}
			{src && file.isWordFile && (
				<>
					<Button
						variant='contained'
						color='primary'
						onClick={handleOpenInWord}
					>
						Abrir en Word
					</Button>
					{showIframe.isLoading && <LoadingBox />}
					{!showIframe.isLoading && showIframe.show && (
						<iframe
							style={{ display: 'none' }}
							src={showIframe.payload.url}
							height={0}
							width={0}
						/>
					)}
					{!showIframe.isLoading && showIframe.isError && (
						<MessageBox variant='danger'>
							{showIframe.payload}
						</MessageBox>
					)}
				</>
			)}
		</div>
	);
}
