//import commonStyles from '../components/formsRelated'; didnt work as expected

const style = (theme) => ({
    
    row:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        marginBottom:'20px'
    },
    labelWithText:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        alignItems:'center',
        [theme.breakpoints.up('sm')]: {
			marginLeft:'10px'
		},
    },
    dateInput:{
        marginLeft:'5px'
    },
    accordionStyle:{
        width:'100%',
    },
    floatingButtonsContainer:{
        display:'none',
        [theme.breakpoints.up('md')]: {
            position:'absolute',
            top:'0',
            right:'0',
            display:'flex !important',
            flexDirection:'column'
        }
    },
    floatingButton:{
        border:theme.customConstants.defaultBorder,
        boxShadow:theme.customConstants.defaultBoxShadow,
        padding:'8px',
        color:'black',
        cursor:'pointer'
    },
    mobileFloatingButtons:{
        [theme.breakpoints.up('md')]: {
            display:'none'
        },
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly'
    },
    modalRow:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
    },
    modalRowEvenlyDistributed:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-evenly',
        flexWrap: 'wrap'
    },
    column:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    columnTitle:{
        marginBottom:'50px',
    },
    rowRightAlign:{
        marginLeft:'auto'
    },
    rowLeftAlign:{
        marginRight:'auto'
    },
    alignedText:{
        textAlign:'center'
    }
    

});

export default style;