export const DOSSIER_STATES = [
	{
		id: 1,
		name: 'Creación de Expediente',
		minRange: 1000,
		maxRange: 1000,
		step: 1,
	},
	{
		id: 2,
		name: 'Revisión de Generales',
		minRange: 1001,
		maxRange: 2000,
		step: 2,
	},
	{
		id: 3,
		name: 'Carga de Borrador',
		minRange: 2001,
		maxRange: 2001,
		step: 3,
	},
	{
		id: 4,
		name: 'Revisión de Borrador',
		minRange: 2002,
		maxRange: 2069,
		step: 4,
	},
	{
		id: 5,
		name: 'Firma de Documento',
		minRange: 2070,
		maxRange: 2130,
		step: 5,
	},
	{
		id: 6,
		name: 'Autorización Preventiva',
		minRange: 2131,
		maxRange: 2200,
		step: 6,
	},
	{
		id: 7,
		name: 'Autorización Definitiva',
		minRange: 2201,
		maxRange: 2299,
		step: 7,
	},
	{
		id: 8,
		name: 'Finalizado',
		minRange: 2300,
		maxRange: 2399,
		step: 8,
	},
];

export const USER_STATES_VALUES = {
	'-50': 'Verificación Fallida',
	0: 'Verificación pendiente',
	50: 'Verificación en proceso',
	51: 'Verificación en proceso',
	52: 'Verificación pendiente',
	100: 'Verificación Completada',
	101: 'Verificación Completada',
	102: 'Verificación Completada',
	103: 'Verificación Confirmada',
};

export const WORD_MIME_TYPES = [
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
