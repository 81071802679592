import React, { useState } from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-react/views/firmaClienteStyle";
// import logo from '../../assets/img/logo.svg';

import PDFViewer from "components/PDFViewer/PDFViewer";

const useStyles = makeStyles(styles);

const fakeVersions = [
  {
    id: 123,
    version: 2,
    fecha: "09/06/2021",
    archivo:
      "http://flujograma.local/v1/file/get?id=01b62166fc8a24d4560aa67cbe5d4300",
  },
  {
    id: 124,
    version: 1,
    fecha: "13/05/2021",
    archivo:
      "http://flujograma.local/v1/file/get?id=f8fedeb55aa3a02742be4608363d2c5f",
  },
];

export default function FirmaRevision(props) {
  const classes = useStyles();

  const signRequest = useSelector((state) => state.signRequest);
  const [file, setFile] = useState(fakeVersions[0]);

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        md={2}
        className={classNames(classes.column, classes.borderRight)}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.column}>
            <div className={classes.note}>
              Revisa el documento y cuando estés listo, procede al siguiente
              paso para firmar
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} className={classes.column}>
        <PDFViewer url={file.archivo} />
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.rightRow}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonDanger}
            >
              Rechazar documento
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonPrimary}
              onClick={props.nextStep}
              disabled={signRequest.loading}
            >
              Firmar documento
            </Button>
            {signRequest.loading && (
              <Button variant="text" className={classes.loader}>
                <i className="fa fa-spinner fa-spin"></i>&nbsp;Cargando ...
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
