import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/glifos/mainLayoutStyle';
import logo from '../../assets/img/logo.svg';

import MessageBox from '../../components/MessageBox/MessageBox';
import LoadingBox from '../../components/LoadingBox/LoadingBox';

import Axios from 'services/Api';

const useStyles = makeStyles(styles);

function ForgotPassword(props) {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(false);
	const isValidData = () => {
		if (email === '') {
			setEmail(true);
			setError('Por favor ingrese su correo electrónico');
			return false;
		}
		return true;
	};

	const sendData = async () => {
		setIsLoading(true);
		try {
			const { data } = await Axios.ForgotPassword({
				email: email,
				user_type: 'notario',
			});
			if (Boolean(data?.success)) {
				props.history.push('/login');
			} else {
				setError(data?.error_log);
			}
		} catch (error) {
			setError(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (isValidData()) {
			sendData();
		}
	};

	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div className={classes.paper}>
				{
					<div className={classes.mainLogo}>
						<img
							src={logo}
							alt='Logo'
							className={classes.fitImage}
						/>
					</div>
				}
				<Typography component='h1' variant='h2'>
					Reestablecer Contraseña
				</Typography>
				{isLoading && <LoadingBox></LoadingBox>}

				<form
					className={classes.form}
					noValidate
					onSubmit={submitHandler}
				>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email'
						name='email'
						autoComplete='email'
						error={emailError}
						autoFocus
						type='email'
						className={classes.input}
						onChange={(e) => {
							setEmail(e.target.value);
							setEmailError(false);
							setError('');
						}}
					/>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						className={classes.submit}
					>
						Solicitar cambio de contraseña
					</Button>

					{(emailError || error) && (
						<MessageBox variant='danger' severity='error'>
							{error}
						</MessageBox>
					)}
				</form>
			</div>
		</Container>
	);
}

export default ForgotPassword;
