import { Button, Chip, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles, Snackbar } from '@material-ui/core';
import { USER_STATES_VALUES } from 'utils/constants';
import { AddAlert } from '@material-ui/icons';
import { Visibility } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Axios from 'services/Api';
import { Autorenew } from '@material-ui/icons';
import LoadingBox from 'components/LoadingBox/LoadingBox';
import MessageBox from 'components/MessageBox/MessageBox';
import { FileCopyOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',
	},
	clientRow: {
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
		alignItems: 'center',
	},
	chipDanger: {
		backgroundColor: '#f44336',
		color: 'white',
	},
	chipWarning: {
		backgroundColor: '#ff9800',
		color: 'white',
	},
	chipSuccess: {
		backgroundColor: '#4caf50',
		color: 'white',
	},
	chipInfo: {
		backgroundColor: '#2196f3',
		color: 'white',
	},
	actions: {
		marginLeft: 'auto',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		gap: '5px',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	userDataContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
	},
	buttonSpace: {
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
		justifyContent: 'flex-end',
		marginTop: 'auto',
	},
	copyButton: {
		marginLeft: 'auto',
	},
}));

export default function RevisionGenerales({ clients, actions = false }) {
	const classes = useStyles();
	const { id: dossier_id } = useParams();
	const [showGenerales, setShowGenerales] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [userData, setUserData] = useState({
		isLoading: false,
		isError: false,
		errorMessage: '',
		payload: null,
	});

	const getData = async (user_id) => {
		try {
			setUserData({
				isLoading: true,
				isError: false,
				errorMessage: '',
				payload: null,
			});
			const { data } = await Axios.GetGenerales({
				dossier: dossier_id,
				user_id,
			});
			if (Boolean(data?.error)) {
				throw new Error(data?.message);
			}
			setUserData({
				isLoading: false,
				isError: false,
				errorMessage: '',
				payload: data?.result,
			});
			console.log(data);
		} catch (e) {
			console.log(e);
			setUserData({
				isLoading: false,
				isError: true,
				errorMessage: e.message,
				payload: null,
			});
		}
	};
	const showReport = (id) => {
		setShowGenerales(true);
		getData(id);
	};

	const sendReminder = async (client_id) => {
		try {
			const { data } = await Axios.SendReminder({
				client_id,
				dossier_id,
			});
			if (data?.success) {
				Swal.fire('Recordatorio enviado!', '', 'success');
			} else {
				Swal.fire('Error al enviar el recordatorio!', '', 'error');
			}
		} catch (error) {
			console.log(error);
			Swal.fire(
				'Error al enviar el recordatorio!',
				error.message,
				'error'
			);
		}
	};

	const generateNewToken = async (client_id) => {
		try {
			const { data } = await Axios.SendNewToken({
				client_id,
				dossier_id,
			});
			if (data?.success) {
				Swal.fire('Token enviado!', '', 'success');
			} else {
				Swal.fire('Error al enviar el token!', '', 'error');
			}
		} catch (error) {
			console.log(error);
			Swal.fire('Error al enviar el token!', error.message, 'error');
		}
	};

	const handleActionButton = (status, id) => {
		if (status >= 100) {
			showReport(id);
		}
		if ([-50, 0, 52].includes(status)) {
			sendReminder(id);
		}
	};

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		setShowSnackbar(true);
	};

	return (
		<div className={classes.container}>
			{!showGenerales && (
				<>
					<Typography variant='h3'>Clientes Asociados</Typography>
					{clients.map((client_section) => {
						const client = client_section?.relations?.dossier0?.[0];
						const status = client_section?.model?.status;
						const inRevision =
							![-50, 0, 52].includes(+status) && +status < 100;
						const className =
							+status === -50
								? classes.chipDanger
								: [0, 52].includes(status)
								? classes.chipWarning
								: status >= 100
								? classes.chipSuccess
								: classes.chipInfo;
						return (
							<div
								key={client?.model?.id}
								className={classes.clientRow}
							>
								<b>{`#${client?.model?.code1}`}</b>
								<span>{client?.model?.name}</span>
								<Chip
									label={USER_STATES_VALUES[status]}
									color='secondary'
									size='small'
									className={className}
								/>
								{actions && !inRevision && (
									<span className={classes.actions}>
										<Button
											className={classes.copyButton}
											onClick={() =>
												handleActionButton(
													status,
													client?.model?.code1
												)
											}
										>
											{status >= 100 && <Visibility />}
											{[0, 52].includes(status) && (
												<AddAlert />
											)}
										</Button>
										{[-50, 0, 52].includes(status) && (
											<Button
												className={classes.copyButton}
												onClick={() =>
													generateNewToken(
														client?.model?.code1
													)
												}
											>
												<Autorenew />
											</Button>
										)}
									</span>
								)}
							</div>
						);
					})}
				</>
			)}
			{showGenerales && userData.isLoading && <LoadingBox />}
			{showGenerales && !userData.isLoading && userData.isError && (
				<MessageBox severity='error'>
					{userData.errorMessage}
				</MessageBox>
			)}
			{showGenerales && !userData.isLoading && !userData.isError && (
				<div className={classes.userDataContainer}>
					<Typography variant='h3'>Generales</Typography>
					{userData?.payload?.nombres && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Nombres:</b>
								</Typography>
							</div>
							<div>{userData.payload?.nombres}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(userData.payload?.nombres)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.apellidoPaterno && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Apellido Paterno:</b>
								</Typography>
							</div>
							<div>{userData.payload?.apellidoPaterno}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.apellidoPaterno
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.apellidoMaterno && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Apellido Materno:</b>
								</Typography>
							</div>
							<div>{userData.payload?.apellidoMaterno}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.apellidoMaterno
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.sexo && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Sexo:</b>
								</Typography>
							</div>
							<div>{userData.payload?.sexo}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(userData.payload?.sexo)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.nacionalidad && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Nacionalidad:</b>
								</Typography>
							</div>
							<div>{userData.payload?.nacionalidad}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.nacionalidad
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.edad && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Edad:</b>
								</Typography>
							</div>
							<div>{userData.payload?.edad}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(userData.payload?.edad)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.fechaNacimiento && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Fecha de Nacimiento:</b>
								</Typography>
							</div>
							<div>{userData.payload?.fechaNacimiento}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.fechaNacimiento
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.lugarNacimiento && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Lugar de Nacimiento:</b>
								</Typography>
							</div>
							<div>{userData.payload?.lugarNacimiento}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.lugarNacimiento
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.estado && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Estado:</b>
								</Typography>
							</div>
							<div>{userData.payload?.estado}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(userData.payload?.estado)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.claveElector && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Clave de Elector:</b>
								</Typography>
							</div>
							<div>{userData.payload?.claveElector}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.claveElector
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.numeroPersonal && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Número Personal:</b>
								</Typography>
							</div>
							<div>{userData.payload?.numeroPersonal}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.numeroPersonal
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.tipoDocumento && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Tipo de Documento:</b>
								</Typography>
							</div>
							<div>{userData.payload?.tipoDocumento}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.tipoDocumento
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.fechaEmision && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Fecha de Emisión:</b>
								</Typography>
							</div>
							<div>{userData.payload?.fechaEmision}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.fechaEmision
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.numeroDocumento && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Número de Documento:</b>
								</Typography>
							</div>
							<div>{userData.payload?.numeroDocumento}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.numeroDocumento
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.direccion && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Dirección:</b>
								</Typography>
							</div>
							<div>{userData.payload?.direccion}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(userData.payload?.direccion)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.calle && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Calle:</b>
								</Typography>
							</div>
							<div>{userData.payload?.calle}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(userData.payload?.calle)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.colonia && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Colonia:</b>
								</Typography>
							</div>
							<div>{userData.payload?.colonia}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(userData.payload?.colonia)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					{userData?.payload?.ciudadEstado && (
						<div className={classes.row}>
							<div>
								<Typography variant='body1'>
									<b>Ciudad/Estado:</b>
								</Typography>
							</div>
							<div>{userData.payload?.ciudadEstado}</div>
							<Button
								className={classes.copyButton}
								onClick={() =>
									copyToClipboard(
										userData.payload?.ciudadEstado
									)
								}
							>
								<FileCopyOutlined />
							</Button>
						</div>
					)}
					<div className={classes.buttonSpace}>
						<Button
							variant='contained'
							color='primary'
							className={classes.buttonPrimary}
							onClick={() => setShowGenerales(false)}
							disabled={userData.isLoading}
						>
							Regregar
						</Button>
					</div>
					<Snackbar
						open={showSnackbar}
						autoHideDuration={6000}
						onClose={() => setShowSnackbar(false)}
					>
						<MessageBox
							onClose={() => setShowSnackbar(false)}
							severity='info'
						>
							Copiado en el portapapeles!
						</MessageBox>
					</Snackbar>
				</div>
			)}
		</div>
	);
}
