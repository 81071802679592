import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FileUploader from '../FileUploader/FileUploader.js';
import JustAddedFile from './JustAddedFile.js';
import {Typography} from '@material-ui/core';
import Swal from 'sweetalert2';
import styles from 'assets/jss/material-dashboard-react/views/expedienteDetaillStyle';
import clsx from 'clsx';

const useStyles = makeStyles(styles);

const testTemplate = [
    { label: 'Age', inputType: 'text', variant: 'outlined' },
    { label: 'Password', inputType: 'password', variant: 'outlined' },
    
    // {label:'Age', inputType:'text', variant:'outlined'},
    // {label:'Password', inputType:'password', variant:'outlined'},
    // {label:'Age', inputType:'text', variant:'outlined'},
    // {label:'Password', inputType:'password', variant:'outlined'},
    // {label:'Age', inputType:'text', variant:'outlined'},
    // {label:'Password', inputType:'password', variant:'outlined'},
    // {label:'Age', inputType:'text', variant:'outlined'},
    // {label:'Password', inputType:'password', variant:'outlined'},
    // {label:'Age', inputType:'text', variant:'outlined'},
    // {label:'Password', inputType:'password', variant:'outlined'},

];


export default function DynamicFilesList(props) {
    const classes = useStyles();
    const { requiredFields } = props;
    const documentsState = useSelector(state => state.document);
    const [wantsToAddNewDocument, setWantsToAddNewDocument] = React.useState(true);

    React.useEffect(() => {
        if (!documentsState.isEdittingNewFile)
            setWantsToAddNewDocument(false);
    }, [documentsState.isEdittingNewFile])

    const renderCurrentFiles = () => {
        return documentsState.archivos.map((element, index) => {
            return (
                <div style={{marginBottom:'10px'}}>
                    <JustAddedFile template={testTemplate} />
                </div>
            );
        });
    }

    const addMoreHandler = () => {
        if (documentsState.isEdittingNewFile) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo',
                text: 'Hay informacion sin guardar'
            });
        }
        else
            setWantsToAddNewDocument(true)
    }

    return (
        <div>
            {/* <JustAddedFile template={testTemplate}/> */}
            {renderCurrentFiles()}
            {(documentsState.archivos == 0 || (!documentsState.isEdittingNewFile && wantsToAddNewDocument)) ? <div style={{marginBottom:'10px'}}> <FileUploader allowedFiles=".txt" /> </div> : null}
            {documentsState.filesData.length >= 1 ? <div className={classes.modalRow} style={{marginBottom:'10px'}}> <div className={clsx(classes.rowLeftAlign,classes.floatingButton)} onClick={addMoreHandler}> <Typography variant="caption">&#43; Añadir otro documento</Typography></div></div> : null}
        </div>
    );
}