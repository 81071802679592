export const PREPAPRE_REQUEST = 'PREPARE_REQUEST';
export const PREPARE_REQUEST_SUCCESS = 'PREPARE_REQUEST_SUCCESS';
export const PREPARE_REQUEST_ERROR = 'PREPARE_REQUEST_ERROR';

export const CERTIFICATE_UPLOADED = 'CERTIFICATE_UPLOADED';
export const CERTIFICATE_DELETED = 'CERTIFICATE_DELETED';
export const KEY_UPLOADED = 'KEY_UPLOADED';
export const KEY_DELETED = 'KEY_DELETED';

export const FINISH_REQUEST = 'FINISH_REQUEST';
export const FINISH_REQUEST_SUCCESS = 'FINISH_REQUEST_SUCCESS';
export const FINISH_REQUEST_ERROR = 'FINISH_REQUEST_ERROR';