import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemCustom from './ListItem';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		maxWidth: 752,
		display: 'flex',
		flexDirection: 'row',
	},
	demo: {
		backgroundColor: theme.palette.background.paper,
	},
	title: {
		margin: theme.spacing(4, 0, 2),
	},
}));

export default function InteractiveList() {
	const classes = useStyles();
	const [dense, setDense] = React.useState(false);
	const [secondary, setSecondary] = React.useState(false);
	const flexContainer = {
		display: 'flex',
		flexDirection: 'row',
		padding: 0,
	};
	const files = useSelector((state) => state.document.archivos);
	console.log(files);

	return (
		<>
			<List style={flexContainer} dense={dense}>
				{files
					? files.map((file) => {
							return (
								<ListItemCustom
									name={file.name.split('.')[0]}
									size={file.size}
									key={file.id}
									id={file.id}
								/>
							);
					  })
					: null}
			</List>
		</>
	);
}
