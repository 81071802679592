import React, { useEffect, useState } from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ContentTable from 'components/ContentTable/ContentTable';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { getMyDocuments } from 'actions/dossierActions';
import { useDispatch, useSelector } from "react-redux";



const useStyles = makeStyles(styles);

var fakeColumns = [
	{ name: 'Correlativo', width: 160, align: 'center' },
	{ name: 'Documento', width: 630, align: 'left' },
	{ name: 'Estado', align: 'center' },
];

function DashboardCliente(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [rows, setRows] = useState([]);

	const documents = useSelector((state) => state.myDocuments.documents);

	const visibilityActionClick = (id) => {
		props.history.push('/documento/' + id);
	};

	useEffect(() => {
		//dispatch(getPendingDossier());
        dispatch(getMyDocuments());
	}, []);

	useEffect(() => {
		if (documents) {
            // const dossiers = documents.map((item) => {
            //     return {
            //         dossiers: item.relations.relatedDossier[0].model.id
            //     }
            // });
			setRows(
				documents.map((item) => {
					return {
						id: item.relations.relatedDossier[0].model.id,
						documento: item.relations.relatedDossier[0].model.name,
						estado: getStatus(item.relations.relatedDossier[0].model.status),
						actions: ['edit'],
					};
				})
			);
		}
	}, [documents]);

	const getStatus = (status) => {
		if (status >= 2001 && status <= 2010) return 'Carga del Borrador';
		if (status >= 2011 && status <= 2070) return 'Revision del Borrador';
		if (status >= 2071 && status <= 2130) return 'Firma del documento';
		if (status >= 2131 && status <= 2200) return 'Autorización preventiva';
		if (status >= 2201 && status <= 2299) return 'Autorización Definitiva';
		if (status >= 2300 && status <= 2399) return 'Finalizado Archivado';
	};

	return (
		<div style={{ height: '100%' }}>
			<ContentTable
				title='Documentos'
				pseudoRows={rows}
				columnDefinitions={fakeColumns}
				renderActionsHeader
				visibilityHandler={visibilityActionClick}
			/>
		</div>
	);
}

export default withRouter(DashboardCliente);
