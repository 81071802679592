import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/components/pdfViewerStyle';
import LoadingBox from 'components/LoadingBox/LoadingBox';
import GlifosDigitalArchiveViewer from 'components/GlifosDigitalArchiveViewer/GlifosDigitalArchiveViewer';

const useStyles = makeStyles(styles);

export default function PDFViewer({
	url,
	file,
	handleSignPosition = () => {},
	client = null,
	isSelectingSignPosition = false,
	signPositions = [],
	canDownload = false,
	removeSignPosition = () => {},
}) {
	const classes = useStyles();
	return (
		<div
			className={classes.container}
			style={{ height: file?.isWordFile ? '100%' : 'auto' }}
		>
			{!url && (
				<div className={classes.noPDF}>
					<LoadingBox />
				</div>
			)}
			{url && (
				<GlifosDigitalArchiveViewer
					src={url}
					file={file}
					handleSignPosition={handleSignPosition}
					client={client}
					isSelectingSignPosition={isSelectingSignPosition}
					signPositions={signPositions}
					canDownload={canDownload}
					removeSignPosition={removeSignPosition}
				/>
			)}
		</div>
	);
}
