import React from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Store from '@material-ui/icons/Store';
import Warning from '@material-ui/icons/Warning';
import DateRange from '@material-ui/icons/DateRange';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Update from '@material-ui/icons/Update';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AccessTime from '@material-ui/icons/AccessTime';
import Accessibility from '@material-ui/icons/Accessibility';
import BugReport from '@material-ui/icons/BugReport';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/Cloud';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Tasks from 'components/Tasks/Tasks.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { bugs, website, server } from 'variables/general.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ContentTable from 'components/ContentTable/ContentTable';
import { Grid, Icon, Typography } from '@material-ui/core';
import { Redirect, withRouter } from 'react-router';
import EntitledContainer from 'components/EntitledContainer/EntitledContainer';
import RichTextBox from 'components/RichTextBox/RichTextBox';
import Filter from 'components/Filter/Filter';
const useStyles = makeStyles(styles);

const fakeRows = [
	{
		id: 2348,
		documento: 'Contrato de compra venta',
	},
	{
		id: 1289,
		documento: 'Acta constitucional',
	},
	{
		id: 9825,
		documento: 'Acta de donacion',
	},
];

var fakeColumns = [
	{ name: 'Correlativo', width: 160, align: 'center' },
	{ name: 'Documento', width: 600, align: 'left' },
];

function Protocolo(props) {
	const classes = useStyles();

	const visibilityActionClick = (id) => {
		props.history.push('/cliente/' + id);
	};

	const nuevoCliente = () => {
		props.history.push('/registro_cliente');
	};

	return (
		<div style={{ height: '100%' }}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={4}>
					<Filter />
				</Grid>
				<Grid item xs={12} sm={8}>
					{/* <div style={{ marginBottom: '40px' }}>
						Barra de busqueda
					</div> */}
					<ContentTable
						title='Protocolo electrónico'
						pseudoRows={fakeRows}
						columnDefinitions={fakeColumns}
						visibilityHandler={visibilityActionClick}
					/>
				</Grid>
			</Grid>
		</div>
	);
}

export default withRouter(Protocolo);
