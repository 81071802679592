import { useState, useRef, useEffect, createElement } from 'react';

import {
	Worker,
	Viewer,
	SpecialZoomLevel,
	LayerRenderStatus,
} from '@react-pdf-viewer/core';
import { SearchIcon } from '@react-pdf-viewer/search';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import CustomToolbar from './CustomToolbar/CustomToolbar';

import styles from './GLIFOSPdfViewer.module.css';
import Loader from './Loader/Loader';
import SearchPlugin from './SearchPlugin/SearchPlugin';
import { useSelector } from 'react-redux';
import PDF from 'assets/img/Documento Prueba.pdf';

const WORKER_URL =
	'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';

export function GLIFOSPDFViewer({
	fileUrl,
	handleSignPosition,
	client,
	isSelectingSignPosition,
	signPositions,
	canDownload = false,
	removeSignPosition,
}) {
	const [theme, setTheme] = useState('dark');
	const [numPages, setNumPages] = useState(0);
	const { clients } = useSelector((state) => state.user_clients);

	const viewerRef = useRef(null);
	/* const [startX, setStartX] = useState(null);
	const [startY, setStartY] = useState(null);
	const [endX, setEndX] = useState(null);
	const [endY, setEndY] = useState(null); */
	const [pdfWidth, setPdfWidth] = useState(0);
	const [pdfHeight, setPdfHeight] = useState(0);
	const drawerRef = useRef(null);

	const handleMouseDown = (event) => {
		//console.log('handleMouseDown');
		if (!isSelectingSignPosition) return;
		console.log(event);

		const { layerX, layerY } = event;
		const scale = getComputedStyle(event.target).getPropertyValue(
			'--scale-factor'
		);

		const pdfContainer = event.target;
		console.log(event.target);
		event.target.dataset.selectionX = layerX;
		event.target.dataset.selectionY = layerY;

		pdfContainer.addEventListener('mousemove', handleMouseMove);
		const newChild = document.createElement('div');
		newChild.id = `sign-helper`;
		pdfContainer.appendChild(newChild);

		//const { left, top } = pdfContainer.getBoundingClientRect();
		//console.log({ layerX, layerY });
	};

	const handleMouseUp = (event) => {
		//console.log('handleMouseUp');
		if (!isSelectingSignPosition) return;
		let pdfPage = event.target;
		if (event.target.id == 'sign-helper') {
			pdfPage = event.target.parentElement;
		}
		const { layerX, layerY } = event;
		console.log(event);
		const page = pdfPage.dataset.testid.replace('core__text-layer-', '');

		const scale = getComputedStyle(event.target).getPropertyValue(
			'--scale-factor'
		);
		const initialX = pdfPage.dataset.selectionX;
		const initialY = pdfPage.dataset.selectionY - page * 792;

		const endX = pdfPage.dataset.selectionEndX;
		const endY = pdfPage.dataset.selectionEndY;

		let y1 = Math.min(initialY, endY);
		let y2 = Math.max(initialY, endY);
		let x1 = Math.min(initialX, endX);
		let x2 = Math.max(initialX, endX);

		handleSignPosition(page, x1, y1, x2, y2);
		pdfPage.dataset.selectionX = undefined;
		pdfPage.dataset.selectionY = undefined;
		pdfPage.dataset.selectionEndX = undefined;
		pdfPage.dataset.selectionEndY = undefined;
		document.getElementById('sign-helper').remove();
		pdfPage.removeEventListener('mousemove', handleMouseMove);
	};
	const handleMouseMove = (event) => {
		if (
			!isSelectingSignPosition ||
			event.target.dataset.selectionX == null ||
			event.target.dataset.selectionX == undefined
		)
			return;
		//console.log(event.target.dataset.selectionX);
		console.log('handleMouseMove');
		const { layerX, layerY } = event;
		console.log(event.target.dataset);
		const page = event.target.dataset.testid.replace(
			'core__text-layer-',
			''
		);
		const initialX = event.target.dataset.selectionX;
		const initialY = event.target.dataset.selectionY - page * 792;
		const posY = layerY - page * 792;

		const pdfContainer = event.target;
		let y1 = Math.min(initialY, posY);
		let y2 = Math.max(initialY, posY);
		let x1 = Math.min(initialX, layerX);
		let x2 = Math.max(initialX, layerX);

		const newChild = document.getElementById('sign-helper');
		newChild.style.border = '2px solid #3b6fb5';
		newChild.style.backgroundColor = 'rgb(87, 148, 229, 0.5)';
		newChild.style.position = 'absolute';
		newChild.style.color = 'rgb(0,0,0,0.8)';
		newChild.style.left = `calc(var(--scale-factor) * ${x1}px)`;
		newChild.style.top = `calc(var(--scale-factor) * ${y1}px)`;
		newChild.style.width = `calc(var(--scale-factor) * ${Math.abs(
			x2 - x1
		)}px)`;
		newChild.style.height = `calc(var(--scale-factor) * ${Math.abs(
			y2 - y1
		)}px)`;
		pdfContainer.dataset.selectionEndX = layerX;
		pdfContainer.dataset.selectionEndY = posY;
		//pdfContainer.appendChild(newChild);
	};

	const handleMoveOut = (event) => {
		//console.log('handleMoveOut');
		if (!isSelectingSignPosition) return;
		const pdfPage = event.target;
		if (event.target.id == 'sign-helper') {
			pdfPage = event.target.parentElement;
		}
		pdfPage.dataset.selectionX = undefined;
		pdfPage.dataset.selectionY = undefined;
		pdfPage.dataset.selectionEndX = undefined;
		pdfPage.dataset.selectionEndY = undefined;
		document.getElementById('sign-helper').remove();
		pdfPage.removeEventListener('mousemove', handleMouseMove);
	};

	const handleSwitchTheme = (new_theme) => {
		setTheme(new_theme);
	};
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		sidebarTabs: (defaultTabs) => [
			{
				content: (
					<SearchPlugin
						searchPluginInstance={
							defaultLayoutPluginInstance.toolbarPluginInstance
								.searchPluginInstance
						}
						theme={theme}
					/>
				),
				icon: <SearchIcon />,
				title: 'Search',
			},
			defaultTabs[0],
		],
		renderToolbar: (Toolbar) => CustomToolbar(Toolbar, canDownload),
		toolbarPlugin: {
			zoomPlugin: {
				enableShortcuts: true,
			},
		},
	});

	const customCanvasPlugin = () => {
		const onCanvasLayerRender = (e) => {
			// Return if the canvas isn't rendered completely
			if (e.status !== LayerRenderStatus.DidRender) {
				return;
			}
			const canvas = e.ele;
		};
		return {
			onCanvasLayerRender,
		};
	};
	const customCanvasPluginInstance = customCanvasPlugin();

	useEffect(() => {
		if (signPositions.length > 0) {
			/* const pdfContainer = document.querySelector(
				'.rpv-core__inner-pages.rpv-core__inner-pages--vertical > div'
			); */
			signPositions.forEach((sign) => {
				const page = sign.page;
				console.log(page);
				const signChild = document.querySelector(`#sign-${sign.id}`);
				if (!signChild) {
					const pdfContainer = document.querySelector(
						`.rpv-core__inner-pages.rpv-core__inner-pages--vertical > div > div.rpv-core__inner-page-container > div[aria-label="Page ${
							+page + 1
						}"]`
					)?.firstChild;
					if (!pdfContainer) return;
					const signer = clients.find(
						(client) => client?.model?.client_user === sign.id
					);
					if (!signer) return;
					console.log(sign);
					const name = `${signer?.relations?.clientUser?.model?.first_name} ${signer?.relations?.clientUser?.model?.last_name}`;

					let y1 = Math.min(sign.endY, sign.y);
					let y2 = Math.max(sign.endY, sign.y);
					let x1 = Math.min(sign.endX, sign.x);
					let x2 = Math.max(sign.endX, sign.x);

					const newChild = document.createElement('div');
					newChild.id = `sign-${sign.id}`;
					newChild.style.border = '2px solid #3b6fb5';
					newChild.style.textAlign = 'center';
					newChild.style.fontSize =
						'calc(var(--scale-factor) * 1rem)';
					newChild.style.backgroundColor = 'rgb(87, 148, 229, 0.5)';
					newChild.style.display = 'flex';
					newChild.style.justifyContent = 'center';
					newChild.style.alignItems = 'center';
					newChild.style.position = 'absolute';
					newChild.style.fontWeight = 'bold';
					newChild.style.color = 'rgb(0,0,0,0.8)';
					newChild.style.left = `calc(var(--scale-factor) * ${x1}px)`;
					newChild.style.top = `calc(var(--scale-factor) * ${y1}px)`;
					newChild.style.width = `calc(var(--scale-factor) * ${Math.abs(
						x2 - x1
					)}px)`;
					newChild.style.height = `calc(var(--scale-factor) * ${Math.abs(
						y2 - y1
					)}px)`;
					newChild.innerText = name;
					newChild.style.zIndex = 10;
					const xSpace = document.createElement('span');
					xSpace.style.aspectRatio = '1/1';
					xSpace.innerText = 'x';
					xSpace.style.color = 'white';
					xSpace.style.fontSize = 'calc(var(--scale-factor) * 1rem)';
					xSpace.style.position = 'absolute';
					xSpace.style.right = '0';
					xSpace.style.top = '0';
					xSpace.style.cursor = 'pointer';
					xSpace.style.transform = 'translate(50%, -50%)';
					xSpace.style.backgroundColor = '#3b6fb5';
					xSpace.style.zIndex = 11;
					xSpace.style.borderRadius = '50%';
					xSpace.style.display = 'flex';
					xSpace.style.justifyContent = 'center';
					xSpace.style.alignItems = 'center';
					xSpace.style.width = '1rem';
					xSpace.style.height = '1rem';
					newChild.appendChild(xSpace);
					xSpace.addEventListener('click', () => {
						newChild.remove();
						removeSignPosition(sign.id);
					});
					pdfContainer.appendChild(newChild);
				}
			});
		}
	}, [signPositions]);

	useEffect(() => {
		const addListeners = (numPages) => {
			for (let i = 0; i < numPages; i++) {
				const pdfContainer = document.querySelector(
					`.rpv-core__inner-pages.rpv-core__inner-pages--vertical > div > div.rpv-core__inner-page-container > div[aria-label="Page ${
						+i + 1
					}"]`
				)?.firstChild;
				pdfContainer.addEventListener('mousedown', handleMouseDown);
				pdfContainer.addEventListener('mouseup', handleMouseUp);
			}
		};
		const removeListeners = (numPages) => {
			for (let i = 0; i < numPages; i++) {
				const pdfContainer = document.querySelector(
					`.rpv-core__inner-pages.rpv-core__inner-pages--vertical > div > div.rpv-core__inner-page-container > div[aria-label="Page ${
						+i + 1
					}"]`
				)?.firstChild;
				pdfContainer?.removeEventListener('mousedown', handleMouseDown);
				pdfContainer?.removeEventListener('mouseup', handleMouseUp);
			}
		};
		if (numPages > 0 && isSelectingSignPosition) {
			addListeners(numPages);
		}
		if (numPages > 0 && !isSelectingSignPosition) {
			removeListeners(numPages);
		}
		return () => {
			removeListeners(numPages);
		};
	}, [numPages, isSelectingSignPosition]);

	return (
		<Worker
			//workerUrl={'/unpkg.com_pdfjs-dist@3.4.120_build_pdf.worker.min.js'}
			workerUrl={WORKER_URL}
		>
			<div className={`${styles.cnt_viewer}`} ref={viewerRef}>
				<Viewer
					onSwitchTheme={handleSwitchTheme}
					fileUrl={fileUrl}
					//fileUrl={PDF}
					renderLoader={Loader}
					plugins={[
						defaultLayoutPluginInstance,
						customCanvasPluginInstance,
					]}
					defaultScale={1}
					renderError={ErrorMessage}
					theme={{
						theme: 'dark',
					}}
					onDocumentLoad={(e) => {
						setNumPages(e.doc.numPages);
						signPositions.forEach((sign) => {
							const page = sign.page;
							console.log(page);
							const signChild = document.querySelector(
								`#sign-${sign.id}`
							);
							if (!signChild) {
								console.log(sign);
								const pdfContainer = document.querySelector(
									`.rpv-core__inner-pages.rpv-core__inner-pages--vertical > div > div.rpv-core__inner-page-container > div[aria-label="Page ${
										+page + 1
									}"]`
								)?.firstChild;
								if (!pdfContainer) return;
								const signer = clients.find(
									(client) =>
										client?.model?.client_user == sign.id
								);
								if (!signer) return;
								console.log(sign);
								const name = `${signer?.relations?.clientUser?.model?.first_name} ${signer?.relations?.clientUser?.model?.last_name}`;

								let y1 = Math.min(+sign.endY, +sign.y);
								let y2 = Math.max(+sign.endY, +sign.y);
								let x1 = Math.min(+sign.endX, +sign.x);
								let x2 = Math.max(+sign.endX, +sign.x);

								const newChild = document.createElement('div');
								newChild.id = `sign-${sign.id}`;
								newChild.style.border = '2px solid #3b6fb5';
								newChild.style.textAlign = 'center';
								newChild.style.fontSize =
									'calc(var(--scale-factor) * 1rem)';
								newChild.style.backgroundColor =
									'rgb(87, 148, 229, 0.5)';
								newChild.style.display = 'flex';
								newChild.style.justifyContent = 'center';
								newChild.style.alignItems = 'center';
								newChild.style.position = 'absolute';
								newChild.style.fontWeight = 'bold';
								newChild.style.color = 'rgb(0,0,0,0.8)';
								newChild.style.left = `calc(var(--scale-factor) * ${x1}px)`;
								newChild.style.top = `calc(var(--scale-factor) * ${y1}px)`;
								newChild.style.width = `calc(var(--scale-factor) * ${Math.abs(
									x2 - x1
								)}px)`;
								newChild.style.height = `calc(var(--scale-factor) * ${Math.abs(
									y2 - y1
								)}px)`;
								newChild.innerText = name;
								newChild.style.zIndex = 10;
								if (!sign.blocked) {
									const xSpace =
										document.createElement('span');
									xSpace.style.aspectRatio = '1/1';
									xSpace.innerText = 'x';
									xSpace.style.color = 'white';
									xSpace.style.fontSize =
										'calc(var(--scale-factor) * 1rem)';
									xSpace.style.position = 'absolute';
									xSpace.style.right = '0';
									xSpace.style.top = '0';
									xSpace.style.cursor = 'pointer';
									xSpace.style.transform =
										'translate(50%, -50%)';
									xSpace.style.backgroundColor = '#3b6fb5';
									xSpace.style.zIndex = 11;
									xSpace.style.borderRadius = '50%';
									xSpace.style.display = 'flex';
									xSpace.style.justifyContent = 'center';
									xSpace.style.alignItems = 'center';
									xSpace.style.width = '1rem';
									xSpace.style.height = '1rem';
									newChild.appendChild(xSpace);
									xSpace.addEventListener('click', () => {
										newChild.remove();
										removeSignPosition(sign.id);
									});
								}

								pdfContainer.appendChild(newChild);
							}
						});
					}}
				/>
				{/* {isSelectingSignPosition && (
					<div
						style={{
							position: 'absolute',
							inset: 0,
							cursor: 'crosshair',
							position: 'absolute',
							left: Math.min(startX, endX),
							top: Math.min(startY, endY),
							width: Math.abs(endX - startX),
							height: Math.abs(endY - startY),
							border: '2px solid #3b6fb5',
							textAlign: 'center',
							fontSize: '1rem',
							backgroundColor: '#5794e5',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					/>
				)} */}
				{/* {signPositions.length > 0 &&
					signPositions.map((sign) => {
						console.log(sign);
						const client = 'Juan Pablo';


						return (
							<div
								key={sign.id}
								style={{
									position: 'absolute',
									left: Math.min(sign.x, sign.endX),
									top: Math.min(sign.y, sign.endY),
									width: Math.abs(sign.endX - sign.x),
									height: Math.abs(sign.endY - sign.y),
									border: '2px solid #3b6fb5',
									textAlign: 'center',
									fontSize: '1rem',
									backgroundColor: '#5794e5',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<p>{client}</p>
							</div>
						);
					})} */}
			</div>
		</Worker>
	);
}
