const style = (theme)=>({
    editorContainer:{
        border:theme.customConstants.defaultBorder,
        boxShadow:theme.customConstants.defaultBoxShadow,
        'editorContainer border rdw-editor-toolbar':{
            marginBottom:'0px !important'
        }
    },
    toolbar:{
        marginBottom:'0px',
    }
});

export default style;