import {
	CREATE_DOSSIER_REQUEST,
	CREATE_DOSSIER_REQUEST_SUCCESS,
	CREATE_DOSSIER_REQUEST_FAIL,
	DOSSIER_REQUEST,
	DOSSIER_REQUEST_SUCCESS,
	DOSSIER_REQUEST_FAIL,
	PENDING_DOSSIER_REQUEST,
	PENDING_DOSSIER_REQUEST_SUCCESS,
	PENDING_DOSSIER_REQUEST_FAIL,
	UPDATE_DOSSIER_REQUEST,
	UPDATE_DOSSIER_REQUEST_SUCCESS,
	UPDATE_DOSSIER_REQUEST_FAIL,
	DOSSIER_HISTORY_REQUEST,
	DOSSIER_HISTORY_REQUEST_SUCCESS,
	DOSSIER_HISTORY_REQUEST_FAIL,
	DOSSIER_COMMENT_REQUEST,
	DOSSIER_COMMENT_REQUEST_SUCCESS,
	DOSSIER_COMMENT_REQUEST_FAIL,
	MY_DOCUMENTS_REQUEST,
	MY_DOCUMENTS_REQUEST_SUCCESS,
	MY_DOCUMENTS_REQUEST_ERROR,
	DOSSIER_DRAFT_REQUEST,
	DOSSIER_DRAFT_REQUEST_SUCCESS,
	DOSSIER_DRAFT_REQUEST_FAIL,
	DOSSIER_DRAFT_DONE,
	DOSSIER_SIGNATURE_REQUEST,
	DOSSIER_SIGNATURE_REQUEST_SUCCESS,
	DOSSIER_SIGNATURE_REQUEST_FAIL,
	DOSSIER_ADD_DOCUMENTS_REQUEST,
	DOSSIER_ADD_DOCUMENTS_REQUEST_SUCCESS,
	DOSSIER_ADD_DOCUMENTS_REQUEST_FAIL,
	FINALIZE_DOSSIER_REQUEST,
	FINALIZE_DOSSIER_REQUEST_SUCCESS,
	FINALIZE_DOSSIER_REQUEST_FAIL,
} from '../constants/dossierConstants';
import Swal from 'sweetalert2';

import Axios from '../services/Api.js';

export const createDossier =
	(
		title,
		description,
		document_type,
		file,
		RFC,
		INE,
		CURP,
		signers,
		callback = () => {}
	) =>
	async (dispatch) => {
		dispatch({ type: CREATE_DOSSIER_REQUEST, loading: true });
		//console.log(signers);
		const body = {
			data: {
				title,
				description,
				document_type,
				file,
				type: 2,
				document_type,
				requirements: { RFC, INE, CURP },
				signers: { client: signers.map((signer) => signer.id) },
			},
		};
		try {
			const { data } = await Axios.CreateDossier(body);
			//console.log(data);

			if (JSON.parse(data.result)) {
				dispatch({
					type: CREATE_DOSSIER_REQUEST_SUCCESS,
					payload: data.create_or_update.state,
				});
				Swal.fire('Expediente creado!', '', 'success').then(() =>
					callback(data.create_or_update.state[0].state.id)
				);
			} else {
				dispatch({
					type: CREATE_DOSSIER_REQUEST_FAIL,
					error: 'Hubo un error al crear el expediente',
				});
				callback(null);
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: CREATE_DOSSIER_REQUEST_FAIL,
				error:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
			callback(null);
		}
	};

export const getDossier = (number) => async (dispatch) => {
	dispatch({ type: DOSSIER_REQUEST, loading: true });

	try {
		const { data } = await Axios.GetDossier({ params: { number } });
		//console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: DOSSIER_REQUEST_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result[0],
			});
		} else {
			dispatch({
				type: DOSSIER_REQUEST_FAIL,
				error: 'Hubo un error al obtener el expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: CREATE_DOSSIER_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const getPendingDossier = () => async (dispatch) => {
	dispatch({ type: PENDING_DOSSIER_REQUEST, loading: true });

	try {
		const { data } = await Axios.PendingDossier();
		//console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: PENDING_DOSSIER_REQUEST_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result,
			});
		} else {
			dispatch({
				type: CREATE_DOSSIER_REQUEST_FAIL,
				error: 'Hubo un error al obtener los expedientes',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: PENDING_DOSSIER_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const updateDossier = (dossier, file, callback) => async (dispatch) => {
	dispatch({ type: UPDATE_DOSSIER_REQUEST, loading: true });

	const body = {
		data: {
			file,
			dossier,
		},
	};
	try {
		const { data } = await Axios.UpdateDossier(body);
		//console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: UPDATE_DOSSIER_REQUEST_SUCCESS,
				payload: data.create_or_update.state,
			});
			Swal.fire('Expediente actualizado!', '', 'success').then(() =>
				callback()
			);
		} else {
			dispatch({
				type: UPDATE_DOSSIER_REQUEST_FAIL,
				error: 'Hubo un error al actualizar el expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: UPDATE_DOSSIER_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const getDossierHistory = (document, file) => async (dispatch) => {
	dispatch({ type: DOSSIER_HISTORY_REQUEST, loading: true });

	const body = {
		data: {
			file,
			document,
		},
	};
	try {
		const { data } = await Axios.DossierHistory(body);
		//console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: DOSSIER_HISTORY_REQUEST_SUCCESS,
				payload:
					data.query_result.models_1.queryOneModel_1.result.reverse(),
			});
		} else {
			dispatch({
				type: DOSSIER_HISTORY_REQUEST_FAIL,
				error: 'Hubo un error al obtener el historial del expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: DOSSIER_HISTORY_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const getDossierComments = (document, file) => async (dispatch) => {
	dispatch({ type: DOSSIER_COMMENT_REQUEST, loading: true });

	const body = {
		data: {
			file,
			document,
		},
	};
	//	console.log(body);
	try {
		const { data } = await Axios.DossierComments(body);
		console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: DOSSIER_COMMENT_REQUEST_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result,
			});
		} else {
			dispatch({
				type: DOSSIER_COMMENT_REQUEST_FAIL,
				error: 'Hubo un error al obtener los comentarios del expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: DOSSIER_COMMENT_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const getMyDocuments = () => async (dispatch) => {
	dispatch({ type: MY_DOCUMENTS_REQUEST, loading: true });

	try {
		const { data } = await Axios.getMyDocuments();
		//console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: MY_DOCUMENTS_REQUEST_SUCCESS,
				payload: data.query_result.models_1.queryOneModel_1.result,
			});
		} else {
			dispatch({
				type: MY_DOCUMENTS_REQUEST_ERROR,
				error: 'Hubo un error al obtener los expedientes',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: MY_DOCUMENTS_REQUEST_ERROR,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const requestDraftReview = (dossier, client) => async (dispatch) => {
	dispatch({ type: DOSSIER_DRAFT_REQUEST, loading: true });

	const body = {
		data: {
			dossier,
			signers: { client },
		},
	};
	console.log(body);
	try {
		const { data } = await Axios.RequestDraftReview(body);
		console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: DOSSIER_DRAFT_REQUEST_SUCCESS,
				payload: JSON.parse(data.result),
			});
		} else {
			dispatch({
				type: DOSSIER_DRAFT_REQUEST_FAIL,
				error: 'Hubo un error al solicitar la revision del expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: DOSSIER_DRAFT_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const cleanRequestDraft = () => async (dispatch) => {
	dispatch({ type: DOSSIER_DRAFT_DONE, payload: {} });
};

export const requestSignature = (dossier, client) => async (dispatch) => {
	dispatch({ type: DOSSIER_SIGNATURE_REQUEST, loading: true });
	const clients = client.map((c) => ({
		id: c.id,
		page: c.page,
		x: c.x,
		y: c.y,
		width: Math.abs(c.endX - c.x),
		height: Math.abs(c.endY - c.y),
	}));

	const body = {
		data: {
			dossier,
			signers: { client: clients },
		},
	};
	console.log(body);
	try {
		const { data } = await Axios.RequestSignature(body);
		console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: DOSSIER_SIGNATURE_REQUEST_SUCCESS,
				payload: JSON.parse(data.result),
			});
		} else {
			dispatch({
				type: DOSSIER_SIGNATURE_REQUEST_FAIL,
				error: 'Hubo un error al solicitar la revision del expediente',
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: DOSSIER_SIGNATURE_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const addDocuments =
	(dossier, archivos, callback) => async (dispatch) => {
		dispatch({ type: DOSSIER_ADD_DOCUMENTS_REQUEST, loading: true });
		let response = {};
		try {
			for (let i = 0; i < archivos.length; i++) {
				const body = {
					data: {
						file: archivos[i],
						dossier,
					},
				};
				const { data } = await Axios.AddDocuments(body);
				console.log(data);
				response = data;
			}
			console.log(response);

			if (JSON.parse(response.result)) {
				dispatch({
					type: DOSSIER_ADD_DOCUMENTS_REQUEST_SUCCESS,
					payload: response.create_or_update.state,
				});
			} else {
				dispatch({
					type: DOSSIER_ADD_DOCUMENTS_REQUEST_FAIL,
					error: 'Hubo un error al actualizar el expediente',
				});
			}
			callback();
		} catch (error) {
			console.log(error);
			dispatch({
				type: DOSSIER_ADD_DOCUMENTS_REQUEST_FAIL,
				error:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};

export const finalizeDossier = (dossier) => async (dispatch) => {
	dispatch({ type: FINALIZE_DOSSIER_REQUEST, loading: true });

	const body = {
		data: {
			dossier,
		},
	};
	try {
		const { data } = await Axios.FinalizeDossier(body);
		console.log(data);

		if (JSON.parse(data.result)) {
			dispatch({
				type: FINALIZE_DOSSIER_REQUEST_SUCCESS,
				payload: data,
			});
		} else {
			dispatch({
				type: FINALIZE_DOSSIER_REQUEST_FAIL,
				error: 'Hubo un error al actualizar el expediente',
			});
		}
		Swal.fire('Expediente finalizado!', '', 'success').then(
			() => (window.location.href = '/dashboard')
		);
	} catch (error) {
		console.log(error);
		dispatch({
			type: FINALIZE_DOSSIER_REQUEST_FAIL,
			error:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
