import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Select, MenuItem, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from 'assets/jss/material-dashboard-react/components/registroDocumentoStyle';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

export default function SeleccionClientes(props) {
	const classes = useStyles();

	const { clients } = useSelector((state) => state.user_clients);

	const [client, setClient] = useState(0);

	const selectClient = () => {
		const isAdded =
			props.selected.length > 0 &&
			props.selected.find((item) => item.id === client);
		if (!isAdded) {
			const cliente = clients.find(
				(item) => item.model.client_user === client
			);
			const nombre = `${cliente.relations.clientUser.model.first_name} ${cliente.relations.clientUser.model.last_name}`;
			cliente &&
				props.setSelected([
					...props.selected,
					{ id: cliente.model.client_user, nombre },
				]);
			setClient(0);
		}
	};

	const removeClient = (id) => {
		props.setSelected([
			...props.selected.filter((cliente) => cliente.id !== id),
		]);
	};

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				<Typography variant='h3'>
					Especifique los cliente asociados a este documento
				</Typography>
			</div>
			<div className={classes.row}>
				<Grid item xs={12} md={9}>
					<Select
						fullWidth
						labelId='label'
						id='client'
						value={client}
						onChange={(e) => setClient(e.target.value)}
					>
						<MenuItem value={0}>Seleccione clientes</MenuItem>
						{clients
							.filter(
								(cliente) =>
									!props.selected
										.map((c) => c.id)
										.includes(cliente.model.client_user)
							)
							.map((client, i) => (
								<MenuItem
									key={i}
									value={client.model.client_user}
								>
									{`${client.relations.clientUser.model.first_name} ${client.relations.clientUser.model.last_name}`}
								</MenuItem>
							))}
					</Select>
				</Grid>
				<Grid item xs={12} md={3} className={classes.centered}>
					<Button
						variant='contained'
						color='primary'
						className={classes.button}
						disabled={client === 0}
						onClick={selectClient}
					>
						Agregar
					</Button>
				</Grid>
			</div>
			<div className={classes.title}>
				<Typography variant='h3'>Clientes a asociar</Typography>
			</div>
			<div className={classes.column}>
				{props.selected.map((client, i) => (
					<div className={classes.clientRow} key={i}>
						<div className={classes.clientID}>#{client.id}</div>
						<div className={classes.clientName}>
							{client.nombre}
						</div>
						<div className={classes.removeClient}>
							<DeleteIcon
								onClick={() => removeClient(client.id)}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
