import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
	Grid,
	Typography,
	Button,
	CircularProgress,
	Snackbar,
	Modal,
	Fade,
	Backdrop,
	TextField,
} from '@material-ui/core';
import { withRouter } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Axios from '../../services/Api.js';
import FirmaUpload from 'components/FirmaUpload/FirmaUpload.js';
import SignUpload from 'components/SignUpload/SignUpload.js';
import { useSelector } from 'react-redux';

const styles = (theme) => ({
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: '20px',
	},
	rowContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: '20px',
		//height: '100%',
		backgroundColor: 'white',
		border: '2px solid black',
		padding: '20px',
		[theme.breakpoints.down('sm')]: {
			gap: '1.5rem',
		},
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	clickable: {
		display: 'flex',
		width: '25px',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
	},
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
	},
	formContainer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '0',
			justifyContent: 'flex-start',
		},
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			justifyContent: 'space-between',
		},
	},
	buttonSpace: {
		alignSelf: 'flex-end',
	},
	button: {
		//	backgroundColor: '#e0e0e0',
		width: '100%',
		height: '100%',
	},
	alertError: {
		color: 'red',
		border: '1px solid red',
		padding: '10px',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ffebee',
		borderRadius: '5px',
		width: '80%',
		alignSelf: 'center',
		position: 'relative',
	},
	closeIconAlert: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 'auto',
		cursor: 'pointer',
		justifyContent: 'center',
		width: '20px',
		'&:hover': {
			color: 'black',
			borderRadius: '50%',
			aspectRatio: '1/1',
			backgroundColor: 'rgba(0,0,0,0.1)',
		},
	},
	alertSuccess: {
		color: 'green',
		border: '1px solid green',
		padding: '10px',
		marginTop: '10px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#e8f5e9',
		borderRadius: '5px',
		width: '80%',
		alignSelf: 'center',
		position: 'relative',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
});

const useStyles = makeStyles(styles);

function SignUploadView(props) {
	const classes = useStyles();
	const [isPosting, setIsPosting] = useState({
		isPosting: false,
		success: false,
		isError: false,
		message: '',
		showModal: false,
	});

	const signRequest = useSelector((state) => state.signRequest);
	if (!signRequest.isSetup) location.href = '/firmas';

	const handleClose = () => {};

	const goBack = () => {
		props.history.goBack();
	};

	return (
		<div>
			<Grid container className={classes.container}>
				<Grid item xs={1} className={classes.column}>
					<div className={classes.clickable} onClick={goBack}>
						<ArrowBackIcon />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className={classes.row}>
						<Typography variant='h3'>Nueva Firma</Typography>
					</div>
					<Grid item className={classes.rowContainer}>
						<Grid item xs={12} className={classes.formContainer}>
							<SignUpload />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className={classes.modal}
				open={isPosting.showModal}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={isPosting.showModal}>
					<div className={classes.paper}>
						<Typography
							variant='h3'
							gutterBottom
							id='transition-modal-title'
						>
							{isPosting.success ? 'Éxito' : 'Ocurrio un error'}
						</Typography>
						<p id='transition-modal-description'>
							{isPosting.message}
						</p>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

export default withRouter(SignUploadView);
