import {
	UPLOAD_REQUEST,
	UPLOAD_REQUEST_SUCCESS,
	UPLOAD_REQUEST_ERROR,
	DELETE_REQUEST,
	DELETE_REQUEST_ERROR,
	DELETE_REQUEST_SUCCESS,
	SAVE_REQUEST,
	SAVE_REQUEST_ERROR,
	SAVE_REQUEST_SUCCESS,
	DOC_TYPES_REQUEST,
	DOC_TYPES_REQUEST_SUCCESS,
	DOC_TYPES_REQUEST_ERROR,
} from '../constants/documentConstants';
import Swal from 'sweetalert2';
import axios from 'axios';

import Axios from '../services/Api.js';

export const uploadFile =
	(file, s3Upload = false) =>
	async (dispatch) => {
		dispatch({ type: UPLOAD_REQUEST });

		const fileA = file.getAll('file')[0];
		try {
			const formData = new FormData();

			if (s3Upload !== false) {
				const s3Endpoint = s3Upload.url;
				formData.append('key', s3Upload.fields['key']);
				formData.append(
					'AWSAccessKeyId',
					s3Upload.fields['AWSAccessKeyId']
				);
				formData.append(
					'x-amz-security-token',
					s3Upload.fields['x-amz-security-token']
				);
				formData.append('policy', s3Upload.fields['policy']);
				formData.append('signature', s3Upload.fields['signature']);
				formData.append('file', fileA);
				const { data } = await axios.post(s3Endpoint, formData);

				dispatch({
					type: UPLOAD_REQUEST_SUCCESS,
					payload: {
						id: s3Upload.fields['key'],
						name: s3Upload.fields['key'],
					},
				});
			} else {
				formData.append('File[digitalFile]', fileA);
				const { data } = await Axios.UploadFile(formData);
				dispatch({ type: UPLOAD_REQUEST_SUCCESS, payload: data });
			}

			Swal.fire('Archivo', 'Documento cargado correctamente', 'success');
		} catch (error) {
			console.log(error);
			dispatch({ type: UPLOAD_REQUEST_ERROR });
			Swal.fire({
				icon: 'error',
				title: 'Hubo un error',
				text: 'Error inesperado al subir archivo',
			});
		}
	};

export const saveFile = (fileData) => async (dispatch) => {
	dispatch({ type: SAVE_REQUEST });

	try {
		///alguna logica adicional puede ir acá

		dispatch({ type: SAVE_REQUEST_SUCCESS, payload: fileData });

		//Swal.fire('Archivo', 'Archivo guardado correctamente', 'success');
	} catch (error) {
		console.log(error);
		dispatch({ type: SAVE_REQUEST_ERROR });
		Swal.fire({
			icon: 'error',
			title: 'Hubo un error',
			text: 'Error inesperado al guardar el archivo',
		});
	}
};

export const deleteFile = (id) => async (dispatch) => {
	dispatch({ type: DELETE_REQUEST });

	try {
		//const { data } = await Axios.dowloadFile(id);
		//console.log(data);

		dispatch({ type: DELETE_REQUEST_SUCCESS, payload: id });

		//Swal.fire('Archivo', 'Archivo eliminado correctamente', 'success');
	} catch (error) {
		console.log(error);
		dispatch({ type: DELETE_REQUEST_ERROR });
		Swal.fire({
			icon: 'error',
			title: 'Hubo un error',
			text: 'Error inesperado al eliminar el archivo',
		});
	}
};

export const getDocumentTypes = () => async (dispatch) => {
	dispatch({ type: DOC_TYPES_REQUEST });

	try {
		const { data } = await Axios.documentTypes();
		console.log(data);
		//const data = resultDocType;

		dispatch({
			type: DOC_TYPES_REQUEST_SUCCESS,
			payload: data.query_result.models_1.queryOneModel_1.result,
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: DOC_TYPES_REQUEST_ERROR,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
