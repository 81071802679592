import {
	blackColor,
	whiteColor,
	hexToRgb,
} from 'assets/jss/material-dashboard-react.js';

const filterStyle = {
	card: {
		border: '0',
		boxSizing: 'border-box',
		marginBottom: '10px',
		borderRadius: '2px',
		color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
		background: whiteColor,
		boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
		display: 'flex',
		position: 'relative',
		width: '100%',
		flexDirection: 'column',
		minWidth: '0',
		wordWrap: 'break-word',
		fontSize: '.875rem',
		padding: '25px',
	},
	field: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column',
		marginBottom: '2rem',
	},
	buttonSpace: {
		alignSelf: 'flex-end',
	},
	button: {
		boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
	},
};

export default filterStyle;
