import React, { useEffect, useState } from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ContentTable from 'components/ContentTable/ContentTable';
import { Button, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Axios from 'services/Api.js';
import { prepareUpload } from 'actions/signActions';

const useStyles = makeStyles(styles);

var fakeColumns = [
	{ name: 'ID', flex: 1, align: 'center', minWidth: 100 },
	{ name: 'Name', flex: 3, align: 'left', minWidth: 200 },
	{ name: 'Description', align: 'center', flex: 2, minWidth: 100 },
];

function SignView(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [rows, setRows] = useState([]);

	const signRequest = useSelector((state) => state.signRequest);
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo } = userSignin;

	const visibilityActionClick = (id) => {
		props.history.push('/firmas/' + id);
	};

	const prepareSignature = () => {
		dispatch(prepareUpload(nuevaFirma));
	};

	const nuevaFirma = () => {
		props.history.push('/firmas/nueva-firma');
	};

	useEffect(() => {
		Axios.GetSignatures(userInfo.id)
			.then((res) => {
				if (!res.data.success) throw new Error(res.data.message);
				setRows(
					res.data.signatures.map((sign) => ({
						id: sign.id,
						name: sign.name,
						description: sign.description,
					}))
				);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	return (
		<div style={{ height: '100%' }}>
			<Grid container className={classes.accesoRapidoContainer}>
				<Grid item xs={12} sm={4}>
					<Button
						variant='outlined'
						color='primary'
						className={classes.newSign}
						onClick={prepareSignature}
						disabled={signRequest.loading}
					>
						{!signRequest.loading && (
							<>
								<i className='fa fa-plus'></i>
								&nbsp;Nueva firma
							</>
						)}
						{signRequest.loading && (
							<>
								<i className='fa fa-spinner fa-spin'></i>
								&nbsp;Cargando ...
							</>
						)}
					</Button>
				</Grid>
			</Grid>
			<ContentTable
				title='Firmas'
				pseudoRows={rows}
				columnDefinitions={fakeColumns}
				//renderActionsHeader
				visibilityHandler={visibilityActionClick}
			/>
		</div>
	);
}

export default withRouter(SignView);
