import React from 'react';
import ContentTable from 'components/ContentTable/ContentTable';
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-react/views/expedienteDetaillStyle";
import { Input, OutlinedInput, MenuItem, Select, Typography } from "@material-ui/core";
import EntitledContainer from 'components/EntitledContainer/EntitledContainer';

const useStyles = makeStyles(styles);

const fakeRows = [
	{
		nombre:12188, 
		fecha:'12/08/2021', 
		asunto:'0 Compra computadora', 
		plazo:'31/08/2021', 
		designado:'Pendiente', 
		revisadoPor:'vcr,df',
		actions:["visibility","delete"]
	},
	{
		nombre:12188, 
		fecha:'12/08/2021', 
		asunto:'1 Compra computadora', 
		plazo:'31/08/2021', 
		designado:'Pendiente', 
		revisadoPor:'vcr,df',
		actions:["visibility","delete"]
	},
	{
		nombre:12188, 
		fecha:'12/08/2021', 
		asunto:'2 Compra computadora', 
		plazo:'31/08/2021', 
		designado:'Pendiente', 
		revisadoPor:'vcr,df',
		actions:["visibility","delete"]
	},
];

var fakeColumns = ['No. Registro', 'Fecha de Ingreso', 'Asunto', 'Plazo', 'Designado', 'Revisado por'];

export default function Expedientes(props) {
    const classes = useStyles();
    return (
        <EntitledContainer title="Expedientes en Estudio">
            <div className={classes.row} style={{marginBottom:'30px'}}>
                <div className={classes.labelWithText}>
                    <Typography variant="h4">Fecha de Ingreso:</Typography>
                    <Input className={classes.dateInput} defaultValue="10/05/2021" type="date" disableUnderline={true}  />
                </div>
                <div className={classes.labelWithText}>
                    <Typography variant="h4">Plazo:</Typography>
                    <Input className={classes.dateInput} defaultValue="10/05/2021" type="date" onClick={(e) => e.preventDefault()} disableUnderline={true} />
                </div>
                <div className={classes.labelWithText}>
                    <Typography variant="h4">Tipo de Expediente:</Typography>
                    <Select
                        value={10}
                        onChange={undefined}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                        disableUnderline={true}
                        className={classes.dateInput}
                    >
                        <MenuItem value="" disabled>
                            Placeholder
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </div>
                <div className={classes.labelWithText}>
                    <Typography variant="h4">Tipo de Expediente:</Typography>
                    <Select
                        value={10}
                        onChange={undefined}
                        displayEmpty
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                        disableUnderline={true}
                        className={classes.dateInput}
                    >
                        <MenuItem value="" disabled>
                            Placeholder
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </div>
            </div>
            <ContentTable pseudoRows={fakeRows} columnDefinitions={fakeColumns} renderActionsHeader />
        </EntitledContainer>
    );
}