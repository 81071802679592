const ApiHostName =
	window.location.hostname.indexOf('firm.laventacapital.com') >= 0
		? window.location.hostname
				.replace(
					'firma.laventacapital.com',
					'api.firma.laventacapital.com'
				)
				.replace('.cliente', '')
		: 'api.' + window.location.hostname;

//console.log('ApiHostName:' + ApiHostName);
const ApiURLs = {
	publicFilesURL: 'https://firma.laventacapital.local/v1/',

	baseURL: location.protocol + '//' + ApiHostName + '/v1/',
	UserLogin: {
		href: '/auth/authenticate',
		method: 'post',
		requires: ['username', 'password', 'remember'],
	},
	documentTypes: {
		href: '/api/tx/document-type',
		method: 'get',
	},
	userClients: {
		href: '/api/tx/list-clients',
		method: 'get',
	},
	UploadFile: {
		href: '/file/upload',
		method: 'post',
	},
	CreateDossier: {
		href: '/api/tx/create-dossier',
		method: 'post',
	},
	PendingDossier: {
		href: '/api/tx/my-pendings',
		method: 'get',
	},
	GetCompletedDocuments: {
		href: '/api/tx/my-completed',
		method: 'get',
	},
	GetDossier: {
		href: '/api/tx/dossier',
		method: 'get',
		//requires: ['number'],
	},
	UpdateDossier: {
		href: '/api/tx/file-update',
		method: 'post',
	},
	DossierHistory: {
		href: '/api/tx/document-history',
		method: 'post',
	},
	RequestDraftReview: {
		href: '/api/tx/request-draft-review',
		method: 'post',
	},
	DossierComments: {
		href: '/api/tx/list-comments',
		method: 'post',
	},
	RequestSignature: {
		href: '/api/tx/request-signatures',
		method: 'post',
	},
	AddDocuments: {
		href: '/api/tx/add-final-authorization-document',
		method: 'post',
	},
	FinalizeDossier: {
		href: '/api/tx/finalize-dossier',
		method: 'post',
	},
	ReadUserBatch: {
		href: '/glifos/api/checkin/batch',
		method: 'post',
	},
	dowloadFile: {
		href: '/glifos/file/download/',
		method: 'get',
	},
	getFileMetadata: {
		href: '/lot/lot-data',
		method: 'get',
	},
	signPrepare: {
		href: '/sign/prepare',
		method: 'post',
		requires: ['dossier_document_id'],
	},
	signFinish: {
		href: '/sign/finish',
		method: 'post',
		requires: ['request_id', 'signature_id', 'password'],
	},
	getMyDocuments: {
		href: '/api/tx/my-documents',
		method: 'get',
	},
	CreateUpdateCliente: {
		href: '/api/tx/create-client',
		method: 'post',
		requires: ['data'],
	},
	GetClientDetail: {
		href: '/api/tx/client-detail',
		method: 'get',
	},
	CreateZoomMeeting: {
		href: '/meeting/schedule',
		method: 'post',
		requires: ['dossier_id', 'topic', 'start_time'],
	},
	ValidateUser: {
		href: '/user/validate-user',
		method: 'post',
		requires: ['token', 'password'],
	},
	ForgotPassword: {
		href: '/user/forgot-password',
		method: 'post',
		requires: ['email', 'user_type'],
	},
	ResolveComment: {
		href: '/api/tx/resolve-comment',
		method: 'post',
		requires: ['comment_id'],
	},
	ResolveAllComments: {
		href: '/api/tx/resolve-all-comments',
		method: 'post',
		requires: ['comments'],
	},
	unResolveComment: {
		href: '/api/tx/unresolve-comment',
		method: 'post',
		requires: ['comment_id'],
	},
	SendNewToken: {
		href: '/user/send-new-token',
		method: 'post',
		requires: ['client_id', 'dossier_id'],
	},
	SendReminder: {
		href: '/user/send-reminder',
		method: 'post',
		requires: ['client_id', 'dossier_id'],
	},
	GetGenerales: {
		href: '/user/get-generales',
		method: 'post',
		requires: ['dossier', 'user_id'],
	},
	GetSignatures: {
		href: '/sign/signature-list',
		method: 'get',
	},
	UploadSignPrepare: {
		href: '/sign/upload-request',
		method: 'get',
	},
	UploadSignFinish: {
		href: '/sign/upload-finish',
		method: 'post',
		requires: ['uuid', 'name'],
	},
	GetEditable: {
		href: '/file/get-editable',
		method: 'post',
		//requires: ['file'],
	},
};
//console.log(ApiURLs);
export default ApiURLs;
