import React, { useState } from 'react';
import {
	Button,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/components/filterStyle';

const useStyles = makeStyles(styles);

export default function Filter() {
	const classes = useStyles();

	const [filter, setFilter] = useState(1);
	const [date1, setDate1] = useState('');
	const [date2, setDate2] = useState('');
	return (
		<div className={classes.card}>
			<div className={classes.field}>
				<InputLabel id='label'>Filtrar por</InputLabel>
				<Select
					labelId='label'
					id='filter'
					value={filter}
					onChange={(e) => setFilter(e.target.value)}>
					<MenuItem value='1'>Actas de herancias</MenuItem>
					<MenuItem value='2'>Acta de donaciones</MenuItem>
				</Select>
			</div>
			<div className={classes.field}>
				<TextField
					id='date1'
					label='Desde'
					type='date'
					value={date1}
					onChange={(e) => setDate1(e.target.value)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
			<div className={classes.field}>
				<TextField
					id='date2'
					label='Hasta'
					type='date'
					value={date2}
					onChange={(e) => setDate2(e.target.value)}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</div>
			<div className={classes.buttonSpace}>
				<Button variant='outlined' className={classes.button}>
					<Typography variant='caption'>Aplicar filtros</Typography>
				</Button>
			</div>
		</div>
	);
}
