import {
	UPLOAD_REQUEST,
	UPLOAD_REQUEST_SUCCESS,
	UPLOAD_REQUEST_ERROR,
	DELETE_REQUEST,
	DELETE_REQUEST_ERROR,
	DELETE_REQUEST_SUCCESS,
	SAVE_REQUEST,
	SAVE_REQUEST_ERROR,
	SAVE_REQUEST_SUCCESS,
	DOC_TYPES_REQUEST,
	DOC_TYPES_REQUEST_SUCCESS,
	DOC_TYPES_REQUEST_ERROR,
} from '../constants/documentConstants';

const initialState = {
	loading: false,
	archivos: [],
	isEdittingNewFile: false,
	currentFileId: undefined,
	filesData: [],
};

export const documentReducer = (state = initialState, action) => {
	switch (action.type) {
		case DELETE_REQUEST:
		case SAVE_REQUEST:
		case UPLOAD_REQUEST: {
			return { ...state, loading: true };
		}
		case UPLOAD_REQUEST_SUCCESS: {
			console.log(state);
			return {
				...state,
				loading: false,
				isEdittingNewFile: true, ///11/10/2021
				currentFileId: action.payload.id,
				archivos: [...state.archivos, action.payload],
			};
		}
		case SAVE_REQUEST_SUCCESS: {
			return {
				...state,
				loading: false,
				isEdittingNewFile: false,
				filesData: [
					...state.filesData,
					getFileWithID(action.payload, state.currentFileId),
				],
				currentFileId: undefined,
				archivos: state.archivos,
			};
		}
		case DELETE_REQUEST_SUCCESS: {
			return {
				...state,
				loading: false,
				archivos: state.archivos.filter(
					(archivo) => archivo.id !== action.payload
				),
			};
		}
		case DELETE_REQUEST_ERROR:
		case SAVE_REQUEST_ERROR:
		case UPLOAD_REQUEST_ERROR: {
			return { ...state, loading: false, archivos: [] };
		}

		default:
			return state;
	}
};

const initialState2 = {
	loading: false,
	document_types: [],
};

export const documentTypeReducer = (state = initialState2, action) => {
	switch (action.type) {
		case DOC_TYPES_REQUEST: {
			return { ...state, loading: true };
		}
		case DOC_TYPES_REQUEST_SUCCESS: {
			return { ...state, loading: false, document_types: action.payload };
		}
		case DOC_TYPES_REQUEST_ERROR: {
			return { ...state, loading: false, error: action.payload };
		}
		default:
			return state;
	}
};

const getFileWithID = (file, fileID) => {
	file.id = fileID;
	return file;
};
