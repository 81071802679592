import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Axios from 'services/Api';
import { CircularProgress } from '@material-ui/core';
import { WebVerification } from 'vdid-sdk-web';
// import logo from '../../assets/img/logo.svg';

const styles = (theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '90%',
		height: '100vh',
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '50%',
		height: '40vh',
		backgroundColor: '#ffffff',
		border: '2px solid #000000',
		flexWrap: 'wrap',
		padding: '2rem',

		[theme.breakpoints.down('md')]: {
			width: '60%',
			height: '50vh',
		},
		[theme.breakpoints.down('sm')]: {
			width: '70%',
			height: '60vh',
		},
		[theme.breakpoints.down('xs')]: {
			width: '80%',
			height: '80vh',
		},
	},
	cardBody: {
		//overflowY: 'scroll',
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		width: '90%',
	},
});

const useStyles = makeStyles(styles);

export default function TermsOfUse(props) {
	const classes = useStyles();

	return (
		<main className={classes.mainContainer}>
			<Typography variant='h1' className={classes.title}>
				Terms of Use for FIRMA La Venta Capital
			</Typography>
			<section className={classes.cardBody}>
				{/* <p>
					<em>Effective Date: [Insert Date]</em>
				</p> */}

				<p>
					Welcome to FIRMA La Venta Capital! These Terms of Use
					("Terms") govern your use of FIRMA La Venta Capital provided
					by La venta capital, S.A. de C.V. ("La venta capital," "we,"
					"us," or "our"). By using FIRMA La Venta Capital, you agree
					to be bound by these Terms. If you do not agree to these
					Terms, please refrain from using the FIRMA La Venta Capital.
				</p>

				<h2>1. Use of FIRMA La Venta Capital</h2>

				<h3>1.1. Age Restriction</h3>
				<p>
					You must be at least 18 years old or the age of majority in
					your jurisdiction to use FIRMA La Venta Capital. If you are
					under 18 years old, you may only use FIRMA La Venta Capital
					with the consent of a parent or legal guardian.
				</p>

				<h3>1.2. Prohibited Use</h3>
				<p>
					You agree not to use FIRMA La Venta Capital for any illegal,
					unauthorized, or improper purposes. You are solely
					responsible for ensuring that your use of FIRMA La Venta
					Capital complies with all applicable laws and regulations.
				</p>

				<h3>1.3. No Harmful Activities</h3>
				<p>
					You may not access, modify, or use FIRMA La Venta Capital in
					a way that could harm, disable, overburden, or impair any
					servers, networks, or other infrastructure used by FIRMA La
					Venta Capital.
				</p>

				<h2>2. Account Registration and Security</h2>

				<h3>2.1. Account Creation</h3>
				<p>
					To use certain features of FIRMA La Venta Capital, you may
					be required to create an account. You must provide accurate,
					current, and complete information during the registration
					process.
				</p>

				<h3>2.2. Account Security</h3>
				<p>
					You are solely responsible for maintaining the
					confidentiality of your account credentials and for any
					activity that occurs under your account. If you suspect any
					unauthorized use of your account, you must notify us
					immediately.
				</p>

				<h2>3. Intellectual Property Rights</h2>

				<h3>3.1. Ownership</h3>
				<p>
					FIRMA La Venta Capital and all its contents, including but
					not limited to text, graphics, images, logos, trademarks,
					and software, are the property of La venta capital or its
					licensors and are protected by intellectual property laws.
				</p>

				<h3>3.2. Restrictions</h3>
				<p>
					You may not modify, reproduce, distribute, create derivative
					works of, publicly display, or otherwise use any portion of
					FIRMA La Venta Capital without our prior written consent.
				</p>

				<h2>4. Limitation of Liability</h2>

				<h3>4.1. Disclaimer</h3>
				<p>
					FIRMA La Venta Capital is provided on an "as-is" basis, and
					we make no warranties or representations regarding its
					accuracy, availability, or reliability.
				</p>

				<h3>4.2. Exclusion of Damages</h3>
				<p>
					In no event shall La venta capital or its affiliates be
					liable for any indirect, incidental, consequential, special,
					or punitive damages arising out of or in connection with
					your use of FIRMA La Venta Capital.
				</p>

				<h3>4.3. Indemnification</h3>
				<p>
					You agree to indemnify and hold La venta capital, its
					officers, directors, employees, and agents harmless from any
					claims, losses, or damages (including legal fees) resulting
					from your violation of these Terms or your use of FIRMA La
					Venta Capital.
				</p>

				<h2>5. Modifications to FIRMA La Venta Capital and Terms</h2>

				<h3>5.1. App Modifications</h3>
				<p>
					We reserve the right to modify, suspend, or discontinue
					FIRMA La Venta Capital or any part thereof at any time
					without notice.
				</p>

				<h3>5.2. Terms Updates</h3>
				<p>
					We may also update or revise these Terms from time to time.
					The updated version will be posted on FIRMA La Venta Capital
					or our website, and the revised effective date will be
					included. Your continued use of FIRMA La Venta Capital after
					such changes constitutes your acceptance of the updated
					Terms.
				</p>

				<h2>6. Governing Law and Jurisdiction</h2>

				<h3>6.1. Applicable Law</h3>
				<p>
					These Terms shall be governed by and construed in accordance
					with the laws of México, without regard to its
					conflict of laws principles.
				</p>

				<h3>6.2. Jurisdiction</h3>
				<p>
					Any dispute arising out of or in connection with these Terms
					or your use of FIRMA La Venta Capital shall be subject to
					the exclusive jurisdiction of the courts of México.
				</p>

				<h2>7. Contact Us</h2>

				<h3>7.1. Contact Information</h3>
				<p>
					If you have any questions, comments, or feedback regarding
					these Terms or FIRMA La Venta Capital, please contact us at:
				</p>
				<p>Email: contact@laventacapital.com</p>
				{/*<p>Address: [Your address]</p>*/}

				<p>
					Thank you for using FIRMA La Venta Capital. We hope you have
					a great experience with our services!
				</p>
			</section>
		</main>
	);
}
