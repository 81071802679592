import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Typography,
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { requestDraftReview } from 'actions/dossierActions';
import Swal from 'sweetalert2';
import { cleanRequestDraft } from 'actions/dossierActions';

function getModalStyle() {
	return {
		borderSizing: 'border-box',
		top: `50%`,
		left: `50%`,
		transform: `translate(-50%, -50%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 800,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3, 4),
		display: 'flex',
		flexDirection: 'column',
		minHeight: '50%',
		overflow: 'auto',
	},
	titleSpace: {
		display: 'flex',
		flexDirection: 'row',
		width: 'auto',
		marginBottom: '15px',
	},
	button: {
		//backgroundColor: '#e0e0e0',
		marginTop: '20px',
		marginLeft: '15px',
	},
	fileSpace: {
		display: 'flex',
		flexDirection: 'row',
		padding: '15px',
		width: 'auto',
		border: '1px solid black',
		height: '3rem',
		marginBottom: '5px',
		overflow: 'auto',
	},
	iconSpace: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		fontSize: '3rem',
	},
	fileData: {
		display: 'flex',
		flexDirection: 'row',
		height: 'auto',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: '15px',
	},
	version: {
		display: 'flex',
		width: 'auto',
		marginLeft: '15px',
		backgroundColor: '#B5C9DD',
		alignItems: 'center',
		padding: '0.5rem',
	},
	closeSpace: {
		display: 'flex',
		width: '100',
		justifyContent: 'flex-end',
	},
	disclaimerSpace: {
		marginBottom: '15px',
	},
	clientSpace: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
}));

export default function ModalClientes(props) {
	const classes = useStyles();
	const { id } = useParams();
	const dispatch = useDispatch();

	const { clients } = useSelector((state) => state.user_clients);
	const requestDraft = useSelector((state) => state.requestDraft);
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = useState(getModalStyle);
	const [open, setOpen] = useState(false);
	const [signers, setSigners] = useState([
		...Object.values(props.clientes || {}),
	]);

	useEffect(() => {
		setSigners([...Object.values(props.clientes || {})]);
	}, [props.clientes]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClose2 = () => {
		dispatch(requestDraftReview(id, signers));
		setOpen(false);
		Swal.fire('La solicitud de revision ha sido enviada!', '', 'success');
	};

	useEffect(() => {
		if (requestDraft.requestDone) {
			dispatch(cleanRequestDraft());
		}
	}, [requestDraft.requestDone]);

	const handleChange = (e) => {
		if (signers.some((item) => item == e.target.name)) {
			setSigners([...signers.filter((item) => item != e.target.name)]);
		} else {
			setSigners([...signers, e.target.name]);
		}
	};
	const body = (
		<div style={modalStyle} className={classes.paper}>
			<div className={classes.titleSpace}>
				<Typography variant='h4'>
					Seleccione los clientes que deben revisar este documento
				</Typography>
			</div>
			<div className={classes.fileSpace}>
				<div className={classes.iconSpace}>
					<i className='fas fa-file fa-1x'></i>
				</div>
				<div className={classes.fileData}>
					<div className={classes.fileType}>
						{props.file.documento}
					</div>
					<div className={classes.version}>
						Versión {props.file.version}
					</div>
				</div>
			</div>
			<div className={classes.disclaimerSpace}>
				<Typography variant='body2'>
					Los clientes seleccionados solo verán esta versión del
					documento
				</Typography>
			</div>
			<div className={classes.clientsSpace}>
				<FormControl component='fieldset'>
					<FormGroup>
						{props.clientes &&
							Object.values(props.clientes).map((cliente, i) => {
								const client = clients?.find(
									(item) => item.model.client_user == cliente
								);
								return (
									<FormControlLabel
										key={i}
										control={
											<Checkbox
												name={cliente}
												checked={signers.some(
													(item) => item == cliente
												)}
												onChange={handleChange}
											/>
										}
										label={`${client?.relations.clientUser.model.first_name} ${client?.relations.clientUser.model.last_name}`}
									/>
								);
							})}
					</FormGroup>
				</FormControl>
			</div>
			<div className={classes.closeSpace}>
				<Button
					variant='contained'
					color='primary'
					onClick={handleClose}
					className={classes.button}
				>
					Cancelar
				</Button>
				<Button
					variant='contained'
					color='primary'
					onClick={handleClose2}
					className={classes.button}
				>
					Solicitar Revisión
				</Button>
			</div>
		</div>
	);

	return (
		<>
			<Button
				variant='contained'
				color='primary'
				onClick={handleOpen}
				className={classes.button}
				style={{ margin: 0 }}
			>
				Solicitar Revisión
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				{body}
			</Modal>
		</>
	);
}
