import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/registroStyle';
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RegistroClientes from 'components/RegistroClientes/RegistroClientes';
import Axios from '../../services/Api.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

const useStyles = makeStyles(styles);

function DetalleCliente(props) {
	const classes = useStyles();
	const { id } = useParams();
	const [data, setData] = useState({
		isLoading: false,
		payload: {
			id: id,
			nombres: '',
			apellidos: '',
			correo: '',
			telefono: '',
			rfc: false,
			curp: false,
			ine: false,
		},
		isError: false,
		message: '',
	});

	useEffect(() => {
		const getClient = async () => {
			try {
				const { data: response_data } = await Axios.GetClientDetail({
					params: { user_id: id },
				});
				if (!Boolean(response_data.result)) {
					throw new Error(response_data.error_log);
				}
				if (
					response_data?.query_result?.models_1?.queryOneModel_1
						?.result?.length === 0
				) {
					throw new Error('No se encontró el cliente');
				}
				const user_data =
					response_data?.query_result?.models_1?.queryOneModel_1
						?.result[0];
				const contacts =
					user_data?.relations?.clientUser?.relations?.userContacts;
				setData((prevData) => ({
					...prevData,
					isLoading: false,
					payload: {
						id: user_data?.model?.client_user,
						nombres:
							user_data?.relations?.clientUser?.model.first_name,
						apellidos:
							user_data?.relations?.clientUser?.model.last_name,
						correo: contacts.find(
							(contact) => contact.model.type === 1
						)?.model?.value,
						telefono: contacts.find(
							(contact) => contact.model.type === 2
						)?.model?.value,
					},
					isError: false,
					message: '',
				}));
			} catch (error) {
				setData((prevData) => ({
					...prevData,
					isLoading: false,
					isError: true,
					message: error.message,
				}));
			}
		};
		getClient();
	}, [id]);

	const goBack = () => {
		props.history.push('/clientes');
	};

	return (
		<div>
			<Grid container className={classes.container}>
				<Grid item xs={1} className={classes.column}>
					<div className={classes.clickable} onClick={goBack}>
						<ArrowBackIcon />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className={classes.row}>
						<Typography variant='h3'>
							{data.isError ? 'Error' : 'Detalle de Cliente'}
						</Typography>
					</div>
					<Grid item className={classes.rowContainer}>
						<Grid item xs={12} className={classes.formContainer}>
							{data.isError && (
								<div className={classes.alertError}>
									{data.message ||
										'Ocurrio un error, por favor intentelo más tarde'}
								</div>
							)}
							{!data.isLoading && !data.isError && (
								<>
									<div className={classes.row}>
										<Grid item xs={12} md={3}>
											<Typography variant='body1'>
												Nombres{' '}
											</Typography>
										</Grid>
										<Grid item xs={12} md={9}>
											{/* <TextField
										fullWidth
										autoFocus
										value={data.nombres}
										name='nombres'
										required
										onChange={handleChange}
										type='text'
										inputProps={{
											type: 'text',
										}}
										error={dataError.nombres.error}
										helperText={dataError.nombres.message}
									/> */}
											{data.payload.nombres}
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={3}>
											<Typography variant='body1'>
												Apellidos{' '}
											</Typography>
										</Grid>
										<Grid item xs={12} md={9}>
											{/* <TextField
										fullWidth
										value={data.apellidos}
										name='apellidos'
										required
										onChange={handleChange}
										type='text'
										inputProps={{
											type: 'text',
										}}
										error={dataError.apellidos.error}
										helperText={dataError.apellidos.message}
									/> */}
											{data.payload.apellidos}
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={3}>
											<Typography variant='body1'>
												Correo{' '}
											</Typography>
										</Grid>
										<Grid item xs={12} md={9}>
											{/* <TextField
										fullWidth
										value={data.correo}
										name='correo'
										onChange={handleChange}
										type='email'
										required
										InputProps={{
											type: 'email',
										}}
										error={dataError.correo.error}
										helperText={dataError.correo.message}
									/> */}
											{data.payload.correo}
										</Grid>
									</div>
									<div className={classes.row}>
										<Grid item xs={12} md={3}>
											<Typography variant='body1'>
												Teléfono
											</Typography>
										</Grid>
										<Grid item xs={12} md={9}>
											{/* <TextField
										fullWidth
										value={data.telefono}
										name='telefono'
										onChange={handleChange}
										type='tel'
										InputProps={{
											type: 'tel',
										}}
										error={dataError.telefono.error}
										helperText={dataError.telefono.message}
									/> */}
											{data.payload.telefono}
										</Grid>
									</div>
									{/* <div className={classes.row}>
								<Grid
									item
									xs={12}
									md={3}
									className={classes.alignStart}
								>
									<Typography variant='body1'>
										Identificación
									</Typography>
								</Grid>
								<Grid item xs={12} md={9}>
									<FormControl component='fieldset'>
										<FormGroup>
											<FormControlLabel
												className={
													data.rfc
														? classes.formControl
														: ''
												}
												control={
													<Checkbox
														name='rfc'
														checked={data.rfc}
														onChange={handleChange}
														value={data.rfc}
													/>
												}
												label='Requerir RFC'
											/>
											<FormControlLabel
												className={
													data.curp
														? classes.formControl
														: ''
												}
												control={
													<Checkbox
														name='curp'
														checked={data.curp}
														onChange={handleChange}
														value={data.curp}
													/>
												}
												label='Requerir CURP'
											/>
											<FormControlLabel
												className={
													data.ine
														? classes.formControl
														: ''
												}
												control={
													<Checkbox
														name='ine'
														checked={data.ine}
														onChange={handleChange}
														value={data.ine}
													/>
												}
												label='Requerir INE'
											/>
										</FormGroup>
									</FormControl>
								</Grid>
							</div> */}
									<div className={classes.buttonSpace}>
										{/* <Button
									variant='contained'
									color='primary'
									onClick={nextStep}
									className={classes.button}
									disabled={isPosting.isPosting}
									endIcon={
										isPosting.isPosting ? (
											<CircularProgress size={20} />
										) : null
									}
								>
									Solicitar Validación
								</Button> */}
									</div>
								</>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

export default withRouter(DetalleCliente);
