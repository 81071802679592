import {
	PREPAPRE_REQUEST,
    PREPARE_REQUEST_SUCCESS,
    PREPARE_REQUEST_ERROR,
    CERTIFICATE_UPLOADED,
    CERTIFICATE_DELETED,
    KEY_UPLOADED,
    KEY_DELETED,
    FINISH_REQUEST,
    FINISH_REQUEST_SUCCESS,
    FINISH_REQUEST_ERROR
} from '../constants/signConstants';

const initialState = {
	loading: false,
    isReady: false,
	signInfo: undefined,
};

export const signReducer = (state = initialState, action) => {
	switch (action.type) {
		case PREPAPRE_REQUEST: {
			return { ...state, loading: true };
		}
        case PREPARE_REQUEST_SUCCESS: {
			return { ...state, loading: false, isSetup: true, signInfo: action.payload };
		}
        case PREPARE_REQUEST_ERROR: {
			return { ...state, loading: false, isSetup: false, signInfo: undefined };
		}
        case CERTIFICATE_UPLOADED:
            return {...state, certificateFile: action.fileData};
        case CERTIFICATE_DELETED:
            return {...state, certificateFile: undefined};
        case KEY_UPLOADED:
            return {...state, keyFile: action.fileData};
        case KEY_DELETED:
            return {...state, keyFile: undefined};
        case FINISH_REQUEST: {
			return { ...state, loading: true };
		}
        case FINISH_REQUEST_SUCCESS: {
			return { ...state, loading: false, isSetup: false, isDone: true, signInfo: undefined, certificateFile: undefined, keyFile: undefined };
		}
        case FINISH_REQUEST_ERROR: {
			return { ...state, loading: false};
		}
		default:
			return state;
	} // switch
};