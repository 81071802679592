import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/fileRelated";
import { TextField, Input, Typography, InputLabel, Select, FormControl } from "@material-ui/core";
import Swal from 'sweetalert2';
/*REDUX*/
import { useDispatch } from 'react-redux';
import { saveFile } from '../../actions/documentActions';
const useStyles = makeStyles(styles);

const renderTextInput = (classes,config, index, updateFunction) => {
    if (!config) {
        console.error('Initialization error: Field metadata not provided!')
        return null;
    }
    return (
        <div className={classes.fieldWithLabel} key={index}>
                    <Typography variant="h4">{config.label+":"}</Typography>
                    <Input className={classes.field}  type={config.inputType} variant={config.variant} onChange={(event) => updateFunction(index, event.target.value)} />
        </div>
        
    );
};

const renderDropDown = (classes,config, index) => {
    if (!config)
        console.error('Initialization error: Field metadata not provided!')
    return (
        <FormControl key={index} variant={"outlined"} required>
            <InputLabel htmlFor="outlined-age-native-simple">{config.label}</InputLabel>
            <Select
                native
                /*value={state.age}
                onChange={handleChange}*/
                label={config.label}
                inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                }}
            >
                <option aria-label="None" value="" />
                {
                    config.options.map((element, index) => {
                        return (
                            <option key={index} value={element.value}>{element.label}</option>
                        )
                    })
                }
            </Select>
        </FormControl>
    );
};

export default function JustAddedFile(props) {
    const { template } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [fileFields, setFileFields] = React.useState([...template]);

    ////TODO, read about usePrompt hook from react-router to prompt user before leaving and if data is unsaved. 

    const renderFields = () => {
        return fileFields.map((element, index) => {
            if (element.inputType == 'dropdown')
                return renderDropDown(classes,element, index);
            else
                return renderTextInput(classes,element, index, updateField);
        })
    };

    const hasUnsavedFields = () => fileFields.find(element => element.hasChanged);

    const hasUnmodifiedFields = () => fileFields.find(element => !element.hasChanged);

    const updateField = (index, newValue) => {
        const currentFields = fileFields;
        currentFields[index].value = newValue;
        currentFields[index].hasChanged = true;
        setFileFields(currentFields);
    }

    const saveFileHandler = () => {
        var cantSave = hasUnmodifiedFields();
        if (cantSave) {
            Swal.fire({
                icon: 'error',
                title: 'Hubo un error',
                text: 'Debes llenar todos los campos antes de continuar'
            });
        }
        else
            dispatch(saveFile(fileFields));


    }

    return (
        <div className={classes.container}>
            <div className={classes.mainColumn}>
                <div className={classes.fileInfoRow}>
                    <div className={classes.fileIcon}>
                        <i class="fas fa-file-pdf fa-5x"></i>
                        <Typography variant="subtitle2"> Ver documento</Typography>
                    </div>
                    {renderFields()}
                </div>
            </div>
            <div className={classes.rightSideColumn}>
                <i class="fas fa-trash-alt fa-2x"></i>
                <i class="fas fa-edit fa-2x"></i>

                <div style={{ marginTop: 'auto' }} onClick={saveFileHandler}><i class="fas fa-save fa-2x"></i></div>
            </div>
        </div>

    );
}