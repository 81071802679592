import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Axios from 'services/Api';
import { CircularProgress } from '@material-ui/core';
import { WebVerification } from 'vdid-sdk-web';
// import logo from '../../assets/img/logo.svg';

const styles = (theme) => ({
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '90%',
		height: '100vh',
	},
	cardContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '50%',
		height: '40vh',
		backgroundColor: '#ffffff',
		border: '2px solid #000000',
		flexWrap: 'wrap',
		padding: '2rem',

		[theme.breakpoints.down('md')]: {
			width: '60%',
			height: '50vh',
		},
		[theme.breakpoints.down('sm')]: {
			width: '70%',
			height: '60vh',
		},
		[theme.breakpoints.down('xs')]: {
			width: '80%',
			height: '80vh',
		},
	},
	cardBody: {
		//overflowY: 'scroll',
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		width: '90%',
	},
});

const useStyles = makeStyles(styles);

export default function TermsOfUse(props) {
	const classes = useStyles();

	return (
		<main className={classes.mainContainer}>
			<Typography variant='h1' className={classes.title}>
				Contact Support
			</Typography>
			<section className={classes.cardBody}>
				<p>
					If you need assistance or have any questions, our support
					team is here to help you. Please use one of the following
					methods to reach out to us:
				</p>

				<h2>Email</h2>
				<p>
					Contact us via email at:{' '}
					<a href='mailto:support@firmalaventacapital.com'>
						support@firmalaventacapital.com
					</a>
				</p>

				{/*<h2>Phone</h2>
				<p>Call our support hotline: [Insert Phone Number]</p>
				
				<h2>Live Chat</h2>
				<p>
					Chat with one of our support representatives in real-time.
					Click the "Live Chat" button on our website during business
					hours to start a chat session.
				</p>
				*/}
				<h2>Support Hours</h2>
				<p>
					Our support team is available to assist you during the
					following hours:
				</p>
				<p>Monday to Friday: 9:00 AM - 6:00 PM (Central Standard Time GTM+6)</p>
				<p>Saturday and Sunday: Closed</p>

				<h2>FAQs</h2>
				<p>
					Before reaching out to support, you might find the answer to
					your question in our Frequently Asked Questions (FAQs)
					section. Visit our FAQ page{' '}
					{/*<a href='[Your FAQ page URL]'>here</a>.*/}
				</p>

				<p>
					We strive to respond to all inquiries promptly and provide
					the best possible support to our valued users.
				</p>

				<p>
					Thank you for using FIRMA La Venta Capital. We appreciate
					your business!
				</p>
			</section>
		</main>
	);
}
