import React, { useEffect, useState } from 'react';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ContentTable from 'components/ContentTable/ContentTable';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { getPendingDossier } from 'actions/dossierActions';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'services/Api'
const useStyles = makeStyles(styles);

var fakeColumns = [
	{ name: 'Correlativo', flex: 1, align: 'center', minWidth: 100 },
	{ name: 'Documento', flex: 3, align: 'left', minWidth: 200 },
	{ name: 'Estado', align: 'center', flex: 2, minWidth: 100 },
];

function DocumentosCompletados
	(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [documentos, setDocumentos] = useState([]);

	const [rows, setRows] = useState([]);

	//const dossiers = useSelector((state) => state.pendingDossier.dossiers);
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo, loading, error } = userSignin;

	const visibilityActionClick = (id) => {
		if (id?.id) id = id.id;
		props.history.push('/revision_documento/' + id);
	};

	useEffect(() => {
		if (!userInfo) return props.history.push('/login');
		if (userInfo.roles.includes('cliente-firmante')) {
			props.history.push('/documento');
		} // if
		const getFiles = async () => {
			try {
				setIsLoading(true);
				const { data } = await Axios.GetCompletedDocuments();
				if (JSON.parse(data.result)) {
					setDocumentos(data.query_result.models_1.queryOneModel_1.result);
				}
			} catch (error) {
				console.log(error)
				setDocumentos([]);
			} finally {
				setIsLoading(false)
			}
		}
		getFiles()
	}, []);

	useEffect(() => {
		if (documentos) {
			setRows(
				documentos.map((item) => {
					return {
						id: item.model.id,
						documento: item.model.name,
						estado: getStatus(item.model.status),
						actions: ['edit'],
					};
				})
			);
		}
	}, [documentos]);

	const getStatus = (status) => {
		if (status >= 2001 && status <= 2010) return 'Carga del Borrador';
		if (status >= 2011 && status <= 2069) return 'Revision del Borrador';
		if (status >= 2070 && status <= 2130) return 'Firma del documento';
		if (status >= 2131 && status <= 2200) return 'Autorización preventiva';
		if (status >= 2201 && status <= 2299) return 'Autorización Definitiva';
		if (status >= 2300 && status <= 2399) return 'Finalizado Archivado';
	};

	return (
		<div style={{ height: '100%' }}>
			<ContentTable
				title='Documentos'
				pseudoRows={rows}
				columnDefinitions={fakeColumns}
				renderActionsHeader
				visibilityHandler={visibilityActionClick}
				loading={isLoading}
				onRowClick={visibilityActionClick}
				onCellClick={visibilityActionClick}
			/>
		</div>
	);
}

export default withRouter(DocumentosCompletados
);
