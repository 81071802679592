import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import InteractiveList from '../List/List';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
/*REDUX*/
import { useDispatch } from 'react-redux';
import { uploadFile } from '../../actions/documentActions';
/*END OF REDUX RELATED*/

import SingleFileDescriptor from './SingleFileDescriptor';
const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	fixedHeight: {
		height: 280,
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		boxShadow: theme.customConstants.defaultBoxShadow,
		border: theme.customConstants.defaultBorder,
	},
	container: {
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '30px',
		borderWidth: '2px',
		borderRadius: '2px',
		//borderColor: ${props => getColor(props)},
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: 'black',
		outline: 'none',
		transition: 'border .24s ease-in-out',
	},
}));

const getBorderColor = (props) => {
	if (props.isDragAccept) {
		return '#00e676';
	}
	if (props.isDragReject) {
		return '#ff1744';
	}
	if (props.isDragActive) {
		return '#2196f3';
	}
	return '#eeeeee';
};

const fallbackText =
	'Arrastra y suelta acá el documento, o da click aquí para buscarlo';

export default function FileUploader(props) {
	const { singleFile, allowedFiles } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	console.log('Allowedfiles ', allowedFiles);
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept: allowedFiles,
		getFilesFromEvent: (event) =>
			myCustomFileGetter(event, props.s3Upload),
	});

	async function myCustomFileGetter(event, s3Upload) {
		const files = [];
		const fileList = event.dataTransfer
			? event.dataTransfer.files
			: event.target.files;
		for (var i = 0; i < fileList.length; i++) {
			console.log('Archivo i' + i + ', en myCustomFileGetter');
			const file = fileList.item(i);
			console.log(file);
			var formData = new FormData();
			formData.append('file', file);
			dispatch(uploadFile(formData, s3Upload));
			files.push(file);
		}
		props.setFileUpload(true);
		return files;
	}

	const fixedHeightPaper = singleFile
		? classes.paper
		: clsx(classes.paper /*, classes.fixedHeight*/);

	const fileUploaderInnerDescription = () => {
		if (!singleFile) {
			return <p>{fallbackText}</p>;
		} else {
			return (
				<SingleFileDescriptor
					fallbackText={fallbackText}
					setFileUpload={props.setFileUpload}
				/>
			);
		}
	};
	return (
		<Paper className={fixedHeightPaper}>
			<section className='container'>
				<div
					className={classes.container}
					style={{
						borderColor: getBorderColor({
							...getRootProps({
								isDragActive,
								isDragAccept,
								isDragReject,
							}),
						}),
					}}
					{...getRootProps({
						isDragActive,
						isDragAccept,
						isDragReject,
					})}>
					<input {...getInputProps()} />
					{fileUploaderInnerDescription()}
				</div>
			</section>
			{singleFile == false && (
				<aside>
					<InteractiveList />
				</aside>
			)}
		</Paper>
	);
}
