import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-react/views/registroStyle';
import { Grid, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StepsDocumento from 'components/Steps/StepsDocumento';
import RegistroDocumentos from 'components/RegistroDocumento/RegistroDocumentos';
import FileUploader from 'components/FileUploader/FileUploader';
import SeleccionClientes from 'components/SeleccionClientes/SeleccionClientes';
import { getDocumentTypes } from 'actions/documentActions';
import { getUserClients } from 'actions/userActions';
import { createDossier } from 'actions/dossierActions';
import { deleteFile } from 'actions/documentActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(styles);

function RegistroDocumento(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useHistory();

	const [step, setStep] = useState(1);
	const [selected, setSelected] = useState([]);
	const [fileUpload, setFileUpload] = useState(false);
	const [documentData, setDocumentData] = useState({
		titulo: '',
		descripcion: '',
		tipo_documento: 0,
		rfc: false,
		curp: false,
		ine: false,
	});

	const [error, setError] = useState({
		titulo: false,
		tipo_documento: false,
	});

	const archivos = useSelector((state) => state.document.archivos);
	const dossier = useSelector((state) => state.dossier.data);

	const handleChange = (e) => {
		if (e.target.name === 'tipo_documento' && error.tipo_documento)
			setError({ ...error, tipo_documento: false });
		setDocumentData({
			...documentData,
			[e.target.name]: e.target.value
				? e.target.value
				: e.target.checked
				? e.target.checked
				: '',
		});
	};

	const nextStep = () => {
		if (!documentData.titulo || documentData.tipo_documento === '0') {
			setError({
				titulo: !documentData.titulo,
				tipo_documento: documentData.tipo_documento === '0',
			});
			return;
		}
		if (step < 3) setStep(step + 1);

		if (step === 3) {
			dispatch(
				createDossier(
					documentData.titulo,
					documentData.descripcion,
					documentData.tipo_documento,
					archivos[0].id,
					documentData.rfc,
					documentData.curp,
					documentData.ine,
					selected,
					(id) => {
						if (id === null) return;
						//console.log(dossier);
						navigate.push(`/revision_documento/${id}`);
						dispatch(deleteFile(archivos[0].id));
					}
				)
			);
			//console.log(dossier);
			// if (
			// 	dossier[0]?.state?.id !== null &&
			// 	dossier[0]?.state?.id !== undefined
			// ) {
			// 	props.history.push(
			// 		`/revision_documento/${dossier[0]?.state?.id}`
			// 	);
			// } else {
			// 	window.location.href = '/dashboard';
			// }
		}
	};

	const goBack = () => {
		props.history.goBack();
	};
	const { userInfo } = useSelector((state) => state.userSignin);

	useEffect(() => {
		if (!userInfo) return props.history.push('/login');
		dispatch(getDocumentTypes());
		dispatch(getUserClients());
	}, []);

	return (
		<div>
			<Grid container className={classes.container}>
				<Grid item xs={1} className={classes.column}>
					<div className={classes.clickable} onClick={goBack}>
						<ArrowBackIcon />
					</div>
				</Grid>
				<Grid item xs={10}>
					<div className={classes.row}>
						<Typography variant='h3'>Nuevo Documento</Typography>
					</div>
					<Grid item className={classes.rowContainer}>
						<Grid item md={3} sm={12} xs={12}>
							<StepsDocumento step={step} />
						</Grid>
						<Grid
							item
							md={9}
							sm={12}
							xs={12}
							className={classes.formContainer}
						>
							{step === 1 && (
								<RegistroDocumentos
									documentData={documentData}
									handleChange={handleChange.bind(this)}
									error={error}
								/>
							)}
							{step === 2 && (
								<FileUploader
									allowedFiles={'.pdf'}
									singleFile={true}
									setFileUpload={setFileUpload.bind(this)}
								/>
							)}
							{step === 3 && (
								<SeleccionClientes
									selected={selected}
									setSelected={setSelected.bind(this)}
								/>
							)}
							<div className={classes.buttonSpace}>
								<Button
									variant='contained'
									color='primary'
									onClick={nextStep}
									disabled={
										(step === 1 &&
											(!documentData.titulo ||
												documentData.tipo_documento ===
													'0')) ||
										(step === 2 && !fileUpload)
									}
									className={classes.button}
								>
									{step < 3 ? 'Siguiente Paso' : 'Finalizar'}
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

export default withRouter(RegistroDocumento);
